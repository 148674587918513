import React, {ReactNode} from "react";
import {Alert, Snackbar} from "@mui/material";

interface ErrorMessageProps {
    isOpen: boolean;
    pageHasFab?: boolean;
    onClose: () => void;
    children?: ReactNode;
}

export function ErrorMessage(props: ErrorMessageProps) {

    const style = {
        bottom: {
            xs: props.pageHasFab ? 140 : 60,
            sm: 60
        },
        maxWidth: {
            sm: `calc(100% - ${props.pageHasFab ? 136 : 48}px)`
        }
    };

    return (
        <Snackbar open={props.isOpen}
                  onClose={props.onClose}
                  autoHideDuration={6000}
                  sx={ style }>
            <Alert severity="error" sx={{ width: '100%', textTransform: 'none' }}>
                {props.children}
            </Alert>
        </Snackbar>
    );
}
