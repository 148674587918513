/**
 *
 * Get an item from local storage.
 *
 * @param key
 *     The item key to obtain the value of.
 * @return
 *     The item value, or NULL if no item with the specified key is found.
 */
function getItem(key: string): string | null {
    try {
        return window.localStorage.getItem(key);
    } catch {
        // Looking at you Chrome when in an iframe with third party cookies disabled
        return null;
    }
}

/**
 *
 * Set an item in local storage.
 *
 * @param key
 *     The item key.
 * @param value
 *     The item value, or null to clear the stored item.
 */
function setItem(key: string, value: string | null): void {
    try {
        if (value !== null) {
            window.localStorage.setItem(key, value);
        } else {
            window.localStorage.removeItem(key);
        }
    } catch {
        // Looking at you Mobile Safari in private mode
    }
}

export const LocalStorage = { getItem, setItem };
