import React, {Component} from "react";
import PropTypes from "prop-types";

import './Mask.scss';
import {SizeMonitor} from "./SizeMonitor";
import {CanvasMask} from "./CanvasMask";

/**
 * - The mask requires a fixed aspect ratio. We cannot use a fixed aspect ratio box CSS solution
 *   (@see https://css-tricks.com/aspect-ratio-boxes/) because these adjust the box height based on available width,
 *   thereby vertically extending the box outside the mask wrapper if the mask's aspect ratio is smaller than the
 *   wrapper's aspect ratio. Instead, we monitor the mask area for resizes.
 * - IE does not support the the mask-image CSS property. In IE we therefore overlay a canvas on the mask content
 *   that renders the background color according to the alpha mask. Any content underlying transparent areas of the
 *   alpha mask will consequently be hidden.
 */
export class Mask extends Component {

    constructor(props) {
        super(props);

        this.handleResize = this.handleResize.bind(this);

        this.state = {
            size: null,
            isIE: this.isIE()
        };
    }

    render() {
        const contentSize = this.getContentSize();
        const contentMaskImage = this.props.alpha && `url('${this.props.alpha}')`;

        const contentStyle = {
            width: contentSize.width,
            height: contentSize.height,
            maskImage: contentMaskImage,
            WebkitMaskImage: contentMaskImage
        };

        const contentClass = `mask-content ${this.props.mirrored ? "mask-content-mirrored" : ""}`;

        const overlayStyle = {
            backgroundImage: `url(${this.props.overlay})`
        };

        return (
            <SizeMonitor onResize={this.handleResize}>
                <div className="mask-wrapper">
                    <div className={contentClass} style={contentStyle}>
                        {this.state.isIE && this.props.alpha &&
                            <CanvasMask alpha={this.props.alpha} color={this.props.backgroundColor} />
                        }
                        {this.props.overlay &&
                            <div className="mask-overlay" style={overlayStyle} />
                        }
                        {this.props.children}
                    </div>
                </div>
            </SizeMonitor>
        );
    }

    handleResize(size) {
        this.setState({size: size});
    }

    getContentSize() {
        const wrapperWidth = this.state.size?.width || 1;
        const wrapperHeight = this.state.size?.height || 1;
        const wrapperAspectRatio = wrapperWidth / wrapperHeight;
        const maskAspectRatio = this.props.aspectRatio;

        const contentSize = {
            width: wrapperWidth,
            height: wrapperHeight
        };

        if (maskAspectRatio < wrapperAspectRatio) {
            contentSize.width = wrapperHeight * maskAspectRatio;
        } else {
            contentSize.height = wrapperWidth / maskAspectRatio;
        }

        return contentSize;
    }

    isIE() {
        return !!window.document.documentMode;
    }

}

Mask.propTypes = {
    aspectRatio: PropTypes.number.isRequired,
    backgroundColor: PropTypes.string.isRequired,
    alpha: PropTypes.string,
    overlay: PropTypes.string,
    mirrored: PropTypes.bool
}
