import {Button, Paper, styled} from "@mui/material";
import {FormattedMessage} from "react-intl";
import React from "react";


const DialogFooterRoot = styled(Paper, {
    name: 'DialogFooter',
    slot: 'Root',
    overridesResolver: (props, styles) => styles.root
})(({ theme }) => ({
    background: theme.palette.background.default,
    padding: theme.spacing(2)
}));

interface DialogFooterProps {
    disabled?: boolean;
    onNext: () => void;
}

export function DialogFooter({
    disabled = false,
    onNext
}: DialogFooterProps) {
    return (
        <DialogFooterRoot
            square
            elevation={0}
        >
            <Button
                variant="contained"
                fullWidth
                disabled={disabled}
                onClick={onNext}
            >
                <FormattedMessage
                    id="shared.next"
                    description="Next button text."
                    defaultMessage="Next"
                />
            </Button>
        </DialogFooterRoot>
    );
}
