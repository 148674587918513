import {defineMessages, useIntl} from "react-intl";

import {locale_en_gb, plugin_crop_locale_en_gb} from "@pqina/pintura"
import {plugin_trim_locale_en_gb} from "@pqina/pintura-video";

/**
 *  We use our own translations instead of Pintura's because:
 * - All translations are then in a single place.
 * - Pintura translations can be overridden by us.
 * - Our translations can be overridden by customers.
 * - Support for locales without a region identifier.
 * - Pintura locales are not distributed in an npm package (requiring manual modifications upon changes anyway).
 */

const messages = defineMessages({
    labelReset: {
        id: "record.shared.editor.reset",
        description: "Record - Shared - Editor - Reset",
        defaultMessage: "Reset"
    },
    labelDefault: {
        id: "record.shared.editor.default",
        description: "Record - Shared - Editor - Default",
        defaultMessage: "Default"
    },
    labelAuto: {
        id: "record.shared.editor.auto",
        description: "Record - Shared - Editor - Auto",
        defaultMessage: "Auto"
    },
    labelNone: {
        id: "record.shared.editor.none",
        description: "Record - Shared - Editor - None",
        defaultMessage: "None"
    },
    labelEdit: {
        id: "record.shared.editor.edit",
        description: "Record - Shared - Editor - Edit",
        defaultMessage: "Edit"
    },
    labelClose: {
        id: "record.shared.editor.close",
        description: "Record - Shared - Editor - Close",
        defaultMessage: "Close"
    },
    labelSupportError: {
        id: "record.shared.editor.support-error",
        description: "Record - Shared - Editor - Support error",
        defaultMessage: "{features} not supported on this browser"
    },
    labelColor: {
        id: "record.shared.editor.color",
        description: "Record - Shared - Editor - Color",
        defaultMessage: "Color"
    },
    labelWidth: {
        id: "record.shared.editor.width",
        description: "Record - Shared - Editor - Width",
        defaultMessage: "Width"
    },
    labelSize: {
        id: "record.shared.editor.size",
        description: "Record - Shared - Editor - Size",
        defaultMessage: "Size"
    },
    labelOffset: {
        id: "record.shared.editor.offset",
        description: "Record - Shared - Editor - Offset",
        defaultMessage: "Offset"
    },
    labelAmount: {
        id: "record.shared.editor.amount",
        description: "Record - Shared - Editor - Amount",
        defaultMessage: "Amount"
    },
    labelInset: {
        id: "record.shared.editor.inset",
        description: "Record - Shared - Editor - Inset",
        defaultMessage: "Inset"
    },
    labelRadius: {
        id: "record.shared.editor.radius",
        description: "Record - Shared - Editor - Radius",
        defaultMessage: "Radius"
    },
    labelColorPalette: {
        id: "record.shared.editor.color-palette",
        description: "Record - Shared - Editor - Color palette",
        defaultMessage: "Color palette"
    },
    labelSizeExtraSmall: {
        id: "record.shared.editor.size-extra-small",
        description: "Record - Shared - Editor - Size extra small",
        defaultMessage: "Extra small"
    },
    labelSizeSmall: {
        id: "record.shared.editor.size-small",
        description: "Record - Shared - Editor - Size small",
        defaultMessage: "Small"
    },
    labelSizeMediumSmall: {
        id: "record.shared.editor.size-medium-small",
        description: "Record - Shared - Editor - Size medium small",
        defaultMessage: "Medium small"
    },
    labelSizeMedium: {
        id: "record.shared.editor.size-medium",
        description: "Record - Shared - Editor - Size medium",
        defaultMessage: "Medium"
    },
    labelSizeMediumLarge: {
        id: "record.shared.editor.size-medium-large",
        description: "Record - Shared - Editor - Size medium large",
        defaultMessage: "Medium large"
    },
    labelSizeLarge: {
        id: "record.shared.editor.size-large",
        description: "Record - Shared - Editor - Size large",
        defaultMessage: "Large"
    },
    labelSizeExtraLarge: {
        id: "record.shared.editor.size-extra-large",
        description: "Record - Shared - Editor - Size extra large",
        defaultMessage: "Extra large"
    },
    labelButtonCancel: {
        id: "record.shared.editor.button-cancel",
        description: "Record - Shared - Editor - Button cancel",
        defaultMessage: "Cancel"
    },
    labelButtonUndo: {
        id: "record.shared.editor.button-undo",
        description: "Record - Shared - Editor - Button undo",
        defaultMessage: "Undo"
    },
    labelButtonRedo: {
        id: "record.shared.editor.button-redo",
        description: "Record - Shared - Editor - Button redo",
        defaultMessage: "Redo"
    },
    labelButtonRevert: {
        id: "record.shared.editor.button-revert",
        description: "Record - Shared - Editor - Button revert",
        defaultMessage: "Revert"
    },
    labelButtonExport: {
        id: "record.shared.editor.button-export",
        description: "Record - Shared - Editor - Button export",
        defaultMessage: "Done"
    },
    labelZoomIn: {
        id: "record.shared.editor.zoom-in",
        description: "Record - Shared - Editor - Zoom in",
        defaultMessage: "Zoom in"
    },
    labelZoomOut: {
        id: "record.shared.editor.zoom-out",
        description: "Record - Shared - Editor - Zoom out",
        defaultMessage: "Zoom out"
    },
    labelZoomFit: {
        id: "record.shared.editor.zoom-fit",
        description: "Record - Shared - Editor - Zoom fit",
        defaultMessage: "Fit to view"
    },
    labelZoomActual: {
        id: "record.shared.editor.zoom-actual",
        description: "Record - Shared - Editor - Zoom actual",
        defaultMessage: "Actual size"
    },
    statusButtonClose: {
        id: "record.shared.editor.status-button-close",
        description: "Record - Shared - Editor - Status button close",
        defaultMessage: "Close"
    },
    statusLoadImageWaiting: {
        id: "record.shared.editor.status-load-image-waiting",
        description: "Record - Shared - Editor - Status load image waiting",
        defaultMessage: "Waiting for image"
    },
    statusLoadImageTooSmall: {
        id: "record.shared.editor.status-load-image-too-small",
        description: "Record - Shared - Editor - Status load image too small",
        defaultMessage: "Minimum image size is {minWidth}×{minHeight}"
    },
    statusLoadImageError: {
        id: "record.shared.editor.status-load-image-error",
        description: "Record - Shared - Editor - Status load image error",
        defaultMessage: "Error while loading image"
    },
    statusLoadImagePreparing: {
        id: "record.shared.editor.status-load-image-preparing",
        description: "Record - Shared - Editor - Status load image preparing",
        defaultMessage: "Preparing image…"
    },
    statusLoadImageLoading: {
        id: "record.shared.editor.status-load-image-loading",
        description: "Record - Shared - Editor - Status load image loading",
        defaultMessage: "Loading image…"
    },
    statusProcessImageUploadError: {
        id: "record.shared.editor.status-process-image-upload-error",
        description: "Record - Shared - Editor - Status process image upload error",
        defaultMessage: "Error while uploading image"
    },
    statusProcessImageUploading: {
        id: "record.shared.editor.status-process-image-uploading",
        description: "Record - Shared - Editor - Status process image uploading",
        defaultMessage: "Uploading image…"
    },
    statusProcessImageError: {
        id: "record.shared.editor.status-process-image-error",
        description: "Record - Shared - Editor - Status process image error",
        defaultMessage: "Error while processing image"
    },
    statusProcessImageProcessing: {
        id: "record.shared.editor.status-process-image-processing",
        description: "Record - Shared - Editor - Status process image processing",
        defaultMessage: "Processing image…"
    },

    cropLabel: {
        id: "record.shared.editor.crop",
        description: "Record - Shared - Editor - Crop",
        defaultMessage: "Crop"
    },
    cropLabelButtonRecenter: {
        id: "record.shared.editor.crop-button-recenter",
        description: "Record - Shared - Editor - Crop button recenter",
        defaultMessage: "Recenter"
    },
    cropLabelButtonRotateLeft: {
        id: "record.shared.editor.crop-button-rotate-left",
        description: "Record - Shared - Editor - Crop button rotate left",
        defaultMessage: "Rotate left"
    },
    cropLabelButtonRotateRight: {
        id: "record.shared.editor.crop-button-rotate-right",
        description: "Record - Shared - Editor - Crop button rotate right",
        defaultMessage: "Rotate right"
    },
    cropLabelButtonFlipHorizontal: {
        id: "record.shared.editor.crop-button-flip-horizontal",
        description: "Record - Shared - Editor - Crop button flip horizontally",
        defaultMessage: "Flip horizontally"
    },
    cropLabelButtonFlipVertical: {
        id: "record.shared.editor.crop-button-flip-vertical",
        description: "Record - Shared - Editor - Crop button flip vertically",
        defaultMessage: "Flip vertically"
    },
    cropLabelSelectPreset: {
        id: "record.shared.editor.crop-select-preset",
        description: "Record - Shared - Editor - Crop select preset",
        defaultMessage: "Crop shape"
    },
    cropLabelCropBoundary: {
        id: "record.shared.editor.crop-boundary",
        description: "Record - Shared - Editor - Crop boundary",
        defaultMessage: "Crop boundary"
    },
    cropLabelCropBoundaryEdge: {
        id: "record.shared.editor.crop-boundary-edge",
        description: "Record - Shared - Editor - Crop boundary edge",
        defaultMessage: "Edge of image"
    },
    cropLabelCropBoundaryNone: {
        id: "record.shared.editor.crop-boundary-none",
        description: "Record - Shared - Editor - Crop boundary none",
        defaultMessage: "None"
    },
    cropLabelTabRotation: {
        id: "record.shared.editor.crop-tab-rotation",
        description: "Record - Shared - Editor - Crop tab rotation",
        defaultMessage: "Rotation"
    },
    cropLabelTabZoom: {
        id: "record.shared.editor.crop-tab-zoom",
        description: "Record - Shared - Editor - Crop tab zoom",
        defaultMessage: "Scale"
    },

    trimLabel: {
        id: "record.shared.editor.trim",
        description: "Record - Shared - Editor - Trim",
        defaultMessage: "Trim"
    },
    trimLabelPlay: {
        id: "record.shared.editor.trim-play",
        description: "Record - Shared - Editor - Trim play",
        defaultMessage: "Play"
    },
    trimLabelPause: {
        id: "record.shared.editor.trim-pause",
        description: "Record - Shared - Editor - Trim pause",
        defaultMessage: "Pause"
    },
    trimLabelMute: {
        id: "record.shared.editor.trim-mute",
        description: "Record - Shared - Editor - Trim mute",
        defaultMessage: "Mute"
    },
    trimLabelUnmute: {
        id: "record.shared.editor.trim-unmute",
        description: "Record - Shared - Editor - Trim unmute",
        defaultMessage: "Unmute"
    },
    trimLabelSplit: {
        id: "record.shared.editor.trim-split",
        description: "Record - Shared - Editor - Trim split",
        defaultMessage: "Split"
    },
    trimLabelMerge: {
        id: "record.shared.editor.trim-merge",
        description: "Record - Shared - Editor - Trim merge",
        defaultMessage: "Merge"
    },
    trimLabelCropBoundaryEdge: {
        id: "record.shared.editor.trim-boundary-edge",
        description: "Record - Shared - Editor - Trim boundary edge",
        defaultMessage: "Edge of video"
    },

    statusLoadMediaWaiting: {
        id: "record.shared.editor.status-load-media-waiting",
        description: "Record - Shared - Editor - Status load media waiting",
        defaultMessage: "Waiting for video"
    },
    statusLoadMediaTooSmall: {
        id: "record.shared.editor.status-load-media-too-small",
        description: "Record - Shared - Editor - Status load media too small",
        defaultMessage: "Minimum video size is {minWidth}×{minHeight}"
    },
    statusLoadMediaError: {
        id: "record.shared.editor.status-load-media-error",
        description: "Record - Shared - Editor - Status load media error",
        defaultMessage: "Error while loading video"
    },
    statusLoadMediaPreparing: {
        id: "record.shared.editor.status-load-media-preparing",
        description: "Record - Shared - Editor - Status load media preparing",
        defaultMessage: "Preparing video…"
    },
    statusLoadMediaLoading: {
        id: "record.shared.editor.status-load-media-loading",
        description: "Record - Shared - Editor - Status load media loading",
        defaultMessage: "Loading video…"
    },
    statusProcessMediaUploadError: {
        id: "record.shared.editor.status-process-media-upload-error",
        description: "Record - Shared - Editor - Status process media upload error",
        defaultMessage: "Error while uploading video"
    },
    statusProcessMediaUploading: {
        id: "record.shared.editor.status-process-media-uploading",
        description: "Record - Shared - Editor - Status process media uploading",
        defaultMessage: "Uploading video…"
    },
    statusProcessMediaError: {
        id: "record.shared.editor.status-process-media-error",
        description: "Record - Shared - Editor - Status process media error",
        defaultMessage: "Error while processing video"
    },
    statusProcessMediaProcessing: {
        id: "record.shared.editor.status-process-media-processing",
        description: "Record - Shared - Editor - Status process media processing",
        defaultMessage: "Processing video…"
    }
});

export function useEditorLocale(utils: string[] = []) {
    const intl = useIntl();

    const core = {
        ...locale_en_gb,

        labelReset: intl.formatMessage(messages.labelReset),
        labelDefault: intl.formatMessage(messages.labelDefault),
        labelAuto: intl.formatMessage(messages.labelAuto),
        labelNone: intl.formatMessage(messages.labelNone),
        labelEdit: intl.formatMessage(messages.labelEdit),
        labelClose: intl.formatMessage(messages.labelClose),
        labelSupportError: (features: string[]) => intl.formatMessage(messages.labelSupportError, {
            features: features.join(', ')
        }),

        labelColor: intl.formatMessage(messages.labelColor),
        labelWidth: intl.formatMessage(messages.labelWidth),
        labelSize: intl.formatMessage(messages.labelSize),
        labelOffset: intl.formatMessage(messages.labelOffset),
        labelAmount: intl.formatMessage(messages.labelAmount),
        labelInset: intl.formatMessage(messages.labelInset),
        labelRadius: intl.formatMessage(messages.labelRadius),

        labelColorPalette: intl.formatMessage(messages.labelColorPalette),

        labelSizeExtraSmall: intl.formatMessage(messages.labelSizeExtraSmall),
        labelSizeSmall: intl.formatMessage(messages.labelSizeSmall),
        labelSizeMediumSmall: intl.formatMessage(messages.labelSizeMediumSmall),
        labelSizeMedium: intl.formatMessage(messages.labelSizeMedium),
        labelSizeMediumLarge: intl.formatMessage(messages.labelSizeMediumLarge),
        labelSizeLarge: intl.formatMessage(messages.labelSizeLarge),
        labelSizeExtraLarge: intl.formatMessage(messages.labelSizeExtraLarge),

        labelButtonCancel: intl.formatMessage(messages.labelButtonCancel),
        labelButtonUndo: intl.formatMessage(messages.labelButtonUndo),
        labelButtonRedo: intl.formatMessage(messages.labelButtonRedo),
        labelButtonRevert: intl.formatMessage(messages.labelButtonRevert),
        labelButtonExport: intl.formatMessage(messages.labelButtonExport),

        labelZoomIn: intl.formatMessage(messages.labelZoomIn),
        labelZoomOut: intl.formatMessage(messages.labelZoomOut),
        labelZoomFit: intl.formatMessage(messages.labelZoomFit),
        labelZoomActual: intl.formatMessage(messages.labelZoomActual),

        statusButtonClose: intl.formatMessage(messages.statusButtonClose),
        statusLabelLoadImage: (state: any) => {
            if (!state || !state.task) return intl.formatMessage(messages.statusLoadImageWaiting);
            if (state.error)
                return state.error.code === 'IMAGE_TOO_SMALL'
                    ?  intl.formatMessage(messages.statusLoadImageTooSmall)
                    :  intl.formatMessage(messages.statusLoadImageError);
            if (state.task === 'blob-to-bitmap') return intl.formatMessage(messages.statusLoadImagePreparing);
            return intl.formatMessage(messages.statusLoadImageLoading);
        },

        statusLabelProcessImage: (state: any) => {
            if (!state || !state.task) return undefined;
            if (state.task === 'store') {
                if (state.error) return intl.formatMessage(messages.statusProcessImageUploadError);
                return intl.formatMessage(messages.statusProcessImageUploading);
            }
            if (state.error) return intl.formatMessage(messages.statusProcessImageError);
            return intl.formatMessage(messages.statusProcessImageProcessing);
        }
    };

    const crop = {
        ...plugin_crop_locale_en_gb,

        cropLabel: intl.formatMessage(messages.cropLabel),

        cropLabelButtonRecenter: intl.formatMessage(messages.cropLabelButtonRecenter),
        cropLabelButtonRotateLeft: intl.formatMessage(messages.cropLabelButtonRotateLeft),
        cropLabelButtonRotateRight: intl.formatMessage(messages.cropLabelButtonRotateRight),
        cropLabelButtonFlipHorizontal: intl.formatMessage(messages.cropLabelButtonFlipHorizontal),
        cropLabelButtonFlipVertical: intl.formatMessage(messages.cropLabelButtonFlipVertical),

        cropLabelSelectPreset: intl.formatMessage(messages.cropLabelSelectPreset),

        cropLabelCropBoundary: intl.formatMessage(messages.cropLabelCropBoundary),
        cropLabelCropBoundaryEdge: intl.formatMessage(messages.cropLabelCropBoundaryEdge),
        cropLabelCropBoundaryNone: intl.formatMessage(messages.cropLabelCropBoundaryNone),

        cropLabelTabRotation: intl.formatMessage(messages.cropLabelTabRotation),
        cropLabelTabZoom: intl.formatMessage(messages.cropLabelTabZoom)
    };

    const trim = {
        ...plugin_trim_locale_en_gb,

        trimLabel: intl.formatMessage(messages.trimLabel),

        trimLabelPlay: intl.formatMessage(messages.trimLabelPlay),
        trimLabelPause: intl.formatMessage(messages.trimLabelPause),

        trimLabelMute: intl.formatMessage(messages.trimLabelMute),
        trimLabelUnmute: intl.formatMessage(messages.trimLabelUnmute),

        trimLabelSplit: intl.formatMessage(messages.trimLabelSplit),
        trimLabelMerge: intl.formatMessage(messages.trimLabelMerge),

        statusLabelLoadImage: (state: any) => {
            if (!state || !state.task) return intl.formatMessage(messages.statusLoadMediaWaiting);
            if (state.error)
                return state.error.code === 'IMAGE_TOO_SMALL'
                    ?  intl.formatMessage(messages.statusLoadMediaTooSmall)
                    :  intl.formatMessage(messages.statusLoadMediaError);
            if (state.task === 'blob-to-bitmap') return intl.formatMessage(messages.statusLoadMediaPreparing);
            return intl.formatMessage(messages.statusLoadMediaLoading);
        },

        statusLabelProcessImage: (state: any) => {
            if (!state || !state.task) return undefined;
            if (state.task === 'store') {
                if (state.error) return intl.formatMessage(messages.statusProcessMediaUploadError);
                return intl.formatMessage(messages.statusProcessMediaUploading);
            }
            if (state.error) return intl.formatMessage(messages.statusProcessMediaError);
            return intl.formatMessage(messages.statusProcessMediaProcessing);
        },

        cropLabelCropBoundaryEdge: intl.formatMessage(messages.trimLabelCropBoundaryEdge),
    };

    return {
        ...core,
        ...(utils.includes('crop') && crop),
        ...(utils.includes('trim') && trim)
    };
}
