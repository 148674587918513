import React from 'react';
import ReactDOM from 'react-dom/client';
import {IntlProvider} from "react-intl";
import {BrowserRouter} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import {CssBaseline, ThemeProvider} from "@mui/material";
import * as Sentry from "@sentry/react";

import {sdk} from "./shared/services/sdk"
import {LocaleLoader} from "./LocaleLoader";
import {ErrorBoundary} from "./ErrorBoundary";
import {TrackingBoundary} from "./TrackingBoundary";
import {Metadata} from "./Metadata";
import {App} from './App';
import {Bridge} from "./Bridge";
import {StoreService} from "./shared/services/StoreService";
import {ThemeLoader} from "./ThemeLoader";
import {GA4} from "./shared/GA4";

import * as Hotjar from "./shared/Hotjar";

import './index.scss';


// Initialize Sentry
Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.NODE_ENV,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
        new Sentry.Replay({
            maskAllText: false,
            blockAllMedia: false
        })
    ]
});

// Create the root
const root = ReactDOM.createRoot(
    document.getElementById('root')
);

// Initialize bridge for iframe-parent communication
const bridge = new Bridge();
bridge.addEventListener('message', handleMessage);
bridge.addEventListener('error', console.error);

// Initialize the app immediately if we're not in an iframe
if (!isIframe()) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const albumId         = urlSearchParams.get('a');
    const videoId         = urlSearchParams.get('v');
    const videoKey        = urlSearchParams.get('k');
    const accessToken     = urlSearchParams.get('t');
    const locale          = urlSearchParams.get('l');
    const trackingEnabled = urlSearchParams.get('te');
    const imageSelectable = urlSearchParams.get('is');
    const mediaSources    = urlSearchParams.get('msrc');
    const imageSources    = urlSearchParams.get('isrc');
    const ga4ClientId     = urlSearchParams.get('gac');
    const ga4SessionId    = urlSearchParams.get('gas');

    init({
        isOverlay       : false,
        locale          : locale,
        trackingEnabled : trackingEnabled ? trackingEnabled === '1' : null,
        trackingParams  : {
            ga4ClientId  : ga4ClientId ?? undefined,
            ga4SessionId : ga4SessionId ?? undefined,
        },
        accessToken     : accessToken || process.env.REACT_APP_ACCESS_TOKEN,
        cdnBaseUrl      : process.env.REACT_APP_CDN_BASE_URL,
        apiBaseUrl      : process.env.REACT_APP_API_BASE_URL,
        params          : {
            albumId         : albumId,
            videoId         : videoId,
            videoKey        : videoKey || undefined,
            imageSelectable : imageSelectable ? imageSelectable === '1' : undefined,
            mediaSources    : mediaSources?.split(','),
            imageSources    : imageSources?.split(',')
        }
    });
}


/**
 * Determine whether we are running inside an iframe
 *
 * @returns {boolean}
 *     Whether we are running inside an iframe.
 */
function isIframe() {
    return window.self !== window.parent;
}

/**
 * Initialize the app
 *
 * @param {Object} config
 *     The app configuration.
 */
async function init(config) {
    try {

        // Initialize the SDK
        sdk.init({
            accessToken: config.accessToken,
            apiBaseUrl: config.apiBaseUrl,
            apiVersion: process.env.REACT_APP_API_VERSION
        });

        // Load the store
        const store = await StoreService.get('me', ['id', 'web_domain', 'theme', 'ga_measurement_id', 'hotjar_id', 'groove_id']);

        // Redirect to the store's domain if needed
        const storeHostname = store.web_domain.split(':')[0];
        if (!isIframe() && (window.location.hostname !== storeHostname)) {
            window.location.hostname = storeHostname;
            return;
        }

        // Load the theme
        const theme = await new ThemeLoader().load(store.theme);

        // Load the locale
        const defaultLocale = 'en';
        const overridePath = store.id.toString();
        const {locale, messages} = await new LocaleLoader().load(defaultLocale, config.locale, overridePath);

        // Add the groove ID to the config
        config.grooveId = store.groove_id;

        // Initialize tracking
        const initTracking = (isTracking) => {
            if (isTracking && store.ga_measurement_id) {
                GA4.init(store.ga_measurement_id, {
                    cookie_flags: isIframe() ? 'samesite=none;secure' : undefined,
                    language: locale,
                    client_id: config.trackingParams?.ga4ClientId,
                    session_id: config.trackingParams?.ga4SessionId
                });
            }

            if (isTracking && store.hotjar_id) {
                Hotjar.init(store.hotjar_id);
            }
        };

        // Render the app
        root.render(
            <ThemeProvider theme={theme}>
                <IntlProvider
                    locale={locale}
                    messages={messages}
                    defaultLocale={defaultLocale}
                    fallbackOnEmptyString={false}
                >
                    <CssBaseline/>
                    <Metadata/>
                    <ErrorBoundary confirm={config.isOverlay} onError={handleError}>
                        <TrackingBoundary
                            disabled={!(store.ga_measurement_id || store.hotjar_id)}
                            isTracking={config.trackingEnabled}
                            canClose={config.isOverlay}
                            onClose={handleClose}
                            onChange={initTracking}
                        >
                            <BrowserRouter>
                                <App config={config} onSuccess={handleSuccess} onClose={handleClose}/>
                            </BrowserRouter>
                        </TrackingBoundary>
                    </ErrorBoundary>
                </IntlProvider>
            </ThemeProvider>
        );

    } catch (error) {
        handleError(new Error(`Error initializing app: ${error.toString()}`));
    }
}

function handleMessage(event) {
    init(event.detail);
}

function handleClose() {
    handleSuccess(null);
}

function handleSuccess(data) {
    if (isIframe()) {
        // Release resources (and fix video continuing to play after close)
        root.unmount();

        bridge.sendMessage({
            success: true,
            response: data
        });
    }
}

function handleError(error) {
    console.error(error);
    if (isIframe()) {
        bridge.sendMessage({
            success: false,
            response: {
                name: error.name,
                message: error.message
            }
        });
    }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
