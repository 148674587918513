import React, {useEffect, useState} from "react";
import {Outlet, Route, Routes, useNavigate} from "react-router-dom";
import {Box, CircularProgress} from "@mui/material";

import {UploadManager} from "./shared/UploadManager";
import {Completed} from "./completed/Completed";
import {ErrorPage} from "../error/ErrorPage";
import {Update} from "./update/Update";
import {Media} from "./media/Media";
import {Image} from "./image/Image";
import {CloseDialog} from "./shared/CloseDialog";
import {VideoService} from "../shared/services/VideoService";
import {VideoModel} from "../shared/models/VideoModel";
import {Preview} from "./preview/Preview";
import {GA4} from "../shared/GA4";
import {Confirm} from "./confirm/Confirm";
import {PresetService} from "../shared/services/PresetService";
import {useRecordDispatch, useRecordState} from "../shared/reducers/RecordStateProvider";


interface PresetParams {
    width: number;
    height: number;
    overlay?: string | Blob;
    alpha_mask?: string | Blob;
}

export interface RecordParams {
    videoId?: string;
    videoKey?: string;
    presetId?: number;
    preset?: PresetParams;
    imageSelectable?: boolean;
    mediaSources?: string[];
    imageSources?: string[];
}

interface RecordProps {
    params: RecordParams;
    accessToken: string;
    canClose: boolean;
    onClose: () => void;
    onSuccess: (video: VideoModel) => void;
}

enum Step {
    Completed = 'completed',
    Media = 'media',
    Image = 'image',
    Preview = 'preview',
    Confirm = 'confirm',
    Update = 'update'
}

const uploadManager = new UploadManager();

export function Record({
    params,
    accessToken,
    canClose,
    onClose,
    onSuccess
}: RecordProps) {

    const state = useRecordState();
    const dispatch = useRecordDispatch();
    const navigate = useNavigate();

    const [steps, setSteps] = useState<Step[]>([]);
    const [video, setVideo] = useState<VideoModel>();
    const [mediaUploadKey, setMediaUploadKey] = useState<string>();
    const [imageUploadKey, setImageUploadKey] = useState<string>();
    const [isClosing, setIsClosing] = useState<boolean>(false);

    const videoData = state.media.data;
    const imageData = state.image.data;

    useEffect(() => {
        getVideo(params).then(video => {
            setVideo(video);
            setSteps(getSteps(video, params.imageSelectable ?? true));
        });
    }, [params]);

    useEffect(() => {
        if (steps.length) {
            navigate(steps[0], { replace: true });
        }
        // @see https://github.com/remix-run/react-router/issues/7634
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [steps]);

    const gotoNextStep = (currentStep: Step) => {
        navigate(steps[steps.indexOf(currentStep) + 1]);
    };

    const gotoPreviousStep = () => {
        navigate(-1);
    };

    const handleMediaAccepted = (data: Blob) => {
        // Persist the media
        dispatch({ type: 'setMediaData', payload: data });

        // Cancel and remove previous media upload
        if (mediaUploadKey) {
            uploadManager.remove(mediaUploadKey);
        }

        // Start uploading the media
        setMediaUploadKey(uploadManager.add(data));

        // Navigate
        gotoNextStep(Step.Media);
    };

    const handleImageAccepted = (data: Blob) => {
        // Persist the image
        dispatch({ type: 'setImageData', payload: data });

        // Cancel and remove previous image upload
        if (imageUploadKey) {
            uploadManager.remove(imageUploadKey);
        }

        // Start uploading the image
        setImageUploadKey(uploadManager.add(data));

        // Navigate
        gotoNextStep(Step.Image);
    };

    const handlePreviewAccepted = () => {
        gotoNextStep(Step.Preview);
    };

    const handleConfirmAccepted = () => {
        gotoNextStep(Step.Confirm);
    };

    const handleUpdated = () => {
        getVideo({ videoId: video!.id }).then(video => {
            setVideo(video);
            onSuccess(video);
        });
    };

    const handleClose = () => {
        if (videoData || imageData) {
            setIsClosing(true);
        } else {
            handleCloseConfirm();
        }
    };

    const handleCloseConfirm = () => {
        GA4.sendEvent('record_close');
        onClose();
    };

    const handleCloseCancel = () => {
        setIsClosing(false);
    };

    const handleBack = () => {
        GA4.sendEvent('record_back');
        gotoPreviousStep();
    };

    const imageSelectable = params.imageSelectable ?? true;
    const mediaSources = params.mediaSources ?? ['file', 'camera', 'mobile'];
    const imageSources = params.imageSources ?? ['file', 'photo', 'still'];

    const load =
        <Box sx={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
           <CircularProgress />
        </Box>;

    const root =
        <>
            <CloseDialog isOpen={isClosing}
                         onConfirm={handleCloseConfirm}
                         onCancel={handleCloseCancel} />
            <Outlet />
        </>;

    const completed =
        <Completed canClose={canClose}
                   onClose={handleClose} />;

    const media = video && video.preset &&
        <Media
            video={video}
            mediaSources={mediaSources}
            imageSources={imageSources}
            maxDuration={video.max_duration}
            preset={video.preset}
            accessToken={accessToken}
            imageSelectable={imageSelectable}
            canClose={canClose}
            onClose={handleClose}
            onAccept={handleMediaAccepted}
            onDone={onSuccess}
        />;

    const image = videoData && video?.preset &&
        <Image
            imageSources={imageSources}
            videoData={videoData}
            preset={video.preset}
            onAccept={handleImageAccepted}
        />;

    const preview = videoData && video && video.preset &&
        <Preview
            imageData={imageData || video.target_image}
            videoData={videoData}
            preset={video.preset}
            maxDuration={video.max_duration}
            onAccept={handlePreviewAccepted}
            onBack={handleBack}
        />;

    const confirm =
        <Confirm
            onBack={handleBack}
            onConfirm={handleConfirmAccepted}
        />;

    const update = video && mediaUploadKey &&
        <Update
            videoId={video.id}
            updateImage={imageSelectable}
            selfComplete={video.order_self_complete}
            uploadManager={uploadManager}
            mediaUploadKey={mediaUploadKey}
            imageUploadKey={imageUploadKey}
            onUpdated={handleUpdated}
            onBack={handleBack}
        />;

    return (
        <Routes>
            <Route path="/" element={ root }>
                <Route index element={ load } />
                <Route path="completed" element={ completed } />
                <Route path="media/*" element={ media } />
                <Route path="image/*" element={ image } />
                <Route path="preview" element={ preview } />
                <Route path="confirm" element={ confirm } />
                <Route path="update" element={ update } />
                <Route path="*" element={ <ErrorPage /> } />
            </Route>
        </Routes>
    );
}

function getSteps(video: VideoModel, imageSelectable: boolean): Step[] {
    if (video.order_completed) {
        return [Step.Completed];
    }

    const steps = [Step.Media];

    if (imageSelectable) {
        steps.push(Step.Image);
    }

    if (video.preset?.preview_background && video.preset?.preview_matrix3d) {
        steps.push(Step.Preview);
    }

    if (video.order_self_complete) {
        steps.push(Step.Confirm);
    }

    steps.push(Step.Update);

    return steps;
}

async function getVideo(params: RecordParams): Promise<VideoModel> {
    if (params.videoId) {
        return VideoService.get(params.videoId, undefined, ['preset'], params.videoKey) as Promise<VideoModel>;
    }

    if (params.preset) {
        params.presetId = await createPreset(params.preset);
    }

    if (params.presetId) {
        return VideoService.post({ order_preset: params.presetId }, undefined, ['preset']) as Promise<VideoModel>;
    }

    return Promise.reject(new Error("Missing 'videoId', 'presetId' or 'preset', parameter for record dialog."));
}

async function createPreset(params: PresetParams): Promise<number> {

    const [alphaMask, overlay] = await Promise.all([
        resolveBlob(params.alpha_mask),
        resolveBlob(params.overlay)
    ]);

    const preset = await PresetService.post({
        name: 'Auto-generated preset',
        width: params.width,
        height: params.height
    }, ['id', 'key']);

    await Promise.all([
        alphaMask && PresetService.putAlphaMask(preset.id!, alphaMask, preset.key!),
        overlay && PresetService.putOverlay(preset.id!, overlay, preset.key!)
    ]);

    return preset.id!;
}

async function resolveBlob(source?: string|Blob): Promise<Blob|null> {
    if (!source) {
        return null;
    }

    if (source instanceof Blob) {
        return source;
    }

    if (isUrl(source)) {
        return fetch(source).then(response => response.blob());
    }

    throw new Error('Source is not a Blob or a URL');
}

function isUrl(value: string): boolean {
    try {
        new URL(value);
        return true;
    } catch (e) {
        return false;
    }
}
