import React, {useCallback, useEffect, useRef, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {Box, Button, Stack, Typography} from "@mui/material";
import {GA4} from "../../../shared/GA4";
import {PresetMask} from "../../../shared/PresetMask";
import {Video} from "../../../shared/Video";
import {CameraSelector} from "../../shared/CameraSelector";
import {RecordButton} from "./RecordButton";
import {SimpleMediaRecorder} from "./SimpleMediaRecorder";

import './Recorder.scss';
import {PresetModel} from "../../../shared/models/PresetModel";


/**
 *
 * A component for recording a video from camera
 *
 * MIME TYPES
 * - Do not explicitly specify codecs in the source constraints (e.g. use 'video/webm' instead of
 *   'video/webm;codecs=vp9,opus') because some browsers will incur a huge performance hit attempting to comply with the
 *   constraint.
 * - For cross browser compatibility, include both 'video/webm' and 'video/mp4' MIME types in the source constraints.
 *
 * AUDIO CONSTRAINTS
 * - Keep the channel count to 1 unless you absolutely need a multi-channel audio source. The default microphone on
 *   some webcams and mobile devices is mono, causing a secondary (e.g. external or virtual) audio input to be selected
 *   when requesting multi-channel audio. We could investigate forcing a particular audio device based on the group ID
 *   of the selected camera (therein taking into account that some browsers do not report a group ID).
 *
 * RESOLUTION
 * - Keep in mind most webcams and user-facing mobile cameras have a 720p resolution.
 * - Firefox reports no match if an exact custom resolution is requested, whereas other browsers scale and crop.
 * - Resolution constraints are a mess, e.g. given constraints:
 *
 *   <code>
 *       video: {
 *           width:  { min: 1280, ideal: 1920, max: 1920 },
 *           height: { min:  720, ideal: 1080, max: 1080 }
 *       }
 *   </code>
 *
 *   + Windows Chrome doesn't match a 1280x720 camera.
 *   + iPad matches a 1280x960 camera, while 1920x1080 is available.
 *
 *   We could investigate support for advanced constraints to deal with this
 *   @see https://developer.mozilla.org/en-US/docs/Web/API/Media_Streams_API/Constraints#advanced_constraints
 *
 * ORIENTATION
 * - Mobile devices will flip the video width and height when held in portrait orientation (except Firefox on
 *   Windows). Most browsers do not report the flipped resolution in the MediaTrackSettings. However, after attaching
 *   the camera stream to a video element, the .videoWidth and .videoHeight properties are set correctly.
 * - Browser behaviour is inconsistent in case the device orientation changes while a camera stream is running. Some
 *   flip the resolution of the stream, whereas others do not.
 *
 * KEYFRAMES
 * - We cannot influence the number of keyframes generated during recording. Adding a timeslice parameter to
 *   MediaRecorder.start() does not result in more keyframes.
 */

enum Step {
    Initializing,
    Permitting,
    Previewing,
    Recording,
    Error
}

interface RecorderProps {
    preset: PresetModel;
    maxDuration: number;
    mimeTypes?: string[];
    sourceConstraints?: MediaStreamConstraints;
    audioBitsPerSecond?: number;
    videoBitsPerSecond?: number;
    onRecorded: (data: Blob) => void;
    onError: (error: Error) => void;
}

export function Recorder({
     preset,
     maxDuration,
     mimeTypes = [
         'video/mp4',
         'video/webm'
     ],
     sourceConstraints = {
         audio: {
             sampleSize: 16,
             sampleRate: 44100,
             channelCount: 1
         },
         video: {
             width: { min: 640, ideal: 1280, max: 1920 },
             height: { min: 360, ideal: 720, max: 1080 },
             aspectRatio: 16 / 9,
             frameRate: 30
         }
     },
     audioBitsPerSecond = 128000,
     videoBitsPerSecond = 2500000,
     onRecorded,
     onError
 }: RecorderProps) {

    const [step, setStep] = useState(Step.Initializing);
    const [cameraId, setCameraId] = useState<string>();
    const [cameraStream, setCameraStream] = useState<MediaStream|null>(null);
    const [cameraIsMirrored, setCameraIsMirrored] = useState<boolean>();
    const [duration, setDuration] = useState<number>(0);

    const mimeType = useRef<string>();
    const mediaRecorder = useRef<SimpleMediaRecorder>();

    const handleError = useCallback((error: Error) => {
        setStep(Step.Error);
        onError(error);
    }, [onError]);

    useEffect(() => {
        mimeType.current = mimeTypes.reduce((firstMimeType: string|undefined, mimeType) => {
            return firstMimeType || (SimpleMediaRecorder.isTypeSupported(mimeType) ? mimeType : undefined);
        }, undefined);

        if (!mimeType.current) {
            handleError(new Error('No suitable MIME type found for video recorder'));
        }
    }, [mimeTypes, handleError]);

    useEffect(() => {
        startCameraStream().then();
        return () => {
            stopCameraStream();
        }
        // startCameraStream and stopCameraStream dependencies are omitted. Should be effect events (but useEffectEvent is not available yet).
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cameraId]);

    useEffect(() => {
        let durationTimer: NodeJS.Timeout;

        if (step === Step.Recording) {
            setDuration(0);

            durationTimer = setInterval(() => {
                setDuration(duration => {
                    if (maxDuration && (duration + 1 >= maxDuration)) {
                        handleRecordStop();
                    }
                    return duration + 1;
                });
            }, 1000);
        }

        return () => {
            if (durationTimer) {
                clearInterval(durationTimer);
            }
        };

        // handleRecordStop dependency is omitted. Should be an effect event (but useEffectEvent is not available yet).
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step, maxDuration]);

    const startCameraStream = async () => {
        const constraints = {
            ...sourceConstraints,
            video: {
                ...(sourceConstraints.video === true && {
                    deviceId: cameraId
                }),
                ...(typeof sourceConstraints.video === 'object' && {
                    ...sourceConstraints.video,
                    deviceId: cameraId
                })
            }
        };

        try {
            const cameraStream = await window.navigator.mediaDevices.getUserMedia(constraints);
            const audioTracks = cameraStream.getAudioTracks();
            const videoTracks = cameraStream.getVideoTracks();

            /*
             * Check that we obtained both a video and audio stream, since Chrome will provide only
             * an audio stream if the user has dismissed the video permission prompt several times.
             * @see https://www.chromestatus.com/features/6443143280984064
             */
            if (!audioTracks.length || !videoTracks.length) {
                throw new DOMException('Permission partially denied', 'NotAllowedError');
            }

            /*
             * User facing camera streams are not automatically mirrored horizontally.
             * Facing modes 'user', 'left' and 'right' face toward the user by definition. Some devices
             * provide an empty value, which is user facing on all devices encountered so far.
             */
            const videoSettings = videoTracks[0].getSettings();
            const cameraIsMirrored = videoSettings.facingMode !== 'environment';

            setCameraId(videoSettings.deviceId);
            setCameraStream(cameraStream);
            setCameraIsMirrored(cameraIsMirrored);
            setStep(Step.Previewing);

            GA4.sendEvent('record_media_camera_preview_start', {
                video_width: videoSettings.width,
                video_height: videoSettings.height,
                video_facing_user: cameraIsMirrored
            });
        } catch (error) {
            if (error instanceof DOMException && error.name === 'NotAllowedError') {
                setStep(Step.Permitting);
            } else {
                handleError(error as Error);
            }
        }
    };

    const stopCameraStream = () => {
        if (cameraStream) {
            cameraStream.getTracks().forEach(track => track.stop());
            setCameraStream(null);
        }
    };

    const handleRecordStart = () => {
        try {
            if (!cameraStream) {
                throw new Error('No active camera stream to record');
            }
            if (!mimeType.current) {
                throw new Error('No MIME type set to record in');
            }

            mediaRecorder.current = new SimpleMediaRecorder({
                mediaStream: cameraStream,
                mimeType: mimeType.current,
                audioBitsPerSecond: audioBitsPerSecond,
                videoBitsPerSecond: videoBitsPerSecond,
                onRecorded: handleRecordEnded,
                onError: handleError
            });
            mediaRecorder.current.start();

            setStep(Step.Recording);
            GA4.sendEvent('record_media_camera_record_start');
        } catch (error) {
            handleError(error as Error);
        }
    };

    const handleRecordStop = () => {
        if (!mediaRecorder.current) {
            throw new Error('MediaRecorder not initialized');
        }

        try {
            mediaRecorder.current.stop();
            GA4.sendEvent('record_media_camera_record_stop', { video_duration: duration });
        } catch (error) {
            handleError(error as Error);
        }
    };

    const handleRecordEnded = (data: Blob) => {
        onRecorded(data);
    };

    if (step === Step.Error) {
        return null;
    }

    if (step === Step.Initializing) {
        return (
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', padding: 2}}>
                <Typography variant="body1" component="div" align="center">
                    <FormattedMessage
                        id="record.media.camera.recorder.access-request"
                        description="Record page - Select video - Camera tab - Camera and microphone access request text."
                        defaultMessage="Grant access to the camera and microphone to continue."
                    />
                </Typography>
            </Box>
        );
    }

    if (step === Step.Permitting) {
        return (
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', padding: 2}}>
                <Stack spacing={2} justifyContent="center" alignItems="center">
                    <Typography variant="body1" component="div" align="center">
                        <FormattedMessage
                            id="record.media.camera.recorder.access-denied"
                            description="Record page - Select video - Camera tab - Camera and microphone access denied text."
                            defaultMessage="Access to the camera or microphone has been denied. Change the permission settings in your browser and retry."
                        />
                    </Typography>
                    <Button variant="contained" onClick={startCameraStream}>
                        <FormattedMessage
                            id="shared.retry"
                            description="Retry button text."
                            defaultMessage="Retry"
                        />
                    </Button>
                </Stack>
            </Box>
        );
    }

    return (
        <div className="recorder-wrapper">
            <PresetMask
                preset={preset}
                mirrored={cameraIsMirrored}
            >
                <Video
                    src={cameraStream}
                    autoPlay={true}
                    muted={true}
                    objectFit="cover"
                />
            </PresetMask>
            <div className="recorder-controls">
                {step === Step.Previewing &&
                    <CameraSelector
                        value={cameraId}
                        onChange={setCameraId}
                    />
                }
                <RecordButton
                    className="recorder-controls-record"
                    isRecording={step === Step.Recording}
                    progress={duration / maxDuration}
                    onClick={step === Step.Recording ? handleRecordStop : handleRecordStart} />
            </div>
        </div>
    );
}
