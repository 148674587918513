import {Card, CardActionArea, CardContent, CardMedia, Typography} from "@mui/material";
import {ReactNode} from "react";

interface SourceCardProps {
    image: string;
    imageAlt?: string;
    title: ReactNode;
    text: ReactNode;
    onClick: () => void;
}

export function SourceCard({
    image,
    imageAlt = '',
    title,
    text,
    onClick
}: SourceCardProps) {
    return (
        <Card>
            <CardActionArea
                sx={{ display: 'flex' }}
                onClick={onClick}
            >
                <CardMedia
                    sx={{ width: '40px', height: '40px', ml: 2 }}
                    component='img'
                    image={image}
                    alt={imageAlt}
                />
                <CardContent sx={{ flexGrow: 1 }}>
                    <Typography variant="h6" component="div">
                        {title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {text}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}
