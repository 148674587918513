import React, {useState} from "react";
import {FormattedMessage} from "react-intl";
import {PhotoCamera} from "./PhotoCamera";
import {CameraError} from "./CameraError";
import {GA4} from "../../../shared/GA4";
import {PresetModel} from "../../../shared/models/PresetModel";
import {Outlet, Route, Routes, useNavigate} from "react-router-dom";
import {ErrorPage} from "../../../error/ErrorPage";
import {ErrorMessage} from "../../shared/ErrorMessage";
import {useRecordDispatch, useRecordState} from "../../../shared/reducers/RecordStateProvider";
import {PinturaImageState} from "@pqina/pintura";
import { Preview } from "../shared/Preview";
import {DialogAppBar} from "../../../shared/DialogAppBar";
import {DialogPage} from "../../../shared/DialogPage";


interface PhotoProps {
    preset: PresetModel;
    onAccept: (data: Blob) => void;
}

export function Photo({
    preset,
    onAccept
}: PhotoProps) {

    const state = useRecordState();
    const dispatch = useRecordDispatch();

    const [error, setError] = useState<Error | null>(null);

    const navigate = useNavigate();

    const imageData = state.image.photo.data;
    const originalImageData = state.image.photo.originalData;
    const editorState = state.image.photo.editorState;

    const handleCaptured = (data: Blob) => {
        dispatch( { type: 'setImagePhotoOriginalData', payload: data });
        navigate('preview');
    };

    const handleError = (error: Error) => {
        setError(error);
        GA4.sendEvent('record_image_camera_error', { error_name: error.name || 'UnknownError' });
    };

    const handleDismissError = () => {
        setError(null);
    };

    const handleEdited = (data: Blob, editorState: PinturaImageState) => {
        dispatch( { type: 'setImagePhotoEditedData', payload: { data, editorState } });
    };

    const handleNext = () => {
        if (imageData) {
            onAccept(imageData);
        }
    };

    const handleBack = () => {
        navigate(-1);
    };

    const preview = imageData && originalImageData &&
        <Preview
            imageData={imageData}
            originalImageData={originalImageData}
            preset={preset}
            editorState={editorState}
            onEdited={handleEdited}
            onNext={handleNext}
            onBack={handleBack}
        />;

    const appBar =
        <DialogAppBar
            title={<FormattedMessage
                id="record.image.photo.title"
                description="Record page - Image - Photo - Title text."
                defaultMessage="Take a picture"
            />}
            canBack={true}
            onBack={handleBack}
        />;

    const page = (
        <DialogPage appBar={appBar}>
            <ErrorMessage
                isOpen={!!error}
                pageHasFab={false}
                onClose={handleDismissError}
            >
                {error && <CameraError error={error} />}
            </ErrorMessage>
            <PhotoCamera
                preset={preset}
                onCaptured={handleCaptured}
                onError={handleError}
            />
        </DialogPage>
    );

    return (
        <Routes>
            <Route path="/" element={ <Outlet /> }>
                <Route index element={ page } />
                <Route path="preview/*" element={ preview } />
                <Route path="*" element={ <ErrorPage /> } />
            </Route>
        </Routes>
    );
}
