import React from "react";
import {FormattedMessage} from "react-intl";
import {DialogAppBar} from "../../shared/DialogAppBar";
import {DialogPage} from "../../shared/DialogPage";
import {PreviewPanel} from "../preview/PreviewPanel";
import {PresetModel} from "../../shared/models/PresetModel";
import { DialogFooter } from "../../shared/DialogFooter";


interface PreviewProps {
    imageData: Blob | string | null;
    videoData: Blob;
    preset: PresetModel;
    maxDuration: number | null;
    onAccept: () => void;
    onBack: () => void;
}

export function Preview({
    imageData,
    videoData,
    preset,
    maxDuration,
    onAccept,
    onBack
}: PreviewProps) {

    const appBar =
        <DialogAppBar
            title={
                <FormattedMessage
                    id="record.preview.title"
                    description="Record page - Preview - Title text."
                    defaultMessage="Check your order"
            />
        }
        canBack={true}
        onBack={onBack}
    />;

    const footer =
        <DialogFooter
            onNext={onAccept}
        />;

    return (
        <DialogPage
            appBar={appBar}
            footer={footer}
        >
            <PreviewPanel
                imageData={imageData}
                videoData={videoData}
                preset={preset}
                maxDuration={maxDuration}
            />
        </DialogPage>
    );
}
