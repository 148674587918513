import React, {Component} from "react";
import PropTypes from "prop-types";
import {Image} from "../../../shared/Image";
import {PresetMask} from "../../../shared/PresetMask";


export class ImageViewer extends Component {

    render() {
        return (
            <PresetMask preset={this.props.preset} >
                <Image src={this.props.source}
                       objectFit="cover" />
            </PresetMask>
        );
    }

}

ImageViewer.propTypes = {
    source: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Blob)
    ]).isRequired,
    preset: PropTypes.object.isRequired
};
