import React, {CSSProperties, useState} from "react";
import {SizeMonitor} from "../../shared/SizeMonitor";
import {Player} from "../media/shared/Player";
import {PresetModel} from "../../shared/models/PresetModel";

interface Size {
    width: number;
    height: number;
}

interface PreviewPanelProps {
    imageData: Blob | string | null;
    videoData: Blob;
    preset: PresetModel;
    maxDuration: number | null;
}

export function PreviewPanel(props: PreviewPanelProps) {

    const [panelSize, setPanelSize] = useState<Size|null>(null);
    const [backgroundSize, setBackgroundSize] = useState<Size|null>(null);

    const handleBackgroundLoad = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
        setBackgroundSize({
            width: event.currentTarget.naturalWidth,
            height: event.currentTarget.naturalHeight
        });
    };

    const coverScale = calcCoverScale(panelSize, backgroundSize);

    const wrapperStyle = {
        position: 'relative',
        width: '100%',
        height: '100%',
        overflow: 'hidden'
    } as CSSProperties;

    const backgroundStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)' + (useZoom ? '' : ` scale(${coverScale})`),
        zoom: useZoom ? coverScale : undefined
    } as CSSProperties;

    const contentStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: props.preset.width,
        height: props.preset.height,
        transformOrigin: '0 0',
        transform: 'matrix3d(' + (props.preset.preview_matrix3d || []).join(',') + ')'
    } as React.CSSProperties;

    return (
        <SizeMonitor onResize={setPanelSize}>
            <div style={wrapperStyle}>
                <div style={backgroundStyle}>
                    <img src={props.preset.preview_background || ''} alt='' onLoad={handleBackgroundLoad} />
                    <div style={contentStyle}>
                        <Player poster={props.imageData}
                                source={props.videoData}
                                preset={props.preset}
                                maxDuration={props.maxDuration}
                                warnMaxDuration={false}
                        />
                    </div>
                </div>
            </div>
        </SizeMonitor>
    );
}

/**
 *
 * Calculate the scale factor required to cover a parent a child
 *
 * @param parentSize
 *     The parent dimensions.
 * @param childSize
 *     The child dimensions.
 * @return
 *     The factor the child element should be scaled to exactly cover the parent element
 *     (assuming the child is scaled from the center of the parent).
 */
function calcCoverScale(parentSize: Size|null, childSize: Size|null): number {
    if (parentSize === null || childSize === null) {
        return 1;
    }

    if ((parentSize.width / parentSize.height) < (childSize.width / childSize.height)) {
        return parentSize.height / childSize.height;
    } else {
        return parentSize.width / childSize.width;
    }
}

/**
 *
 * Determine whether to use CSS zoom instead of a CSS scale transform.
 *
 * By default we try to avoid the non-standard zoom. However, on many Android devices (across browsers) applying
 * a scale transform on (an ancestor of) an element with a matrix3d transform clips the rendering of <video> child
 * elements at most scale factors. Only Android Firefox does not support CSS zoom, but also doesn't have the scale bug.
 */
const useZoom = /android/i.test(navigator.userAgent) && 'zoom' in document.createElement('div').style;
