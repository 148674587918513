import React, {ReactNode} from "react";
import {Box, Stack, Typography} from "@mui/material";
import {InstructionImage} from "./InstructionImage";

interface InstructionProps {
    image: { src: string|null, alt: string, force?: boolean };
    header: ReactNode,
    text: ReactNode
    buttons?: ReactNode[]
}

export function Instruction(props: InstructionProps) {
    return (
        <Stack sx={{ height: '100%' }}
               direction="column"
               justifyContent="center"
               alignItems="center"
               spacing={4}>

            <InstructionImage src={props.image.src} alt={props.image.alt} force={props.image.force} />

            <Box sx={{ pl: 2, pr: 2 }}>
                <Typography variant="h6" component="div" align="center">
                    { props.header }
                </Typography>

                <Typography variant="body1" component="div" align="center" style={{ maxWidth: '20em' }}>
                    { props.text }
                </Typography>
            </Box>

            <Stack direction="row" spacing={2}>
                {props.buttons && props.buttons.map((button, index) =>
                    <React.Fragment key={index}>
                        {button}
                    </React.Fragment>
                )}
            </Stack>
        </Stack>
    );
}
