import {createTheme, Theme} from "@mui/material";

export class ThemeLoader {

    /**
     * Load a theme
     *
     * @param url
     *     The URL of the theme configuration file, or NULL if the default theme should be used.
     * @returns
     *     A promise that resolves to a MUI theme object, or rejects if the theme cannot be loaded.
     */
    public load(url: string|null): Promise<Theme> {
        if (!url) {
            return Promise.resolve(createTheme());
        }

        return fetch(url)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`Got error response fetching theme from ${url} (${response.status})`);
                }
                return response.json();
            })
            .then(json => createTheme(json));
    }

}
