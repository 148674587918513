import {useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import {PlayError} from "./PlayError";
import {AlbumModel} from "../shared/models/AlbumModel";
import {AlbumService} from "../shared/services/AlbumService";
import {ZapparFrame} from "./ZapparFrame";


interface AlbumPlayerProps {
    apiBaseUrl?: string,
    accessToken: string,
    albumId: string,
    resourceKey?: string
}

export function AlbumPlayer(props: AlbumPlayerProps) {

    const [album, setAlbum] = useState<Partial<AlbumModel> | null>(null);

    useEffect(() => {
        const fields = ['order_completed', 'status', 'views', 'max_views'];
        AlbumService.get(props.albumId, fields, [], props.resourceKey).then(setAlbum);
    }, [props.albumId, props.resourceKey]);

    if (!album) {
        // TODO: show loading spinner
        return null;
    }

    if (!album.order_completed) {
        return <PlayError
            title={<FormattedMessage
                id="play.album.order-not-completed.title"
                description="Play - Album - Order not completed - Title text."
                defaultMessage="Not recorded"
            />}
            message={<FormattedMessage
                id="play.album.order-not-completed.message"
                description="Play - Album - Order not completed - Message text."
                defaultMessage="The album has not been recorded yet. Try again later."
            />}
        />;
    }

    if (album.status !== 'ready') {
        return <PlayError
            title={<FormattedMessage
                id="play.album.not-ready.title"
                description="Play - Album - Content not ready - Title text."
                defaultMessage="Not ready"
            />}
            message={<FormattedMessage
                id="play.album.not-ready.message"
                description="Play - Album - Content not ready - Message text."
                defaultMessage="The album is not ready for playback yet. Try again later."
            />}
        />;
    }

    if (album.max_views && (album.views! >= album.max_views)) {
        return <PlayError
            title={<FormattedMessage
                id="play.album.view-limit-reached.title"
                description="Play - Album - View limit reached - Title text."
                defaultMessage="View limit reached"
            />}
            message={<FormattedMessage
                id="play.album.view-limit-reached.message"
                description="Play - Album - View limit reached - Message text."
                defaultMessage="This album has been viewed too many times."
            />}
        />;
    }

    return (
        <ZapparFrame
            apiBaseUrl={props.apiBaseUrl}
            accessToken={props.accessToken}
            albumId={props.albumId}
            resourceKey={props.resourceKey}
        />
    );
}
