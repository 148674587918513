import React from "react";
import {defineMessages, FormattedMessage, useIntl} from "react-intl";
import {DialogAppBar} from "../../shared/DialogAppBar";
import {DialogPage} from "../../shared/DialogPage";
import {Instruction} from "../shared/Instruction";
import {DialogFooter} from "../../shared/DialogFooter";


const messages = defineMessages({
    imageUrl: {
        id: "record.confirm.image-url",
        description: "Record page - Confirm - Image URL.",
        defaultMessage: "/img/confirm.svg"
    },
    imageAlt: {
        id: "record.confirm.image-alt",
        description: "Record page - Confirm - Image alternative text.",
        defaultMessage: "A person thinking."
    }
});

interface ConfirmProps {
    onBack: () => void;
    onConfirm: () => void;
}

export function Confirm({
    onBack,
    onConfirm
}: ConfirmProps) {
    const intl = useIntl();

    const appBar =
        <DialogAppBar
            title={
                <FormattedMessage
                    id="record.confirm.title"
                    description="Record page - Confirm - Title text."
                    defaultMessage="Confirm your selection"
                />
            }
            canBack={true}
            onBack={onBack}
        />;

    const footer =
        <DialogFooter
            onNext={onConfirm}
        />;

    return (
        <DialogPage
            appBar={appBar}
            footer={footer}
        >
            <Instruction
                image={{
                    src: intl.formatMessage(messages.imageUrl),
                    alt: intl.formatMessage(messages.imageAlt)
                }}
                header={
                    <FormattedMessage
                        id="record.confirm.header"
                        description="Record page - Confirm - Header."
                        defaultMessage="Are you sure?"
                    />
                }
                text={
                    <FormattedMessage
                        id="record.confirm.text"
                        description="Record page - Select video - File tab - Instruction text."
                        defaultMessage="Only continue if you are happy with your selected media. You will not be able to change it later."
                    />
                }
            />
        </DialogPage>
    );
}
