import {defineMessages, useIntl} from "react-intl";
import {useTheme} from "@mui/material";

const messages = defineMessages({
    title: {
        id: "metadata.title",
        description: "Document - Title text."
    },
    faviconUrl: {
        id: "metadata.favicon-url",
        description: "Document - Favicon URL."
    }
});

export function Metadata() {
    const intl = useIntl();
    const theme = useTheme();

    const setLang = (locale: string) => {
        const html = document.querySelector<HTMLHtmlElement>('html');

        if (html) {
            html.lang = locale;
        }
    };

    const setMeta = (name: string, content: string) => {
        let meta = document.querySelector<HTMLMetaElement>(`meta[name='${name}']`);

        if (!meta) {
            meta = document.createElement('meta');
            meta.name = name;
            document.getElementsByTagName('head')[0].appendChild(meta);
        }

        meta.content = content;
    };

    const setLink = (rel: string, href: string) => {
        let link = document.querySelector<HTMLLinkElement>(`link[rel='${rel}']`);

        if (!link) {
            link = document.createElement('link');
            link.rel = rel;
            document.getElementsByTagName('head')[0].appendChild(link);
        }

        link.href = href;
    };

    // Set document language
    setLang(intl.locale);

    // Set document title
    document.title = intl.formatMessage(messages.title);

    // Set document favicon
    setLink('icon', intl.formatMessage(messages.faviconUrl));

    // Set document theme color
    setMeta('theme-color', theme.palette.background.default);

    return null;
}
