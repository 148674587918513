import {DialogAppBar} from "../shared/DialogAppBar";
import {defineMessages, FormattedMessage, useIntl} from "react-intl";
import React from "react";
import {DialogPage} from "../shared/DialogPage";
import {Instruction} from "../record/shared/Instruction";
import {Button} from "@mui/material";


const messages = defineMessages({
    imageUrl: {
        id: "tracking.image-url",
        description: "Tracking page - Image URL.",
        defaultMessage: "/img/cookie.svg"
    },
    imageAlt: {
        id: "tracking.image-alt",
        description: "Tracking page - Image alternative text.",
        defaultMessage: "A cookie."
    },
    privacyPolicyUrl: {
        id: "tracking.privacy-policy-url",
        description: "Privacy policy - URL.",
        defaultMessage: "https://happyar.world/privacy-statement/"
    }
});

interface TrackingPageProps {
    canClose?: boolean;
    onClose?: () => void;
    onAccept: () => void;
    onReject: () => void;
}

export function TrackingPage(props: TrackingPageProps) {
    const intl = useIntl();

    const appBar = <DialogAppBar
        title={<FormattedMessage
            id="tracking.title"
            description="Tracking page - Title text."
            defaultMessage="Consider cookies"
        />}
        canClose={props.canClose}
        onClose={props.onClose}
    />;

    return (
        <DialogPage appBar={appBar}>
            <Instruction
                image={{
                    src: intl.formatMessage(messages.imageUrl),
                    alt: intl.formatMessage(messages.imageAlt)
                }}
                header={
                    <FormattedMessage
                        id="tracking.header"
                        description="Tracking page - Header."
                        defaultMessage="May we use cookies?"
                    />
                }
                text={
                    <FormattedMessage
                        id="tracking.text"
                        description="Tracking page - Explanation text."
                        defaultMessage="To improve your experience, we would like to track how you use our service. See our <a>privacy policy</a> for details."
                        values={{
                            a: (text) => (
                                <a target="_blank" rel="noreferrer" href={intl.formatMessage(messages.privacyPolicyUrl)}>{text}</a>
                            )
                        }}
                    />
                }
                buttons={[
                    <Button variant="outlined" size="large" onClick={props.onReject}>
                        <FormattedMessage
                            id="tracking.reject"
                            description="Tracking page - Reject."
                            defaultMessage="Reject"
                        />
                    </Button>,
                    <Button variant="contained" size="large" onClick={props.onAccept}>
                        <FormattedMessage
                            id="tracking.accept"
                            description="Tracking page - Accept."
                            defaultMessage="Accept"
                        />
                    </Button>
                ]}
            />
        </DialogPage>
    );
}
