import React, {useState} from "react";
import {FormattedMessage} from "react-intl";

import {Recorder} from "./Recorder";
import {RecorderError} from "./RecorderError";
import {ErrorMessage} from "../../shared/ErrorMessage";
import {GA4} from "../../../shared/GA4";
import {Outlet, Route, Routes, useNavigate} from "react-router-dom";
import {PresetModel} from "../../../shared/models/PresetModel";
import {useRecordDispatch, useRecordState} from "../../../shared/reducers/RecordStateProvider";
import { DialogPage } from "../../../shared/DialogPage";
import {DialogAppBar} from "../../../shared/DialogAppBar";
import {ErrorPage} from "../../../error/ErrorPage";
import {Preview} from "../shared/Preview";
import {PinturaImageState} from "@pqina/pintura";


interface CameraProps {
    recorderSettings: {
        mimeTypes: string[];
        sourceConstraints: MediaStreamConstraints;
        audioBitsPerSecond: number;
        videoBitsPerSecond: number;
    }
    maxDuration: number;
    preset: PresetModel;
    onAccept: (data: Blob) => void;
}

export function Camera({
    recorderSettings,
    maxDuration,
    preset,
    onAccept
}: CameraProps) {

    const state = useRecordState();
    const dispatch = useRecordDispatch();

    const [error, setError] = useState<Error|null>(null);

    const navigate = useNavigate();

    const videoData = state.media.camera.data;
    const originalVideoData = state.media.camera.originalData;
    const editorState = state.media.camera.editorState;

    const handleRecorded = (data: Blob) => {
        dispatch( { type: 'setMediaCameraOriginalData', payload: data });
        navigate('preview');
    };

    const handleError = (error: Error) => {
        setError(error);
        GA4.sendEvent('record_media_camera_error', { error_name: error.name || 'UnknownError' });
    };

    const handleDismissError = () => {
        setError(null);
    };

    const handlePlay = () => {
        GA4.sendEvent('record_media_camera_play');
    };

    const handleEdited = (data: Blob, editorState: PinturaImageState) => {
        dispatch({ type: 'setMediaCameraEditedData', payload: { data, editorState } });
    };

    const handleNext = () => {
        if (videoData) {
            onAccept(videoData);
        }
    };

    const handleBack = () => {
        navigate(-1);
    };

    const preview = videoData && originalVideoData &&
        <Preview
            videoData={videoData}
            originalVideoData={originalVideoData}
            preset={preset}
            editorState={editorState}
            onPlay={handlePlay}
            onEdited={handleEdited}
            onNext={handleNext}
            onBack={handleBack}
        />;

    const appBar =
        <DialogAppBar
            title={<FormattedMessage
                id="record.media.camera.title"
                description="Record page - Media - Camera - Title text."
                defaultMessage="Record a video"
            />}
            canBack={true}
            onBack={handleBack}
        />;

    const page =
        <DialogPage appBar={appBar}>
            <ErrorMessage isOpen={!!error}
                          pageHasFab={false}
                          onClose={handleDismissError}>
                {error && <RecorderError error={error} />}
            </ErrorMessage>
            <Recorder
                mimeTypes={recorderSettings.mimeTypes}
                sourceConstraints={recorderSettings.sourceConstraints}
                videoBitsPerSecond={recorderSettings.videoBitsPerSecond}
                audioBitsPerSecond={recorderSettings.audioBitsPerSecond}
                preset={preset}
                maxDuration={maxDuration}
                onRecorded={handleRecorded}
                onError={handleError}
            />
        </DialogPage>;

    return (
        <Routes>
            <Route path="/" element={ <Outlet /> }>
                <Route index element={ page } />
                <Route path="preview/*" element={ preview } />
                <Route path="*" element={ <ErrorPage /> } />
            </Route>
        </Routes>
    );
}
