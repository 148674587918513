import React from "react";
import {defineMessages, FormattedMessage, useIntl} from "react-intl";
import {Stack, Typography} from "@mui/material";
import {DialogPage} from "../shared/DialogPage";
import {DialogAppBar} from "../shared/DialogAppBar";

import './ErrorPage.scss';

const messages = defineMessages({
    imageUrl: {
        id: "error.image-url",
        description: "Error page - Image URL.",
        defaultMessage: "/img/error.svg"
    },
    imageAlt: {
        id: "error.image-alt",
        description: "Error page - Image alternative text.",
        defaultMessage: "We're sorry"
    }
});

interface ErrorPageProps {
    error?: Error,
    canClose?: boolean;
    onClose?: () => void;
}

export function ErrorPage(props: ErrorPageProps) {
    const intl = useIntl();

    const appBar =
        <DialogAppBar
            title={<FormattedMessage
                id="error.title"
                description="Error page - Title text."
                defaultMessage="Error"
            />}
            canClose={props.canClose}
            onClose={props.onClose}
        />;

    return (
        <DialogPage appBar={appBar}>
            <Stack sx={{ height: '100%'}}
                   direction="column"
                   justifyContent="center"
                   alignItems="center"
                   spacing={4}>

                <img className="error-page-image"
                     src={intl.formatMessage(messages.imageUrl)}
                     alt={intl.formatMessage(messages.imageAlt)} />

                <Typography variant="body1" align="center">
                    <FormattedMessage
                        id="error.message"
                        description="Error page - Unknown error message text."
                        defaultMessage="An unknown error occurred."
                    />
                </Typography>

                {props.error &&
                    <code className="error-page-details">
                        {props.error.stack}
                    </code>
                }

            </Stack>
        </DialogPage>
    );

}
