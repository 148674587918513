import React from "react";
import {FormattedMessage} from "react-intl";


interface FileSelectorErrorProps {
    error: DOMException;
    maxSize: number;
    minResolution: {
        width: number,
        height: number
    };
}

export function FileSelectorError(props: FileSelectorErrorProps) {

    switch (props.error.name) {
        case "SizeError":
            return <FormattedMessage
                id="record.shared.file-selector.size-error"
                description="Record page - File selector - File is too large text."
                defaultMessage="File is larger than {maxSize, number} MB."
                values={{maxSize: props.maxSize / 1024 / 1024}}
            />;

        case "FormatError":
            return <FormattedMessage
                id="record.shared.file-selector.format-error"
                description="Record page - File selector - Unsupported file format text."
                defaultMessage="Unsupported file format."
            />;

        case "ResolutionError":
            return <FormattedMessage
                id="record.shared.file-selector.resolution-error"
                description="Record page - File selector - Resolution too low text."
                defaultMessage="Resolution is lower than {width}x{height} px."
                values={props.minResolution}
            />;

        default:
            return <FormattedMessage
                id="record.shared.file-selector.unknown-error"
                description="Record page - File selector - Load error text."
                defaultMessage="Could not load file."
            />;
    }

}
