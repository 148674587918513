import React, {Component} from "react";
import {Avatar, Box, Divider, Paper, Slide, Stack, Typography} from "@mui/material";
import PropTypes from "prop-types";

export class Banner extends Component {

    render() {
        return (
            <Slide in={this.props.isOpen} mountOnEnter unmountOnExit>
                <Box>
                    <Paper elevation={0} sx={{ p: 2, pb: 1 }}>
                        <Stack spacing={2} direction="row" alignItems="center">
                            {this.props.icon &&
                                <Avatar sx={{ bgcolor: "primary.main" }}>
                                    {this.props.icon}
                                </Avatar>
                            }
                            <Typography variant="body2">
                                {this.props.text}
                            </Typography>
                        </Stack>
                        <Box sx={{ mt: 1, display: "flex", justifyContent: "flex-end" }}>
                            {this.props.buttons.map((button, index) =>
                                <React.Fragment key={index}>
                                    {button}
                                </React.Fragment>
                            )}
                        </Box>
                    </Paper>
                    <Divider />
                </Box>
            </Slide>
        );
    }

}

Banner.defaultProps = {
    isOpen: false
};

Banner.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    buttons: PropTypes.arrayOf(PropTypes.element).isRequired,
    icon: PropTypes.element
};
