import {Instruction} from "../shared/Instruction";
import {defineMessages, FormattedMessage, useIntl} from "react-intl";
import React from "react";

const messages = defineMessages({
    imageUrl: {
        id: "record.image.no-source.image-url",
        description: "Record page - Select image - No source - Image URL.",
        defaultMessage: "/img/no-source.svg"
    },
    imageAlt: {
        id: "record.image.no-source.image-alt",
        description: "Record page - Select image - No source - Image alternative text.",
        defaultMessage: "A computer error."
    }
});

export function NoSource() {
    const intl = useIntl();

    return (
        <Instruction
            image={{
                src: intl.formatMessage(messages.imageUrl),
                alt: intl.formatMessage(messages.imageAlt)
            }}
            header={
                <FormattedMessage
                    id="record.image.no-source.header"
                    description="Record page - Select image - No source - Header."
                    defaultMessage="No source"
                />
            }
            text={
                <FormattedMessage
                    id="record.image.no-source.text"
                    description="Record page - Select image - No source - Text."
                    defaultMessage="No image source could be found."
                />
            }
        />
    );
}
