import {ReactNode, useEffect} from "react";
import {useLocalStorage} from "./shared/hooks/useLocalStorage";
import {TrackingPage} from "./tracking/TrackingPage";
import {TrackingContext} from "./TrackingContext";

enum Tracking {
    Yes = 'yes',
    No = 'no'
}

interface TrackingBoundaryProps {
    isTracking: boolean|null,
    children: ReactNode;
    disabled?: boolean;
    canClose?: boolean;
    onClose?: () => void;
    onChange: (enabled: boolean) => void;
}

/**
 *
 * A component that manages user tracking preferences
 *
 * - Renders the permission UI if the user's tracking preference is unknown
 *   (i.e. props.isTracking is null and no tracking preference is set in local storage).
 * - Allows overriding the user's tracking preference
 *   (by setting props.isTracking to a non-null value).
 * - Persists the user's (or the overridden) tracking preference.
 * - Notifies the parent of tracking preference changes.
 * - Provides a context to obtain the tracking state in descendant components.
 */
export function TrackingBoundary(props: TrackingBoundaryProps) {
    const { isTracking, children, disabled=false, canClose, onClose, onChange } = props;

    const [storedTracking, setStoredTracking] = useLocalStorage('tracking');

    useEffect(() => {
        if (!disabled && (isTracking !== null)) {
            setStoredTracking(isTracking ? Tracking.Yes : Tracking.No);
        }
    }, [disabled, isTracking, setStoredTracking])

    useEffect(() => {
        if (!disabled && (storedTracking !== null)) {
            onChange(storedTracking === Tracking.Yes);
        }
    }, [disabled, storedTracking, onChange]);

    const handleAccept = () => {
        setStoredTracking(Tracking.Yes);
    }

    const handleReject = () => {
        setStoredTracking(Tracking.No);
    }

    if (!disabled && (storedTracking === null)) {
        return <TrackingPage
            canClose={canClose}
            onClose={onClose}
            onAccept={handleAccept}
            onReject={handleReject}
        />;
    }

    return (
        <TrackingContext.Provider value={storedTracking === Tracking.Yes}>
            {children}
        </TrackingContext.Provider>
    );
}
