interface VideoErrorProps {
    error: MediaError;
}

export function VideoError(props: VideoErrorProps) {
    switch (props.error.code) {
        case 1:
            /*The fetching of the associated resource was aborted by the user's request. */
            return (
                <div>Fetching video was aborted</div>
            );
        case 2:
            /*
             * Some kind of network error occurred which prevented the media from being
             * successfully fetched, despite having previously been available.
             */
            return(
                <div>Video is no longer available</div>
            );
        case 3:
        case 4:
            /*
             * Despite having previously been determined to be usable, an error occurred
             * while trying to decode the media resource, resulting in an error.
             * OR
             * The associated resource or media provider object has been found to be unsuitable.
             */
            return(
                <div>Error while decoding the video</div>
            );
        default:
            return (
                <div>A video error occurred: {props.error.message} ({props.error.code})</div>
            );
    }
}
