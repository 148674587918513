import React, {Component, RefObject} from "react";

import {FileInput} from "./FileInput";
import {Alert, Snackbar} from "@mui/material";
import {FileSelectorError} from "./FileSelectorError";


enum Status {
    Ok = 'ok',
    SizeError = 'SizeError',
    FormatError = 'FormatError',
    ResolutionError = 'ResolutionError',
    UnknownError = 'UnknownError'
}

interface Metadata {
    width: number;
    height: number;
}

interface FileSelectorProps {
    type: 'video' | 'image';
    maxSize: number;
    minResolution: {
        width: number,
        height: number
    },
    onOpen: () => void;
    onSelected: (file: File) => void;
    onError: (error: DOMException) => void;
}

interface FileSelectorState {
    error: DOMException | null;
}

export class FileSelector extends Component<FileSelectorProps, FileSelectorState> {

    private readonly selectorRef: RefObject<FileInput>;

    constructor(props: FileSelectorProps) {
        super(props);

        this.handleFileSelected = this.handleFileSelected.bind(this);
        this.handleDismissError = this.handleDismissError.bind(this);

        this.state = {
            error: null
        };

        this.selectorRef = React.createRef();
    }

    public render() {
        return (
            <>
                <FileInput
                    ref={this.selectorRef}
                    type={this.props.type}
                    onOpen={this.props.onOpen}
                    onSelected={this.handleFileSelected}
                />
                { this.state.error &&
                    <Snackbar
                        open={true}
                        onClose={this.handleDismissError}
                        autoHideDuration={6000}
                    >
                        <Alert
                            severity="error"
                            sx={{ width: '100%' }}
                        >
                            <FileSelectorError
                                error={this.state.error}
                                maxSize={this.props.maxSize}
                                minResolution={this.props.minResolution}
                            />
                        </Alert>
                    </Snackbar>
                }
            </>
        );
    }

    public open() {
        this.selectorRef.current?.open();
    }

    private handleDismissError() {
        this.setState({ error: null });
    }

    private async handleFileSelected(file: File) {
        const status = await this.getStatus(file);

        if (status === Status.Ok) {
            this.props.onSelected(file);
        } else {
            const error = new DOMException('File error', status);
            this.setState({ error });
            this.props.onError?.(error);
        }
    }

    private async getStatus(file: File): Promise<Status> {
        if (file.size > this.props.maxSize) {
            return Status.SizeError;
        }

        try {
            const metadata = await this.getMetadata(file);

            if ((metadata.width < this.props.minResolution.width) ||
                (metadata.height < this.props.minResolution.height))
            {
                return Status.ResolutionError;
            }

            return Status.Ok;
        }
        catch (error) {
            if (error instanceof MediaError &&
                error.code === MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED)
            {
                return Status.FormatError;
            }

            return Status.UnknownError;
        }
    }

    private async getMetadata(file: File): Promise<Metadata> {
        switch (this.props.type) {
            case 'image':
                return this.getImageMetadata(file);
            case 'video':
                return this.getVideoMetadata(file);
            default:
                return Promise.reject(new Error("Unknown type"));
        }
    }

    private async getVideoMetadata(file: File): Promise<Metadata> {
        const url = URL.createObjectURL(file);
        const video = document.createElement('video');

        return new Promise<Metadata>((resolve, reject) => {

            video.onerror = () => {
                reject(video.error);
            };

            video.onloadedmetadata = () => {
                resolve({
                    width: video.videoWidth,
                    height: video.videoHeight
                });

                // Prevent net::ERR_FILE_NOT_FOUND error for .ogv in Chrome/Edge/Opera
                video.removeAttribute('src');
                video.load();
            };

            video.preload = 'metadata';
            video.src = url;

        }).finally(() => {
            URL.revokeObjectURL(url);
        });
    }

    private async getImageMetadata(file: File): Promise<Metadata> {
        const url = URL.createObjectURL(file);
        const img = document.createElement('img');

        return new Promise<Metadata>((resolve, reject) => {

            img.onerror = () => {
                reject(new Error("Image load error"));
            };

            img.onload = () => {
                resolve({
                    width: img.naturalWidth,
                    height: img.naturalHeight
                });
            };

            img.src = url;

        }).finally(() => {
            URL.revokeObjectURL(url);
        });
    }

}
