import {PinturaImageState} from "@pqina/pintura";


export type RecordAction =
    | { type: 'setMediaData', payload: Blob|null }
    | { type: 'setMediaFileOriginalData', payload: Blob }
    | { type: 'setMediaFileEditedData', payload: { data: Blob, editorState: PinturaImageState } }
    | { type: 'setMediaCameraOriginalData', payload: Blob }
    | { type: 'setMediaCameraEditedData', payload: { data: Blob, editorState: PinturaImageState } }
    | { type: 'setImageData', payload: Blob|null }
    | { type: 'setImageFileOriginalData', payload: Blob }
    | { type: 'setImageFileEditedData', payload: { data: Blob, editorState: PinturaImageState } }
    | { type: 'setImagePhotoOriginalData', payload: Blob }
    | { type: 'setImagePhotoEditedData', payload: { data: Blob, editorState: PinturaImageState } }
    | { type: 'setImageStillOriginalData', payload: { data: Blob, position: number } }
    | { type: 'setImageStillEditedData', payload: { data: Blob, editorState: PinturaImageState } };

export interface RecordState {
    media: {
        data: Blob|null;
        file: {
            data: Blob|null;
            originalData: Blob|null;
            editorState: PinturaImageState|null;
        },
        camera: {
            data: Blob|null;
            originalData: Blob|null;
            editorState: PinturaImageState|null;
        }
    },
    image: {
        data: Blob|null;
        file: {
            data: Blob|null;
            originalData: Blob|null;
            editorState: PinturaImageState|null;
        },
        photo: {
            data: Blob|null;
            originalData: Blob|null;
            editorState: PinturaImageState|null;
        },
        still: {
            position: number;
            data: Blob|null;
            originalData: Blob|null;
            editorState: PinturaImageState|null;
        }
    }
}

export const initialRecordState: RecordState = {
    media: {
        data: null,
        file: {
            data: null,
            originalData: null,
            editorState: null
        },
        camera: {
            data: null,
            originalData: null,
            editorState: null
        }
    },
    image: {
        data: null,
        file: {
            data: null,
            originalData: null,
            editorState: null
        },
        photo: {
            data: null,
            originalData: null,
            editorState: null
        },
        still: {
            position: 0,
            data: null,
            originalData: null,
            editorState: null
        }
    }
}

export function recordReducer(state: RecordState, action: RecordAction): RecordState {
    switch (action.type) {

        case 'setMediaData': {
            return {
                ...state,
                media: {
                    ...state.media,
                    data: action.payload
                }
            }
        }

        case 'setMediaFileOriginalData': {
            return {
                ...state,
                media: {
                    ...state.media,
                    file: {
                        ...state.media.file,
                        data: action.payload,
                        originalData: action.payload,
                        editorState: null
                    }
                }
            }
        }

        case 'setMediaFileEditedData': {
            return {
                ...state,
                media: {
                    ...state.media,
                    file: {
                        ...state.media.file,
                        data: action.payload.data,
                        editorState: action.payload.editorState
                    }
                }
            }
        }

        case 'setMediaCameraOriginalData': {
            return {
                ...state,
                media: {
                    ...state.media,
                    camera: {
                        ...state.media.camera,
                        data: action.payload,
                        originalData: action.payload,
                        editorState: null
                    }
                }
            }
        }

        case 'setMediaCameraEditedData': {
            return {
                ...state,
                media: {
                    ...state.media,
                    camera: {
                        ...state.media.camera,
                        data: action.payload.data,
                        editorState: action.payload.editorState
                    }
                }
            }
        }

        case 'setImageData': {
            return {
                ...state,
                image: {
                    ...state.image,
                    data: action.payload
                }
            }
        }

        case 'setImageFileOriginalData': {
            return {
                ...state,
                image: {
                    ...state.image,
                    file: {
                        ...state.image.file,
                        data: action.payload,
                        originalData: action.payload,
                        editorState: null
                    }
                }
            }
        }

        case 'setImageFileEditedData': {
            return {
                ...state,
                image: {
                    ...state.image,
                    file: {
                        ...state.image.file,
                        data: action.payload.data,
                        editorState: action.payload.editorState
                    }
                }
            }
        }

        case 'setImagePhotoOriginalData': {
            return {
                ...state,
                image: {
                    ...state.image,
                    photo: {
                        ...state.image.photo,
                        data: action.payload,
                        originalData: action.payload,
                        editorState: null
                    }
                }
            }
        }

        case 'setImagePhotoEditedData': {
            return {
                ...state,
                image: {
                    ...state.image,
                    photo: {
                        ...state.image.photo,
                        data: action.payload.data,
                        editorState: action.payload.editorState
                    }
                }
            }
        }

        case 'setImageStillOriginalData': {
            return {
                ...state,
                image: {
                    ...state.image,
                    still: {
                        ...state.image.still,
                        position: action.payload.position,
                        data: action.payload.data,
                        originalData: action.payload.data,
                        editorState: null
                    }
                }
            }
        }

        case 'setImageStillEditedData': {
            return {
                ...state,
                image: {
                    ...state.image,
                    still: {
                        ...state.image.still,
                        data: action.payload.data,
                        editorState: action.payload.editorState
                    }
                }
            }
        }

        default: {
            throw new Error('Unknown reducer action');
        }
    }
}
