import React, {useCallback, useEffect, useRef, useState} from "react";
import {defineMessages, FormattedMessage, useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";
import {Box, Button, Stack, Typography} from "@mui/material";

import {UploadManager} from "../shared/UploadManager";
import {UploadProgress} from "./UploadProgress";
import {CancellationError, CancellationToken} from "../../shared/CancellationToken";
import {DialogAppBar} from "../../shared/DialogAppBar";
import {DialogPage} from "../../shared/DialogPage";
import {Banner} from "../../shared/Banner";
import {UpdateProgress} from "./UpdateProgress";
import {VideoService} from "../../shared/services/VideoService";
import {GA4} from "../../shared/GA4";
import {VideoModel} from "../../shared/models/VideoModel";
import {InstructionImage} from "../shared/InstructionImage";

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';


const messages = defineMessages({
    updateImageUrl: {
        id: "record.update.update-image-url",
        description: "Record page - Update order - Updating order image URL.",
        defaultMessage: "/img/updating.svg"
    },
    updateImageAlt: {
        id: "record.update.update-image-alt",
        description: "Record page - Update order - Updating order image alternative text.",
        defaultMessage: "A person waiting."
    },
    doneImageUrl: {
        id: "record.update.done-image-url",
        description: "Record page - Update order - Done image URL.",
        defaultMessage: "/img/done.svg"
    },
    doneImageAlt: {
        id: "record.update.done-image-alt",
        description: "Record page - Update order - Done image alternative text.",
        defaultMessage: "A person confirming completion."
    }
});

interface UpdateProps {
    videoId: string;
    updateImage: boolean;
    selfComplete: boolean;
    uploadManager: UploadManager;
    mediaUploadKey: string;
    imageUploadKey?: string;
    onBack: () => void;
    onUpdated: () => void;
}

export function Update({
    videoId,
    updateImage,
    selfComplete,
    uploadManager,
    mediaUploadKey,
    imageUploadKey,
    onBack,
    onUpdated
}: UpdateProps) {

    const [mediaUploadId, setMediaUploadId] = useState<string | null>(null);
    const [imageUploadId, setImageUploadId] = useState<string | null>(null);
    const [hasUploadError, setHasUploadError] = useState(false);
    const [mediaUpdated, setMediaUpdated] = useState(false);
    const [imageUpdated, setImageUpdated] = useState(false);
    const [hasUpdateError, setHasUpdateError] = useState(false);
    const [isValidTarget, setIsValidTarget] = useState<boolean | null>(null);
    const [isOrderCompleted, setIsOrderCompleted] = useState<boolean | null>(null);

    const cancellationToken = useRef<CancellationToken>();
    const navigate = useNavigate();
    const intl = useIntl();

    const patchMedia = useCallback((uploadId: string) => {
        patchVideo(videoId, { order_media: uploadId })
            .then(() => setMediaUpdated(true))
            .catch(() => setHasUpdateError(true));
    }, [videoId]);

    const patchImage = useCallback((uploadId: string) => {
        patchVideo(videoId, { order_image: uploadId })
            .then(() => setImageUpdated(true))
            .catch(() => setHasUpdateError(true));
    }, [videoId]);

    const validateTarget = useCallback(() => {
        checkIsValidTarget(cancellationToken.current!, videoId)
            .then(setIsValidTarget)
            .catch(() => setHasUpdateError(true));
    }, [videoId]);

    const completeOrder = useCallback(() => {
        patchVideo( videoId, { order_completed: true })
            .then(() => setIsOrderCompleted(true))
            .catch(() => setHasUpdateError(true));
    }, [videoId]);

    useEffect(() => {
        cancellationToken.current = new CancellationToken();
        return () => {
            cancellationToken.current?.cancel();
        };
    }, []);

    useEffect(() => {
        const handleUploadCompleted = (event: CustomEvent) => {
            if (event.detail.key === mediaUploadKey) {
                setMediaUploadId(event.detail.uploadId);
            }

            if (event.detail.key === imageUploadKey) {
                setImageUploadId(event.detail.uploadId);
            }
        };

        const handleUploadError = () => {
            setHasUploadError(true);
        };

        // Check for upload errors that occurred before mounting this component.
        if (uploadManager.hasError()) {
            handleUploadError();
        }

        // Process uploads that completed before mounting this component
        mediaUploadKey && setMediaUploadId(uploadManager.get(mediaUploadKey)?.uploadId || null);
        imageUploadKey && setImageUploadId(uploadManager.get(imageUploadKey)?.uploadId || null);

        uploadManager.addEventListener('completed', handleUploadCompleted);
        uploadManager.addEventListener('error', handleUploadError);

        return () => {
            uploadManager.removeEventListener('completed', handleUploadCompleted);
            uploadManager.removeEventListener('error', handleUploadError);
        };

    }, [uploadManager, mediaUploadKey, imageUploadKey]);

    useEffect(() => {
        if (mediaUploadId) {
            patchMedia(mediaUploadId);
        }
    }, [mediaUploadId, patchMedia]);

    useEffect(() => {
        if (imageUploadId) {
            patchImage(imageUploadId);
        }
    }, [imageUploadId, patchImage]);

    useEffect(() => {
        if (imageUpdated) {
            validateTarget();
        }
    }, [imageUpdated, validateTarget]);

    useEffect(() => {
        if (mediaUpdated && (!updateImage || isValidTarget) && selfComplete) {
            completeOrder();
        }
    }, [mediaUpdated, isValidTarget, updateImage, selfComplete, completeOrder]);

    useEffect(() => {
        if (mediaUpdated && (!updateImage || isValidTarget) && (!selfComplete || isOrderCompleted)) {
            GA4.sendEvent('record_update_done');
            onUpdated();
        }
        // Don't include props.onUpdated in the dependency array because it changes on every parent re-render.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mediaUpdated, isValidTarget, isOrderCompleted, updateImage, selfComplete]);

    useEffect(() => {
        if (hasUploadError) {
            GA4.sendEvent('record_update_upload_error');
        }
    }, [hasUploadError]);

    useEffect(() => {
        if (hasUpdateError) {
            GA4.sendEvent('record_update_update_error');
        }
    }, [hasUpdateError]);

    useEffect(() => {
        if (isValidTarget === false) {
            GA4.sendEvent('record_update_target_error');
        }
    }, [isValidTarget]);

    const handleRetryUpload = () => {
        GA4.sendEvent('record_update_upload_retry');
        setHasUploadError(false);
        uploadManager.retryAll();
    };

    const handleRetryUpdate = () => {
        GA4.sendEvent('record_update_update_retry');
        setHasUpdateError(false);

        if (mediaUploadId && !mediaUpdated) {
            patchMedia(mediaUploadId);
        }

        if (imageUploadId && !imageUpdated) {
            patchImage(imageUploadId);
        }

        if (imageUpdated && (isValidTarget === null)) {
            validateTarget();
        }

        if (mediaUpdated && (!updateImage || isValidTarget) && selfComplete && !isOrderCompleted) {
            completeOrder();
        }
    };

    const handleChangeImage = () => {
        GA4.sendEvent('record_update_target_retry');
        navigate('../image');
    };

    const showUploading = !mediaUploadId || (updateImage && !imageUploadId);
    const showUpdating  = !showUploading && (!mediaUpdated || (updateImage && !isValidTarget) || (!!selfComplete && !isOrderCompleted));
    const showDone      = !showUploading && !showUpdating;

    const title = showDone ?
        <FormattedMessage
            id="record.update.title-done"
            description="Record page - Update order - Title done text."
            defaultMessage="Done"
        /> :
        <FormattedMessage
            id="record.update.title-wait"
            description="Record page - Update order - Title wait text."
            defaultMessage="Please wait"
        />;

    const appBar =
        <DialogAppBar
            title={title}
            canBack={true}
            onBack={onBack}
        />;

    const uploadErrorBanner =
        <Banner icon={<CloudUploadIcon />}
                text={<FormattedMessage
                    id="record.update.upload-error"
                    description="Record page - Update order - Data upload failed text."
                    defaultMessage="Data upload failed."
                />}
                buttons={[
                    <Button color="primary" onClick={handleRetryUpload}>
                        <FormattedMessage
                            id="shared.retry"
                            description="Retry button text."
                            defaultMessage="Retry"
                        />
                    </Button>
                ]}
                isOpen={hasUploadError}
        />;

    const updateErrorBanner =
        <Banner icon={<CloudSyncIcon />}
                text={<FormattedMessage
                    id="record.update.update-error"
                    description="Record page - Update order - Order update failed text."
                    defaultMessage="Order update failed."
                />}
                buttons={[
                    <Button color="primary" onClick={handleRetryUpdate}>
                        <FormattedMessage
                            id="shared.retry"
                            description="Retry button text."
                            defaultMessage="Retry"
                        />
                    </Button>
                ]}
                isOpen={hasUpdateError}
        />;

    const invalidTargetBanner =
        <Banner icon={<ImageNotSupportedIcon />}
                text={<FormattedMessage
                    id="record.update.invalid-target"
                    description="Record page - Update order - Unsuitable image text."
                    defaultMessage="The selected image is not usable."
                />}
                buttons={[
                    <Button color="primary" onClick={handleChangeImage}>
                        <FormattedMessage
                            id="record.update.invalid-target-retry"
                            description="Record page - Update order - Change unsuitable image button text."
                            defaultMessage="Change image" />
                    </Button>
                ]}
                isOpen={isValidTarget === false}
        />;

    return (
        <DialogPage appBar={appBar}>
            {uploadErrorBanner}
            {updateErrorBanner}
            {invalidTargetBanner}

            <Stack sx={{ height: '100%' }}
                   direction="column"
                   justifyContent="center"
                   alignItems="center"
                   spacing={4}>

                {(showUploading || showUpdating) &&
                    <InstructionImage
                        src={intl.formatMessage(messages.updateImageUrl)}
                        alt={intl.formatMessage(messages.updateImageAlt)}
                    />
                }

                {showDone &&
                    <InstructionImage
                        src={intl.formatMessage(messages.doneImageUrl)}
                        alt={intl.formatMessage(messages.doneImageAlt)}
                    />
                }

                <Box sx={{ pl: 2, pr: 2 }}>
                    <Typography variant="h6" align="center">
                        {showUploading &&
                            <FormattedMessage
                                id="record.update.upload-header"
                                description="Record page - Update order - Uploading media header text."
                                defaultMessage="Uploading media"
                            />
                        }

                        {showUpdating &&
                            <FormattedMessage
                                id="record.update.update-header"
                                description="Record page - Update order - Updating order header text."
                                defaultMessage="Updating order"
                            />
                        }

                        {showDone &&
                            <FormattedMessage
                                id="record.update.done-header"
                                description="Record page - Update order - Done header text."
                                defaultMessage="You're all set"
                            />
                        }
                    </Typography>

                    <Typography variant="body1" align="center" style={{ maxWidth: '20em' }}>
                        {showUploading &&
                            <FormattedMessage
                                id="record.update.upload-message"
                                description="Record page - Update order - Uploading media message text."
                                defaultMessage="Your media is being uploaded. Waiting time depends on your internet speed."
                            />
                        }

                        {showUpdating &&
                            <FormattedMessage
                                id="record.update.update-message"
                                description="Record page - Update order - Updating order message text."
                                defaultMessage="Your order is being updated. This shouldn't take more than a minute."
                            />
                        }

                        {showDone && !isOrderCompleted &&
                            <FormattedMessage
                                id="record.update.done-not-completed-message"
                                description="Record page - Update order - Done but not completed message text."
                                defaultMessage="Your order has been updated. Return to the store to complete your order."
                            />
                        }

                        {showDone && isOrderCompleted &&
                            <FormattedMessage
                                id="record.update.done-completed-message"
                                description="Record page - Update order - Done and completed message text."
                                defaultMessage="Your order has been completed. The video will be playable in a couple of minutes."
                            />
                        }
                    </Typography>
                </Box>

                {showUploading &&
                    <UploadProgress uploadManager={uploadManager} />
                }

                {showUpdating &&
                    <UpdateProgress hasError={hasUpdateError || (isValidTarget === false)} />
                }

            </Stack>
        </DialogPage>
    );

}

function patchVideo(videoId: string, data: Partial<VideoModel>): Promise<Partial<VideoModel | void>> {
    return VideoService
        .patch(videoId, data)
        .catch(error => {
            if (!(error instanceof CancellationError)) {
                throw error;
            }
        });
}

function checkIsValidTarget(cancellationToken: CancellationToken, videoId: string, retries = 24, delay = 5000): Promise<boolean> {
    cancellationToken.throwIfCancellationRequested();

    return VideoService
        .get(videoId, ['target_status'])
        .then(video => {
            if (video.target_status !== 'processing') {
                return video.target_status === 'ready';
            }

            if (retries <= 0) {
                throw(new Error('Maximum number of retries reached'));
            }

            const wait = new Promise(resolve => setTimeout(resolve, delay));
            return wait.then(() => {
                return checkIsValidTarget(cancellationToken, videoId, --retries, delay);
            });
        });
}
