import {ReactNode} from "react";
import {Stack, Typography} from "@mui/material";

interface PlayErrorProps {
    title: ReactNode,
    message: ReactNode
}

export function PlayError(props: PlayErrorProps) {
    return (
        <Stack spacing={2} sx={{ height: '100%', justifyContent: 'center', alignItems: 'center', p: 2 }}>
            <Typography variant="h6" align="center">
                {props.title}
            </Typography>

            <Typography variant="body1" align="center">
                {props.message}
            </Typography>
        </Stack>
    );
}
