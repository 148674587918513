import React, {useEffect, useRef, useState} from "react";
import {Button, Paper, Slider as MuiSlider} from "@mui/material";

import {Video} from "../../../shared/Video";
import {PresetMask} from "../../../shared/PresetMask";
import {PresetModel} from "../../../shared/models/PresetModel";

import './FrameSelector.scss';
import {FormattedMessage} from "react-intl";

interface FrameSelectorProps {
    videoData: Blob;
    preset: PresetModel;
    initialPosition?: number;
    onSelected: (data: Blob, position: number) => void;
}

export function FrameSelector(props: FrameSelectorProps) {
    const [videoDuration, setVideoDuration] = useState<number | null>(null);
    const [videoPosition, setVideoPosition] = useState<number>(props.initialPosition ?? 0);
    const [videoSeeking, setVideoSeeking] = useState<boolean>(false);

    const videoRef = useRef<Video>(null);

    useEffect(() => {
        if (!videoRef.current || !videoDuration) {
            return;
        }

        /*
         * When seeking to a frame towards the end of the video, iOS browsers and macOS Safari show an empty image.
         * So keep some distance from the end (lowest valid value obtained through trial and error).
         */
        videoRef.current.seek(videoPosition * (videoDuration - 0.15));
    }, [videoDuration, videoPosition])

    const handleLoadedMetadata = (metadata: {duration: number}) => {
        setVideoDuration(metadata.duration);
    };

    const handleVideoPositionChange = (event: Event, value: number | number[]) => {
        setVideoPosition(value as number);
    };

    const handleSeeking = () => {
        setVideoSeeking(true);
    };

    const handleSeeked = () => {
        setVideoSeeking(false);
    };

    const handleSelect = async () => {
        if (!videoRef.current) {
            return;
        }

        const still = await videoRef.current.getStill();
        props.onSelected(still, videoPosition);
    };

    return (
        <div className="frame-selector-wrapper">
            <PresetMask preset={props.preset}>
                <Video ref={videoRef}
                       src={props.videoData}
                       objectFit="cover"
                       muted={true}
                       onLoadedMetadata={handleLoadedMetadata}
                       onSeeking={handleSeeking}
                       onSeeked={handleSeeked} />
                <div className={`frame-selector-seek-mask ${videoSeeking ? 'active' : ''}`} />
            </PresetMask>
            <div className="frame-selector-controls">
                <Paper
                    sx={{
                        width: '80%',
                        mx: 'auto',
                        px: 2,
                        py: 0.5,
                        opacity: 0.95,
                        display: 'flex',
                        alignItems: 'center'
                    }}
                    elevation={1}
                >
                    <MuiSlider
                        sx={{
                            flexGrow: 1,
                            ml: 1,
                            mr: 3
                        }}
                        track={false}
                        min={0}
                        max={1}
                        step={0.01}
                        value={videoPosition}
                        onChange={handleVideoPositionChange}
                    />
                    <Button onClick={handleSelect}>
                        <FormattedMessage
                            id="record.image.still.selector"
                            description="Record page - Select image - Still tab - Selector - Use button text"
                            defaultMessage="Use"
                        />
                    </Button>
                </Paper>
            </div>
        </div>
    );
}
