import React, {Component} from "react";
import {Box, LinearProgress} from "@mui/material";
import PropTypes from "prop-types";


export class UpdateProgress extends Component {

    render() {
        return (
            <Box sx={{ width: '50%' }}>

                {!this.props.hasError &&
                    <LinearProgress variant="indeterminate" />
                }

                {this.props.hasError &&
                    <LinearProgress variant="determinate" value={0} />
                }

            </Box>
        );
    }

}

UpdateProgress.propTypes = {
    hasError: PropTypes.bool
}
