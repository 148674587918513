import {useEffect, useState} from "react";

export function useHasCamera() {
    const [hasCamera, setHasCamera] = useState<boolean | null>(null);

    useEffect(() => {
        const updateHasCamera = () => {
            canCapture()
                .catch(() => false)
                .then(setHasCamera);
        };

        updateHasCamera();

        window.navigator.mediaDevices?.addEventListener('devicechange', updateHasCamera);
        return () => {
            window.navigator.mediaDevices?.removeEventListener('devicechange', updateHasCamera);
        };
    }, []);

    return hasCamera;
}

async function canCapture(): Promise<boolean> {
    if (!window.navigator.mediaDevices ||
        !window.navigator.mediaDevices.enumerateDevices ||
        !window.navigator.mediaDevices.getUserMedia)
    {
        return false;
    }

    const devices = await window.navigator.mediaDevices.enumerateDevices();
    return !!devices.find(device => device.kind === 'videoinput');
}
