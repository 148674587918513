/**
 *
 * A simple Google Analytics 4 API
 *
 * - For accurate page view counts on single page applications, enable the "Page changes based on browser history
 *   events" setting in enhanced measurement. This is preferred over manually sending page view events, since that will
 *   lead to duplicate page view events if aforementioned setting is enabled (even if the 'send_page_view' configuration
 *   option is set to false, as this only applies to the initial script load).
 *   @see https://support.google.com/analytics/answer/9216061
 *
 */

declare global {
    interface Window {
        dataLayer: any[];
    }
}

// The GA4 measurement ID, or null if not initialized.
let measurementId: string|null = null;

// @see https://developers.google.com/analytics/devguides/collection/ga4/reference/config
interface ConfigParams {
    allow_google_signals?: boolean;
    allow_ad_personalization_signals?: boolean;
    campaign_content?: string;
    campaign_id?: string;
    campaign_medium?: string;
    campaign_name?: string;
    campaign_source?: string;
    campaign_term?: string;
    client_id?: string;
    content_group?: string;
    cookie_domain?: string;
    cookie_expires?: number;
    cookie_flags?: string;
    cookie_path?: string;
    cookie_prefix?: string;
    cookie_update?: boolean;
    debug_mode?: boolean;
    language?: string;
    page_location?: string;
    page_referrer?: string;
    page_title?: string;
    send_page_view?: boolean;
    screen_resolution?: string;
    user_id?: string;
    user_properties?: any;
}

/**
 * Initializes Google Analytics 4
 *
 * @param id
 *     The GA4 measurement ID.
 * @param config
 *     Global configuration parameters.
 */
function init(id: string, config: ConfigParams = {}) {
    // Prevent duplicate initialization
    if (measurementId !== null) {
        return;
    }

    gtag('js', new Date());
    gtag('config', id, config);

    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
    document.body.appendChild(script);

    measurementId = id;
}

interface PageViewParams {
    page_title?: string;
    page_location?: string;
    page_referrer?: string;
}

/**
 * Sends a page view
 *
 * @see https://support.google.com/analytics/answer/11403294
 *
 * @param params
 *     The page view parameters.
 */
function sendPageView(params: PageViewParams): void {
    sendEvent('page_view', params);
}

/**
 * Sends an event
 *
 * @see https://developers.google.com/tag-platform/gtagjs/reference/#event
 *
 * @param name
 *     The event name.
 * @param params
 *     The event parameters.
 */
function sendEvent(name: string, params?: any): void {
    gtag('event', name, params);
}

/**
 * Get the measurement ID
 *
 * @return
 *     The measurement ID, or null if GA4 is not initialized.
 */
function getMeasurementId(): string|null {
    return measurementId;
}

/**
 * Gets a field value
 *
 * @see https://developers.google.com/tag-platform/gtagjs/reference#get
 *
 * @param name
 *     The field name.
 * @return
 *     A promise that resolves to the field value, or undefined if GA4 is not initialized or the field is not set.
 */
function getField(name: string): Promise<any> {
    if (measurementId === null) {
        return Promise.resolve(undefined);
    }

    return new Promise(resolve => {
        gtag('get', measurementId, name, resolve);
    });
}

function gtag(...args: any[]): void {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(arguments);
}

export const GA4 = { init, sendPageView, sendEvent, getMeasurementId, getField };
