export class WebMContainer {

    static get validElementTypes() {
        return ["m", "u", "i", "f", "s", "8", "b", "d"];
    }

    blob;

    constructor(blob) {
        this.blob = blob;
    }

    makeSeekable() {
        return import('ts-ebml').then(ebml => {
            return this.getInfo().then(info => {
                const metadata = ebml.tools.makeMetadataSeekable(info.metadatas, info.duration, info.cues);

                return this.toArrayBuffer().then(buffer => {
                    const body = buffer.slice(info.metadataSize);
                    this.blob = new Blob([metadata, body], {type: this.blob.type});
                });
            });
        });
    }

    getInfo() {
        return import('ts-ebml').then(ebml => {
            return this.toArrayBuffer().then(buffer => {

                const reader = new ebml.Reader();
                const decoder = new ebml.Decoder();

                // Workaround for https://github.com/legokichi/ts-ebml/issues/33
                const elements = decoder.decode(buffer).filter(element => WebMContainer.validElementTypes.includes(element.type));
                elements.forEach(element => reader.read(element));
                reader.stop();

                return {
                    metadataSize: reader.metadataSize,
                    metadatas: reader.metadatas,
                    duration: reader.duration,
                    cues: reader.cues
                };

            });
        });
    }

    getBlob() {
        return this.blob;
    }

    toArrayBuffer() {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onloadend = () => {
                resolve(reader.result);
            };

            reader.onerror = (event) => {
                reject(event.error);
            };

            reader.readAsArrayBuffer(this.blob);
        });
    }

}
