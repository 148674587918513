import {styled} from "@mui/system";
import {Box} from "@mui/material";

/**
 *
 * A box with a preferred aspect ratio.
 *
 * Uses the aspect-ratio CSS property if available, otherwise mimics its behaviour using padding
 * (@see https://css-tricks.com/aspect-ratio-boxes/). Fixing the box' height does not adjust its
 * width in the fallback behaviour, so always use width to drive the height. Example use:
 *
 * ```
 * <AspectBox sx={{ width: '300px' }} ratio={16/9}>...</AspectBox>
 * ```
 */
interface AspectBoxProps {
    ratio: number
}

const AspectBox = styled(Box, {
    name: 'AspectBox',
    slot: 'Root',
})<AspectBoxProps>((props) => ({
    ...(supportsAspectRatio && {
        aspectRatio: props.ratio.toString()
    }),
    ...(!supportsAspectRatio && {
        '::before': {
            content: '""',
            width: '1px',
            marginLeft: '-1px',
            float: 'left',
            paddingTop: `calc(100% / ${props.ratio.toString()})`
        },
        '::after': {
            content: '""',
            display: 'table',
            clear: 'both'
        }
    })
}));

const supportsAspectRatio = 'aspectRatio' in document.createElement('div').style;

export default AspectBox;
