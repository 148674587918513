import {LocalStorage} from "../LocalStorage";
import {Dispatch, SetStateAction, useEffect, useState} from "react";


export function useLocalStorage(key: string): [string|null, Dispatch<SetStateAction<string|null>>] {

    const [value, setValue] = useState<string|null>(LocalStorage.getItem(key));

    useEffect(() => {
        setValue(LocalStorage.getItem(key));
    }, [key]);

    useEffect(() => {
        LocalStorage.setItem(key, value);
    }, [key, value]);

    return [value, setValue];
}
