import React, {Component} from "react";
import PropTypes from "prop-types";
import "objectFitPolyfill";

export class Image extends Component {

    constructor(props) {
        super(props);

        this.state = {
            src: null
        }

        this.objectUrl = null;
        this.elementRef = React.createRef();
    }

    componentDidMount() {
        this.updateSrc();

        // @see https://github.com/constancecchen/object-fit-polyfill/issues/54#issuecomment-525904688
        window.objectFitPolyfill(this.elementRef.current);
    }

    componentWillUnmount() {
        if (this.objectUrl) {
            URL.revokeObjectURL(this.objectUrl);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.src !== this.props.src) {
            this.updateSrc();
        }
    }

    updateSrc() {
        if (this.objectUrl) {
            URL.revokeObjectURL(this.objectUrl);
        }

        const src = this.props.src;
        let imageSrc;

        if (src instanceof Blob) {
            this.objectUrl = URL.createObjectURL(src);
            imageSrc = this.objectUrl;
        } else {
            imageSrc = src;
        }

        this.setState({ src: imageSrc });
    }

    render() {
        const style = { width: '100%', height: '100%', objectFit: this.props.objectFit };

        return (
            <img style={style}
                 ref={this.elementRef}
                 src={this.state.src}
                 alt={this.props.alt}
                 data-object-fit={this.props.objectFit}
            />
        )
    }

}

Image.propTypes = {
    src: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Blob)
    ]),
    alt: PropTypes.string,
    objectFit: PropTypes.string
};
