import {WebMContainer} from "./WebMContainer";

interface Config {
    mediaStream: MediaStream;
    mimeType: string;
    audioBitsPerSecond: number;
    videoBitsPerSecond: number;
    onRecorded: (data: Blob) => void;
    onError: (error: any) => void;
}

export class SimpleMediaRecorder {

    private mediaRecorder: MediaRecorder;

    constructor(config: Config) {
        let data: Blob[];

        this.mediaRecorder = new MediaRecorder(config.mediaStream, {
            mimeType: config.mimeType,
            audioBitsPerSecond: config.audioBitsPerSecond,
            videoBitsPerSecond: config.videoBitsPerSecond
        });

        this.mediaRecorder.onstart = () => {
            data = [];
        };

        this.mediaRecorder.ondataavailable = (event) => {
            data.push(event.data);
        };

        this.mediaRecorder.onstop = () => {
            const type = config.mimeType.split(';')[0];
            const blob = new Blob(data, {type: type});

            if (!type.toLowerCase().endsWith('/webm')) {
                config.onRecorded(blob);
                return;
            }

            const container = new WebMContainer(blob);
            container.makeSeekable()
                .then(() => config.onRecorded(container.getBlob()))
                .catch(error => config.onError(error));
        };

        this.mediaRecorder.onerror = (mediaRecorderError: Event) => {

            // Firefox and Safari might provide additional details via MediaRecorderErrorEvent
            const error = 'error' in mediaRecorderError
                ? mediaRecorderError.error
                : new DOMException('Unknown media recorder error');

            config.onError(error);
        }
    }

    /**
     * Start recording
     *
     * @throws DOMException
     *     Any error that can be detected immediately.
     *     @see https://developer.mozilla.org/en-US/docs/Web/API/MediaRecorder/start
     */
    public start(): void {
        this.mediaRecorder.start();
    }

    /**
     * Stop recording
     *
     * @throws DOMException
     *     Any error that can be detected immediately.
     *     @see https://developer.mozilla.org/en-US/docs/Web/API/MediaRecorder/stop
     */
    public stop(): void {
        this.mediaRecorder.stop();
    }

    /**
     * Determine whether the provided MIME type is supported
     *
     * @param type
     *     The MIME type to test.
     * @return
     *     Whether the MIME type is supported.
     */
    public static isTypeSupported(type: string): boolean {
        return !!window.MediaRecorder && window.MediaRecorder.isTypeSupported(type);
    }

}
