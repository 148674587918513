const happyar = window.happyar;

function init(options: InitOptions): void {
    happyar.init(options);
}

function api<T>(options: APIOptions): Promise<T> {
    const searchParams = new URLSearchParams();

    if (options.fields?.length) {
        searchParams.append('fields', options.fields.join(','));
    }

    if (options.expand?.length) {
        searchParams.append('expand', options.expand.join(','));
    }

    const searchString = searchParams.toString();

    return new Promise<T>((resolve, reject) => {
        happyar.api({
            method: options.method,
            path: options.path + (searchString ? `?${searchString}` : ''),
            contentType: options.contentType,
            data: options.data,
            key: options.key,
            success: resolve,
            error: reject
        });
    });
}

export const sdk = { init, api };

export interface InitOptions {
    accessToken: string,
    apiBaseUrl?: string,
    cdnBaseUrl?: string
}

export interface APIOptions {
    method:  'POST' | 'GET' | 'PUT' | 'PATCH' | 'DELETE';
    path: string;
    contentType?: string;
    data?: any;
    key?: string;
    fields?: string[];
    expand?: string[];
}

