import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {defineMessages, FormattedMessage, useIntl} from "react-intl";
import {Box, Button, rgbToHex, useTheme} from "@mui/material";
import UpdateIcon from '@mui/icons-material/Update';

import {VideoService} from "../../../shared/services/VideoService";
import {CancellationError, CancellationToken} from "../../../shared/CancellationToken";
import {Banner} from "../../../shared/Banner";
import {VideoModel} from "../../../shared/models/VideoModel";
import {TrackingContext} from "../../../TrackingContext";
import {GA4} from "../../../shared/GA4";
import { DialogPage } from "../../../shared/DialogPage";
import {DialogAppBar} from "../../../shared/DialogAppBar";
import {useNavigate} from "react-router-dom";
import { Hint } from "../../../shared/Hint";


const messages = defineMessages({
    qrImageAlt: {
        id: "record.media.mobile.qrcode",
        description: "Record page - Select video - Mobile tab - QR code alternative text.",
        defaultMessage: "QR code"
    }
});

interface MobileProps {
    video: VideoModel;
    accessToken: string;
    imageSelectable: boolean;
    mediaSources: string[];
    imageSources: string[];
    onReady: (video: VideoModel) => void;
}

export function Mobile({
    video,
    accessToken,
    imageSelectable,
    mediaSources,
    imageSources,
    onReady
}: MobileProps) {
    const navigate = useNavigate();
    const isTracking = useContext(TrackingContext);

    const [qrImageUrl, setQrImageUrl] = useState<string|null>(null);
    const [hasError, setHasError] = useState<boolean>(false);
    const cancellationToken = useRef<CancellationToken>();

    const intl = useIntl();
    const theme = useTheme();

    useEffect(() => {
        const createQrImageUrl = async () => {

            /*
             * GA4 cross-domain measurement
             *
             * - The recommended approach of adding a domain via the Admin console does not work, because
             *   the tag.js link decorator does not add the _gl parameter to iframe src links.
             * - Manually generating the _gl parameter requires the deprecated analytics.js library and is not
             *   officially supported.
             * - So we use the alternative approach suggested by Google, and pass the GA4 client ID and
             *   session ID.
             *
             * @see https://support.google.com/analytics/answer/10071811?hl=en&ref_topic=12153646,12153943,2986333,&sjid=16076513191510125897-EU&visit_id=638445597557626131-3250924329&rd=1#zippy=%2Cmanual-setup
             * @see https://gtm-gear.com/posts/ga4-cross-domain-linker/
             * @see https://www.thyngster.com/cross-domain-tracking-on-google-analytics-4-ga4
             */
            const ga4ClientId = await GA4.getField('client_id');
            const ga4SessionId = await GA4.getField('session_id');

            const url = new URL(video.qrcode);

            url.searchParams.set('locale', intl.locale);
            url.searchParams.set('color', rgbToHex(theme.palette.text.primary));
            url.searchParams.set('platform', 'web');
            url.searchParams.set('purpose', 'record');
            url.searchParams.set('label', '0');
            url.searchParams.set('imageSelectable', imageSelectable ? '1' : '0');
            url.searchParams.set('accessToken', accessToken);
            url.searchParams.set('trackingEnabled', isTracking ? '1' : '0');

            if (ga4ClientId) {
                url.searchParams.set('ga4ClientId', ga4ClientId);
            }

            if (ga4SessionId) {
                url.searchParams.set('ga4SessionId', ga4SessionId);
            }

            for (let mediaSource of mediaSources) {
                url.searchParams.append('mediaSources[]', mediaSource);
            }

            for (let imageSource of imageSources) {
                url.searchParams.append("imageSources[]", imageSource);
            }

            return url.toString();
        };

        createQrImageUrl().then(setQrImageUrl);
    }, [video, imageSelectable, accessToken, mediaSources, imageSources, intl, theme, isTracking]);

    const checkIsReady = useCallback(async () => {
        try {
            setHasError(false);
            await waitUntilReady(cancellationToken.current!, video, imageSelectable);
            const readyVideo = await VideoService.get(video.id) as VideoModel;
            onReady(readyVideo);
        } catch (error: unknown) {
            if (error instanceof CancellationError) {
                return;
            } else if (error instanceof TimeoutError) {
                GA4.sendEvent('record_media_mobile_timeout');
            } else {
                GA4.sendEvent('record_media_mobile_error');
            }
            setHasError(true);
        }
        // Don't include props.onReady in the dependency array because it changes on every parent re-render.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [video, onReady, imageSelectable]);

    useEffect(() => {
        cancellationToken.current = new CancellationToken();
        checkIsReady().then();

        return () => {
            cancellationToken.current?.cancel();
        };
    }, [checkIsReady]);

    const handleBack = () => {
        navigate(-1);
    };

    const appBar =
        <DialogAppBar
            title={<FormattedMessage
                id="record.media.mobile.title"
                description="Record page - Select video - Mobile tab - Title."
                defaultMessage="Use another device"
            />}
            canBack={true}
            onBack={handleBack}
        />;

    return (
        <DialogPage appBar={appBar}>
            <Banner
                icon={
                    <UpdateIcon />
                }
                text={
                    <FormattedMessage
                        id="record.media.mobile.error"
                        description="Record page - Select video - Mobile tab - Timeout text."
                        defaultMessage="Could not determine if a video was selected."
                    />
                }
                buttons={[
                    <Button color="primary" onClick={checkIsReady}>
                        <FormattedMessage
                            id="shared.retry"
                            description="Retry button text."
                            defaultMessage="Retry"
                        />
                    </Button>
                ]}
                isOpen={hasError}
            />
            <Hint
                text={
                    <FormattedMessage
                        id="record.media.mobile.instruction.text"
                        description="Record page - Select video - Mobile tab - Instruction text."
                        defaultMessage="Scan the QR code with the device that you want to select a video from."
                    />
                }
            />
            <Box sx={{p: 2, display: 'flex', justifyContent: 'center'}}>
                {qrImageUrl &&
                    <img
                        src={qrImageUrl}
                        alt={intl.formatMessage(messages.qrImageAlt)}
                    />
                }
            </Box>
        </DialogPage>
    );

}

function wait(duration: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, duration));
}

function waitUntilReady(cancellationToken: CancellationToken, video: VideoModel, imageSelectable: boolean, retries=60, delay=5000): Promise<void> {
    cancellationToken.throwIfCancellationRequested();

    if (retries < 0) {
        throw new TimeoutError('Maximum number of retries reached');
    }

    return wait(delay)
        .then(() => {
            return VideoService.get(video.id, ['order_image', 'order_media', 'target_status']);
        })
        .then(curVideo => {
            if ((curVideo.order_media === video.order_media) ||
                (imageSelectable && (
                    curVideo.order_image === video.order_image ||
                    curVideo.target_status !== 'ready'
                )))
            {
                return waitUntilReady(cancellationToken, video, imageSelectable, --retries, delay);
            }
        });
}

class TimeoutError extends Error {}
