import React, {Component} from "react";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

export class CameraError extends Component {

    /*
     * @see https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
     */
    render() {
        switch (this.props.error.name) {
            case "NotAllowedError":
            case "SecurityError":
                return <FormattedMessage
                    id="record.image.photo.camera-error.access-denied"
                    description="Record page - Select image - Camera tab - Camera access denied text."
                    defaultMessage="Camera access is denied."
                />;

            case "NotFoundError":
            case "OverconstrainedError":
                return <FormattedMessage
                    id="record.image.photo.camera-error.not-found"
                    description="Record page - Select image - Camera tab - No suitable camera found text."
                    defaultMessage="No suitable camera found."
                />;

            case "AbortError":
            case "NotReadableError":
                return <FormattedMessage
                    id="record.image.photo.camera-error.not-usable"
                    description="Record page - Select image - Camera tab - Error using the camera text."
                    defaultMessage="Error using the camera."
                />;

            default:
                return <FormattedMessage
                    id="record.image.photo.camera-error.unknown"
                    description="Record page - Select image - Camera tab - Unknown camera error text."
                    defaultMessage="Unknown camera error."
                />;
        }
    }
}

CameraError.propTypes = {
    error: PropTypes.objectOf(DOMException).isRequired
};
