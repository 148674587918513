import {useHasCamera} from "./photo/useHasCamera";
import React, {ReactElement, useRef} from "react";
import {SourceCard} from "../shared/SourceCard";
import {FormattedMessage} from "react-intl";
import { NoSource } from "./NoSource";
import {DialogPage} from "../../shared/DialogPage";
import {Stack} from "@mui/material";
import {File} from "./file/File";
import {FileSelector} from "../shared/FileSelector";
import {GA4} from "../../shared/GA4";
import {useRecordDispatch} from "../../shared/reducers/RecordStateProvider";
import {Outlet, Route, Routes, useNavigate} from "react-router-dom";
import {ErrorPage} from "../../error/ErrorPage";
import {PresetModel} from "../../shared/models/PresetModel";
import {Photo} from "./photo/Photo";
import {Still} from "./still/Still";
import {DialogAppBar} from "../../shared/DialogAppBar";
import { Hint } from "../../shared/Hint";


interface ImageProps {
    imageSources: string[];
    videoData: Blob;
    maxSize?: number;
    minResolution?: {
        width: number;
        height: number;
    };
    preset: PresetModel;
    onAccept: (data: Blob) => void;
}

export function Image({
    imageSources,
    videoData,
    maxSize = 20 * 1024 * 1024,
    minResolution = { width: 720, height: 720 },
    preset,
    onAccept
}: ImageProps) {

    const dispatch = useRecordDispatch();
    const navigate = useNavigate();
    const hasCamera = useHasCamera();
    const fileSelectorRef = useRef<FileSelector>(null);

    const handleStillClick = () => {
        GA4.sendEvent('record_image_source_select', { source: 'still' });
        navigate('still');
    };

    const handlePhotoClick = () => {
        GA4.sendEvent('record_image_source_select', { source: 'photo' });
        navigate('photo');
    };

    const handleFileClick = () => {
        GA4.sendEvent('record_image_source_select', { source: 'file' });
        fileSelectorRef.current?.open();
    };

    const handleFileOpened = () => {
        GA4.sendEvent('record_image_file_open');
    };

    const handleFileSelected = (data: Blob) => {
        dispatch({ type: 'setImageFileOriginalData', payload: data });
        GA4.sendEvent('record_image_file_select');
        navigate('file');
    };

    const handleFileError = (error: DOMException) => {
        GA4.sendEvent('record_image_file_error', { error_name: error.name });
    };

    const handleAccept = (data: Blob) => {
        GA4.sendEvent('record_image_next');
        onAccept(data);
    };

    const handleBack = () => {
        navigate(-1);
    };

    if (hasCamera === null) {
        return null;  // TODO: loading spinner while searching for camera
    }

    const cards: {[key: string]: ReactElement | false} = {
        'still':
            <SourceCard
                key='still'
                image='/img/movie.svg'
                title={
                    <FormattedMessage
                        id="record.image.btn-still.title"
                        description="Record page - Select image - Still button - Title."
                        defaultMessage="Select from your video"
                    />
                }
                text={
                    <FormattedMessage
                        id="record.image.btn-still.text"
                        description="Record page - Select image - Still button - Text."
                        defaultMessage="Choose a still image from your video."
                    />
                }
                onClick={handleStillClick}
            />,

        'photo': hasCamera &&
            <SourceCard
                key='photo'
                image='/img/camera.svg'
                title={
                    <FormattedMessage
                        id="record.image.btn-photo.title"
                        description="Record page - Select image - Photo button - Title."
                        defaultMessage="Take a picture"
                    />
                }
                text={
                    <FormattedMessage
                        id="record.image.btn-photo.text"
                        description="Record page - Select image - Photo button - Text."
                        defaultMessage="Use this device to take a picture now."
                    />
                }
                onClick={handlePhotoClick}
            />,

        'file':
            <SourceCard
                key='file'
                image='/img/upload.svg'
                title={
                    <FormattedMessage
                        id="record.image.btn-file.title"
                        description="Record page - Select image - File button - Title."
                        defaultMessage="Upload an image"
                    />
                }
                text={
                    <FormattedMessage
                        id="record.image.btn-file.text"
                        description="Record page - Select image - File button - Text."
                        defaultMessage="Use an image that is stored on this device."
                    />
                }
                onClick={handleFileClick}
            />
    };

    const filteredCards = imageSources
        .map(imageSource => cards[imageSource])
        .filter(Boolean);

    if (!filteredCards.length) {
        return <NoSource />;
    }

    const appBar =
        <DialogAppBar
            title={
                <FormattedMessage
                    id="record.image.title"
                    description="Record page - Select image - Title text."
                    defaultMessage="Select an image"
                />
            }
            canBack={true}
            onBack={handleBack}
        />;

    const page =
        <DialogPage appBar={appBar}>
            <Hint text={
                <FormattedMessage
                    id="record.image.instruction"
                    description="Record page - Select image - Hint"
                    defaultMessage="Select an image for the front of your card. How would you like to provide the image?"
                />
            }/>
            <Stack spacing={2} sx={{p: 2}}>
                { filteredCards }
            </Stack>
            <FileSelector
                ref={fileSelectorRef}
                type="image"
                maxSize={maxSize}
                minResolution={minResolution}
                onOpen={handleFileOpened}
                onSelected={handleFileSelected}
                onError={handleFileError}
            />
        </DialogPage>;

    const still =
        <Still
            videoData={videoData}
            preset={preset}
            onAccept={handleAccept}
        />;

    const photo =
        <Photo
            preset={preset}
            onAccept={handleAccept}
        />;

    const file =
        <File
            preset={preset}
            onAccept={handleAccept}
        />;

    return (
        <Routes>
            <Route path="/" element={ <Outlet /> }>
                <Route index element={ page } />
                <Route path="still/*" element={ still } />
                <Route path="photo/*" element={ photo } />
                <Route path="file/*" element={ file }/>
                <Route path="*" element={ <ErrorPage /> } />
            </Route>
        </Routes>
    );

}
