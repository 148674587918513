import React, {Component} from "react";
import PropTypes from "prop-types";

/**
 *
 * Component that reports the size of the (content) box that envelopes its children.
 *
 * Reported sizes are updated as soon as the component size changes. Android will report the size in decimal numbers.
 * This component requires the ResizeObserver polyfill for IE11.
 */
export class SizeMonitor extends Component {

    constructor(props) {
        super(props);

        this.elementRef = React.createRef();

        this.resizeObserver = new ResizeObserver(entries => {
            if (!entries.length) {
                return;
            }

            const entry = entries[0];
            if (entry.contentBoxSize) {
                // Used by Chrome, Edge, Firefox, Opera, IE, Samsung Internet
                // Some older versions of Firefox do not implement contentBoxSize as an array
                const contentBoxSize = Array.isArray(entry.contentBoxSize) ? entry.contentBoxSize[0] : entry.contentBoxSize;
                this.props.onResize({ width: contentBoxSize.inlineSize, height: contentBoxSize.blockSize });
            } else {
                // Used by Safari
                this.props.onResize({ width: entry.contentRect.width, height: entry.contentRect.height });
            }
        });
    }

    componentDidMount() {
        this.resizeObserver.observe(this.elementRef.current);
    }

    componentWillUnmount() {
        this.resizeObserver.unobserve(this.elementRef.current);
    }

    render() {
        const style = { width: '100%', height: '100%' };

        return (
            <div ref={this.elementRef} style={style}>
                {this.props.children}
            </div>
        );
    }

}

SizeMonitor.propTypes = {
    onResize: PropTypes.func.isRequired
}
