import {useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import {VideoModel} from "../shared/models/VideoModel";
import {PlayError} from "./PlayError";
import {VideoService} from "../shared/services/VideoService";
import {ZapparFrame} from "./ZapparFrame";


interface VideoPlayerProps {
    apiBaseUrl?: string,
    accessToken: string,
    videoId: string,
    resourceKey?: string
}

export function VideoPlayer(props: VideoPlayerProps) {

    const [video, setVideo] = useState<Partial<VideoModel> | null>(null);

    useEffect(() => {
        const fields = ['order_completed', 'media_status', 'target_status', 'views', 'max_views'];
        VideoService.get(props.videoId, fields, [], props.resourceKey).then(setVideo);
    }, [props.videoId, props.resourceKey]);

    if (!video) {
        // TODO: show loading spinner
        return null;
    }

    if (!video.order_completed) {
        return <PlayError
            title={<FormattedMessage
                id="play.order-not-completed.title"
                description="Play - Order not completed - Title text."
                defaultMessage="Not recorded"
            />}
            message={<FormattedMessage
                id="play.order-not-completed.message"
                description="Play - Order not completed - Message text."
                defaultMessage="The video has not been recorded yet. Try again later."
            />}
        />;
    }

    if ((video.media_status !== 'ready') || (video.target_status !== 'ready')) {
        return <PlayError
            title={<FormattedMessage
                id="play.not-ready.title"
                description="Play - Content not ready - Title text."
                defaultMessage="Not ready"
            />}
            message={<FormattedMessage
                id="play.not-ready.message"
                description="Play - Content not ready - Message text."
                defaultMessage="The video is not ready for playback yet. Try again later."
            />}
        />;
    }

    if (video.max_views && (video.views! >= video.max_views)) {
        return <PlayError
            title={<FormattedMessage
                id="play.view-limit-reached.title"
                description="Play - View limit reached - Title text."
                defaultMessage="View limit reached"
            />}
            message={<FormattedMessage
                id="play.view-limit-reached.message"
                description="Play - View limit reached - Message text."
                defaultMessage="This video has been viewed too many times."
            />}
        />;
    }

    return (
        <ZapparFrame
            apiBaseUrl={props.apiBaseUrl}
            accessToken={props.accessToken}
            videoId={props.videoId}
            resourceKey={props.resourceKey}
        />
    );
}
