import React, {Component} from "react";
import {DialogAppBar} from "../../shared/DialogAppBar";
import PropTypes from "prop-types";
import {DialogPage} from "../../shared/DialogPage";
import {FormattedMessage} from "react-intl";
import {Box, Stack, Typography} from "@mui/material";


export class Completed extends Component {

    render() {
        const appBar =
            <DialogAppBar
                title={<FormattedMessage
                    id="record.completed.title"
                    description="Record page - Order already completed - Title text."
                    defaultMessage="Order completed"
                />}
                canClose={this.props.canClose}
                onClose={this.props.onClose}
            />;

        return (
            <DialogPage appBar={appBar}>
                <Box sx={{ p:2, display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
                    <Stack spacing={2} alignItems="center" sx={{ width: '100%' }}>
                        <Typography variant="body1" align="center">
                            <FormattedMessage
                                id="record.completed.text"
                                description="Record page - Order already completed - Text."
                                defaultMessage="Order is already completed"
                            />
                        </Typography>
                        <Typography sx={{ width: '75%' }} variant="body2" align="center">
                            <FormattedMessage
                                id="record.completed.subtext"
                                description="Record page - Order already completed - Subtext."
                                defaultMessage="Contact customer support if you want to modify your order."
                            />
                        </Typography>
                    </Stack>
                </Box>
            </DialogPage>
        );
    }

}

Completed.propTypes = {
    canClose: PropTypes.bool,
    onClose: PropTypes.func
}
