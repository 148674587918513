import React from "react";
import {useNavigate} from "react-router-dom";
import {PresetModel} from "../../../shared/models/PresetModel";
import {useRecordDispatch, useRecordState} from "../../../shared/reducers/RecordStateProvider";
import {PinturaImageState} from "@pqina/pintura";
import {Preview} from "../shared/Preview";


interface FileProps {
    preset: PresetModel;
    onAccept: (data: Blob) => void;
}

export function File({
    preset,
    onAccept
}: FileProps) {

    const state = useRecordState();
    const dispatch = useRecordDispatch();

    const navigate = useNavigate();

    const imageData = state.image.file.data;
    const originalImageData = state.image.file.originalData;
    const editorState = state.image.file.editorState;

    const handleEdited = (data: Blob, editorState: PinturaImageState) => {
        dispatch( { type: 'setImageFileEditedData', payload: { data, editorState } });
    };

    const handleNext = () => {
        if (imageData) {
            onAccept(imageData);
        }
    };

    const handleBack = () => {
        navigate(-1);
    };

    if (!imageData || !originalImageData) {
        return null;    // TODO: expected image data set
    }

    return (
        <Preview
            imageData={imageData}
            originalImageData={originalImageData}
            preset={preset}
            editorState={editorState}
            onEdited={handleEdited}
            onNext={handleNext}
            onBack={handleBack}
        />
    );
}
