import {sdk} from './sdk';
import {AlbumModel} from "../models/AlbumModel";


function get(id: string, fields?: string[], expand?: string[], key?: string): Promise<Partial<AlbumModel>> {
    return sdk.api({
        method: 'GET',
        path: `/albums/${id}`,
        fields: fields,
        expand: expand,
        key: key
    });
}

export const AlbumService = { get };
