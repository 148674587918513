import {Paper, styled, Typography} from "@mui/material";
import React, {ReactNode} from "react";


const HintRoot = styled(Paper, {
    name: 'Hint',
    slot: 'Root',
    overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
    background: theme.palette.background.default,
    padding: theme.spacing(4)
}));

interface HintProps {
    text: ReactNode;
}

export function Hint({ text }: HintProps) {
    return (
        <HintRoot square elevation={0}>
            <Typography variant="body1" align="center">
                {text}
            </Typography>
        </HintRoot>
    );
}
