import {sdk} from './sdk';
import {PresetModel} from "../models/PresetModel";


function post(data: Partial<PresetModel>, fields?: string[], expand?: string[]): Promise<Partial<PresetModel>> {
    return sdk.api<PresetModel>({
        method: 'POST',
        path: `/presets`,
        data: data,
        fields: fields,
        expand: expand
    });
}

function putAlphaMask(id: number, data: Blob, key?: string): Promise<void> {
    return sdk.api({
        method: 'PUT',
        path: `/presets/${id}/alpha_mask`,
        contentType: data.type,
        data: data,
        key: key
    });
}

function putOverlay(id: number, data: Blob, key?: string): Promise<void> {
    return sdk.api({
        method: 'PUT',
        path: `/presets/${id}/overlay`,
        contentType: data.type,
        data: data,
        key: key
    });
}

export const PresetService = { post, putAlphaMask, putOverlay };
