import React, {Component} from "react";

import './IconButton.scss';
import PropTypes from "prop-types";

export class IconButton extends Component {
    render() {
        return (
            <button className={`icon-button icon-button-${this.props.size}`}
                    onClick={this.props.onClick}>
                <span className="material-icons md-light">{this.props.icon}</span>
            </button>
        );
    }
}

IconButton.defaultProps = {
    size: 's'
};

IconButton.propTypes = {
    icon: PropTypes.string.isRequired,
    size: PropTypes.oneOf(['s', 'm', 'l']),
    onClick: PropTypes.func
};
