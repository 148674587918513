import React, {useEffect} from "react";
import {Outlet, Route, Routes, useNavigate} from "react-router-dom";
import {Box, CircularProgress, useThemeProps} from "@mui/material";

import {Record, RecordParams} from "./record/Record";
import {Play, PlayParams} from "./play/Play";
import {ErrorPage} from "./error/ErrorPage";
import {VideoModel} from "./shared/models/VideoModel";
import {VideoService} from "./shared/services/VideoService";
import {RecordStateProvider} from "./shared/reducers/RecordStateProvider";


interface AppProps {
    config: {
        accessToken: string,
        trackingEnabled: boolean,
        apiBaseUrl: string,
        apiVersion: number,
        dialog?: string,
        isOverlay: boolean,
        locale: string,
        grooveId: string|null,
        params: RecordParams | PlayParams
    };
    onSuccess: (video: VideoModel) => void;
    onClose: () => void
}

/**
 *
 * Navigate to a record or play flow in the following order:
 *
 * 1. Follow deep links to /record or /play.
 * 2. If a value is provided in props.config.dialog, then navigate to that path.
 * 3. If a video ID is provided in props.config.params and the video's order has completed, then navigate to /play.
 * 4. Otherwise navigate to /record.
 */
export function App(inProps: AppProps) {
    const props = useThemeProps({ props: inProps, name: 'App' });

    const redirect =
        <Redirect
            dialog={props.config.dialog}
            params={props.config.params}
        />;

    const record =
        <RecordStateProvider>
            <Record
                params={props.config.params as RecordParams}
                accessToken={props.config.accessToken}
                canClose={props.config.isOverlay}
                onSuccess={props.onSuccess}
                onClose={props.onClose}
            />
        </RecordStateProvider>;

    const play =
        <Play
            params={props.config.params as PlayParams}
            apiBaseUrl={props.config.apiBaseUrl}
            accessToken={props.config.accessToken}
            grooveId={props.config.grooveId}
        />;

    return (
        <Routes>
            <Route path="/" element={ <Outlet /> }>
            <Route index element={ redirect } />
            <Route path="record/*" element={ record } />
            <Route path="play/*" element={ play } />
            <Route path="*" element={ <ErrorPage /> } />
            </Route>
        </Routes>
    );

}

function Redirect(props: { dialog?: string, params: any }) {
    const navigate = useNavigate();

    useEffect(() => {
        const redirect = (path: string) => navigate(path, { replace: true });

        if (props.dialog) {
            redirect(props.dialog);
            return;
        }

        if (props.params.albumId) {
            redirect('play');
            return;
        }

        if (!props.params.videoId) {
            redirect('record');
            return;
        }

        VideoService
            .get(props.params.videoId, ['order_completed'], undefined, props.params.videoKey)
            .then(video => {
                redirect(video.order_completed ? 'play' : 'record')
            });
    }, [props, navigate]);

    return (
        <Box sx={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress />
        </Box>
    );
}
