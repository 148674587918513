import './Events';

/**
 *
 * A message bridge between an iframe and its parent
 *
 */
export class Bridge extends EventTarget {

    constructor() {
        super();
        window.addEventListener('message', this.handleMessage.bind(this), false);
    }

    /**
     *
     * Send a message to the parent
     *
     * @param {!Object} message
     *     The message to send.
     */
    sendMessage(message) {
        try {
            // Envelope the message
            const data = { source: 'HappyAR', payload: message };

            /*
             * To prevent interception of the message by a malicious third party, we restrict the target origin
             * to our creator via the document referrer. We allow any target origin if no document referrer is set.
             */
            window.parent.postMessage(data, window.document.referrer || '*');
        } catch (error) {
            this.handleError(error);
        }
    }

    handleMessage(event) {
        // Ignore messages from non-parents (including e.g. ourself or the React Developer Tools extension)
        if (event.source !== window.parent || window.self === window.parent) {
            return;
        }

        // Ignore non-HappyAR messages (including e.g. internal Chrome messages on iOS)
        if (!event.data || event.data.source !== 'HappyAR') {
            return;
        }

        this.dispatchEvent(new CustomEvent('message', {
            detail: event.data.payload
        }));
    }

    handleError(error) {
        this.dispatchEvent(new CustomEvent('error', {
            detail: error
        }));
    }

}
