/**
 *
 * A file input
 *
 * MOBILE CAMERA INPUT
 *  - There is no way to explicitly suppress the camera/camcorder input options.
 *  - Android:
 *    + always shows both the camera and camcorder options if the accept filter contains a non-image file extension
 *    + does not show the camcorder option if the accept filter consists of only image MIME types or file extensions
 *    + does not show the camera option if the accept filter consists of a single MIME type of type "video/"
 * - iOS first excludes accept filters it doesn't know, then shows:
 *   + "Take photo": if all remaining filters are image formats
 *   + "Record video": if all remaining filters are video formats
 *   + "Take photo or record video": if the remaining filters contain both image and video formats
 *   + None of the above if the remaining filters do not include image or video formats
 * - iOS records videos at 480x360 resolution, Android records at 1920x1080 resolution
 *
 * FILE FILTERING
 *  - When using "video/*" or "image/*" accept filters, listed file extensions differ per browser.
 *  - Most desktop browsers:
 *    + allow the user to override accept filters
 *    + name the filter "Custom files" if more than one MIME type or extension is specified
 *    + name the filter "Video files" only if the "video/*" MIME type is specified.
 *    + name the filter "Image files" only if the "image/*" MIME type is specified.
 *  - The Android file selector shows all files, even if file extensions are specified.
 *  - Android file selector only filters on first type if multiple MIME types are provided (at least for images)
 *
 * MISCELLANEOUS
 * - The selected file MIME type reported by the input is unreliable. It is based on just the file extension, therefore:
 *   + the file is not guaranteed to contain content of the specified MIME type
 *   + the content might still be displayable in a browser, even if the MIME type suggests otherwise
 * - Browsers' file extensions for "image/*" and "video/*" filters are much broader than content that is displayable
 *   by <img> and <video> elements. Browsers may change the list of included file extensions over time, but also
 *   displayable content by <img> and <video> elements may change over time.
 * - iOS transcodes a selected video to MP4 format with 720p resolution (unless the multiple attribute is set)
 * - We can safely assume that our back end supports all file types that are displayable in a browser.
 * - Some browsers (e.g. Opera, iOS Safari) position the file selection popup near the file input element. So for
 *   usability, we should keep the file input near the button that activates it.
 *
 * CONCLUSION
 * - Use the "image/*" and "video/*" accept filters such that camera/camcorder input options are correct on mobile
 * - Check whether files are displayable in a browser instead of relying on file extensions or MIME types
 */

import React, {Component} from "react";

import "./FileInput.scss";
import PropTypes from "prop-types";


const AcceptEnum = Object.freeze({
    image: "image/*",
    video: "video/*"
});

export class FileInput extends Component {

    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);

        this.inputRef = React.createRef();
    }

    render() {
        return (
          <input className="file-input"
                 type="file"
                 ref={this.inputRef}
                 accept={AcceptEnum[this.props.type]}
                 onClick={this.handleClick}
                 onChange={this.handleInputChange}
          />
        );
    }

    open() {
        this.inputRef.current.click();
    }

    handleClick(event) {
        // Prevent a programmatically generated click from bubbling to the parent
        event.stopPropagation();

        // Ensure the change event fires if the same file is selected twice in a row
        this.inputRef.current.value = null;

        this.props.onOpen?.();
    }

    handleInputChange() {
        const files = this.inputRef.current.files;

        if (files.length === 0) {
            return;
        }

        this.props.onSelected(files[0]);
    }

}

FileInput.propTypes = {
    type: PropTypes.oneOf(['image', 'video']).isRequired,
    onOpen: PropTypes.func,
    onSelected: PropTypes.func.isRequired
};
