import React, {Component} from "react";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

export class RecorderError extends Component {

    /*
     * @see https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
     * @see https://developer.mozilla.org/en-US/docs/Web/API/MediaRecorder/onerror
     */
    render() {
        switch (this.props.error.name) {
            case "NotAllowedError":
            case "SecurityError":
                return <FormattedMessage
                    id="record.media.camera.recorder-error.device-access-denied"
                    description="Record page - Select video - Camera tab - Camera or microphone access denied text."
                    defaultMessage="Cannot access the camera or microphone."
                />;

            case "NotFoundError":
            case "OverconstrainedError":
                return <FormattedMessage
                    id="record.media.camera.recorder-error.device-not-found"
                    description="Record page - Select video - Camera tab - No suitable camera or microphone found text."
                    defaultMessage="No suitable camera or microphone found."
                />;

            case "AbortError":
            case "NotReadableError":
                return <FormattedMessage
                    id="record.media.camera.recorder-error.device-error"
                    description="Record page - Select video - Camera tab - Error using the camera or microphone text."
                    defaultMessage="Error using the camera or microphone."
                />;

            case "NotSupportedError":
                return <FormattedMessage
                    id="record.media.camera.recorder-error.mime-type-not-supported"
                    description="Record page - Select video - Camera tab - Browser does not support recording text."
                    defaultMessage="Browser does not support recording."
                />;

            default:
                return <FormattedMessage
                    id="record.media.camera.recorder-error.unknown"
                    description="Record page - Select video - Camera tab - Unknown recording error text."
                    defaultMessage="Unknown recording error."
                />;
        }
    }
}

RecorderError.propTypes = {
    error: PropTypes.objectOf(DOMException).isRequired
};
