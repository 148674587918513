import React, {useCallback, useEffect, useState} from "react";
import * as Sentry from "@sentry/react";
import {ErrorPage} from "./error/ErrorPage";


/**
 *
 * An error boundary component that shows an error UI on:
 * - React child component errors in constructor, render and lifecycle methods
 * - Global errors (including errors raised in event handlers)
 * - Global uncaught promise rejections
 *
 * Note that this component does not log errors. However, it does pass the error
 * that occurred in the onConfirm callback after the user acknowledges the error.
 */

interface ErrorBoundaryProps {
    confirm: boolean;
    onError: (error: Error) => void;
    children: React.ReactElement
}

export function ErrorBoundary({confirm, onError, children}: ErrorBoundaryProps) {

    const [error, setError] = useState<Error|null>(null);

    const handleError = useCallback((error: Error) => {
        // Use timeout to prevent updating state when an error is thrown during a render cycle
        setTimeout(() => {
            setError(error);

            if (!confirm) {
                onError(error);
            }
        }, 0);
    }, [confirm, onError]);

    useEffect(() => {
        const handleErrorEvent = (event: ErrorEvent) => {
            handleError(event.error);
        };

        const handleUnhandledRejectionEvent = (event: PromiseRejectionEvent) => {
            handleError(new Error(`Unhandled promise rejection: ${event.reason}`));
        }

        window.addEventListener('error', handleErrorEvent);
        window.addEventListener('unhandledrejection', handleUnhandledRejectionEvent);
        return () => {
            window.removeEventListener('error', handleErrorEvent);
            window.removeEventListener('unhandledrejection', handleUnhandledRejectionEvent);
        };
    }, [handleError]);

    if (error) {
        return <ErrorPage error={error} canClose={confirm} onClose={() => onError(error)} />;
    }

    return (
        <Sentry.ErrorBoundary onError={handleError}>
            {children}
        </Sentry.ErrorBoundary>
    );
}
