import React, {Component} from "react";
import PropTypes from "prop-types";
import {withTheme} from "@emotion/react";

import {Mask} from "./Mask";


class PresetMaskWithTheme extends Component {

    render() {
        return (
            <Mask aspectRatio={this.props.preset.width / this.props.preset.height}
                  backgroundColor={this.props.theme.palette.background.default}
                  alpha={this.props.preset.clipping_mask}
                  overlay={this.props.preset.overlay}
                  mirrored={this.props.mirrored} >
                {this.props.children}
            </Mask>
        );
    }

}

PresetMaskWithTheme.defaultProps = {
    mirrored: false
}

PresetMaskWithTheme.propTypes = {
    children: PropTypes.node.isRequired,
    preset: PropTypes.shape({
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
        overlay: PropTypes.string,
        clipping_mask: PropTypes.string
    }).isRequired,
    mirrored: PropTypes.bool
}

export const PresetMask = withTheme(PresetMaskWithTheme);
