import {sdk} from './sdk';
import {StoreModel} from "../models/StoreModel";


function get(id: number|'me', fields?: string[], expand?: string[]): Promise<Partial<StoreModel>> {
    return sdk.api<StoreModel>({
        method: 'GET',
        path: `/stores/${id}`,
        fields: fields,
        expand: expand
    });
}

export const StoreService = { get };
