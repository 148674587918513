import {LocalStorage} from "./shared/LocalStorage";

export interface LoadedLocale {
    locale: string,
    messages?: { [key: string]: string }
}

export class LocaleLoader {

    /**
     * Load a locale
     *
     * Loads the first available locale from the ./i18n folder out of:
     * 1. The specified preferred locale.
     * 2. The last successfully loaded locale on this device.
     * 3. The browser's locale preferences.
     * 4. The specified default locale.
     *
     * Then persists the loaded locale and applies message overrides from the
     * ./i18n/overrides/{overridesPath} folder for the loaded locale.
     *
     * @param defaultLocale
     *    The default locale.
     * @param preferredLocale
     *    The preferred locale.
     * @param overridesPath
     *    The path relative to the overrides folder to load overrides from.
     * @returns
     *     A promise that resolves to an object containing the loaded locale and corresponding
     *     messages, or rejects if none of the provided locales can be loaded.
     */
    public load(defaultLocale: string, preferredLocale: string | null = null, overridesPath: string | null = null): Promise<LoadedLocale> {
        const locales = this.getLocales(defaultLocale, preferredLocale);
        return this.loadLocale(locales, defaultLocale).then(loadedLocale => {
            this.storedLocale = loadedLocale.locale;
            return this.loadOverrides(loadedLocale.locale, overridesPath).then(overrides => {
                return {
                    locale: loadedLocale.locale,
                    messages: {...loadedLocale.messages, ...overrides}
                };
            });
        });
    }

    /**
     * Get a list of locales that may match the user's preference
     *
     * @param defaultLocale
     *     The default locale.
     * @param preferredLocale
     *     The preferred locale.
     * @returns
     *     An array of locales ordered by preference.
     */
    private getLocales(defaultLocale: string, preferredLocale?: string | null): string[] {
        let locales = [];

        // Start with the preferred locale
        if (preferredLocale) {
            locales.push(preferredLocale);
        }

        // Add the stored locale
        const storedLocale = this.storedLocale;
        if (storedLocale !== null) {
            locales.push(storedLocale);
        }

        // Add browser locale preferences
        locales.push(...(window.navigator.languages || [window.navigator.language]));

        // Inject locales without region
        locales = locales.reduce((previous: string[], current: string) => {
            previous.push(current);

            const locale = this.parseLocale(current);
            if (locale && locale.region) {
                previous.push(locale.language);
            }

            return previous;
        }, []);

        // Finish with the default locale
        locales.push(defaultLocale);

        // Remove casing inconsistencies (due to user input and browser differences)
        locales = locales.map(locale => locale.toLowerCase());

        // Return unique locales in order
        return [...new Set(locales)];
    }

    /**
     * Load the first available locale from a list
     *
     * @param locales
     *     An ordered list of locales to attempt to load.
     * @param defaultLocale
     *    The default locale.
     * @returns
     *     A promise that resolves to an object containing the loaded locale and corresponding
     *     messages (or no messages for the default locale), or rejects if none of the provided
     *     locales can be loaded.
     */
    private loadLocale(locales: string[], defaultLocale: string): Promise<LoadedLocale> {
        return locales.reduce((promise, locale) => {
            return promise.catch(() => {
                if (locale === defaultLocale) {
                    return { locale, undefined };
                }
                return import(`./i18n/${locale}.json`).then(messages => {
                    return { locale, messages };
                });
            });
        }, Promise.reject() as Promise<LoadedLocale>);
    }

    /**
     * Load message overrides for a locale
     *
     * @param locale
     *     The locale to load overrides for.
     * @param path
     *     The path relative to the overrides folder to load overrides from,
     *     or null if no overrides should be loaded.
     * @returns
     *     A promise that resolves to an object containing all override messages,
     *     or an empty object if no overrides exist or cannot be loaded.
     */
    private loadOverrides(locale: string, path: string | null): Promise<{ [key: string]: string }> {
        if (!path) {
            return Promise.resolve({});
        }
        return import(`./i18n/overrides/${path}/${locale}.json`).catch(() => {});
    }

    /**
     * Parse a locale string
     *
     * @param locale
     *    The locale string to parse.
     * @returns
     *    An object containing the language and optional region of the locale, or null if the string cannot
     *    be parsed. Supports 3-part locale strings (e.g. zh-Hant-CN for traditional Chinese in China).
     */
    private parseLocale(locale: string): {language: string, region?: string} | null {
        const parts = locale.match(/[^-]+/g);

        if (parts === null) {
            return null;
        }

        if (parts.length === 1) {
            return { language: parts[0] };
        }

        return {
            region: parts.pop(),
            language: parts.join('-')
        };
    }

    /**
     * Get the stored locale
     *
     * @return
     *     The stored locale, or null if no locale is stored.
     */
    private get storedLocale(): string | null {
        return LocalStorage.getItem('locale');
    }

    /**
     * Set the stored locale
     *
     * @param locale
     *     The locale to store, or null to clear the stored locale.
     */
    private set storedLocale(locale: string | null) {
        LocalStorage.setItem('locale', locale);
    }

}
