import {Player} from "./Player";
import React from "react";
import {PresetModel} from "../../../shared/models/PresetModel";
import { DialogPage } from "../../../shared/DialogPage";
import {Outlet, Route, Routes, useNavigate} from "react-router-dom";
import {ErrorPage} from "../../../error/ErrorPage";
import {Editor} from "../../shared/Editor";
import {PinturaImageState} from "@pqina/pintura";
import {DialogAppBar} from "../../../shared/DialogAppBar";
import {FormattedMessage} from "react-intl";
import {Box, Button} from "@mui/material";
import {DialogFooter} from "../../../shared/DialogFooter";
import {Hint} from "../../../shared/Hint";

interface PreviewProps {
    videoData: Blob;
    originalVideoData: Blob;
    preset: PresetModel;
    maxDuration?: number|null;
    editorState: PinturaImageState|null;
    onPlay: () => void;
    onEdited: (data: Blob, editorState: PinturaImageState) => void;
    onWarnMaxDuration?: (duration: number, maxDuration: number) => void;
    onNext: () => void;
    onBack: () => void;
}

export function Preview({
    videoData,
    originalVideoData,
    preset,
    onPlay,
    maxDuration = null,
    editorState,
    onWarnMaxDuration,
    onEdited,
    onNext,
    onBack
}: PreviewProps) {
    const navigate = useNavigate();

    const handleEdit = () => {
        navigate('edit');
    };

    const handleEditClose = () => {
        navigate(-1);
    };

    const handleEditDone = (data: Blob, editorState: PinturaImageState) => {
        onEdited(data, editorState);
        navigate(-1);
    };

    const actions = [
        <Button onClick={handleEdit}>
            <FormattedMessage
                id="shared.edit"
                description="Shared - Edit."
                defaultMessage="Edit"
            />
        </Button>
    ];

    const appBar =
        <DialogAppBar
            title={<FormattedMessage
                id="record.media.preview.title"
                description="Record page - Media - Preview - Title text."
                defaultMessage="Check your video"
            />}
            actions={actions}
            canBack={true}
            onBack={onBack}
        />;

    const footer =
        <DialogFooter
            onNext={onNext}
        />;

    const page =
        <DialogPage
            appBar={appBar}
            footer={footer}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <Hint
                    text={
                        <FormattedMessage
                            id="record.media.preview.hint"
                            description="Record page - Select media - Preview - Hint"
                            defaultMessage="This is how your video will appear on the card. Verify that it is correct, and edit if needed."
                        />
                    }
                />
                <Box sx={{ flexGrow: 1}}>
                    <Player
                        source={videoData}
                        preset={preset}
                        maxDuration={maxDuration}
                        onWarnMaxDuration={onWarnMaxDuration}
                        onPlay={onPlay}
                    />
                </Box>
            </Box>
        </DialogPage>;

    const edit =
        <Editor type="video"
                preset={preset}
                source={originalVideoData}
                maxDuration={maxDuration}
                state={editorState}
                onClose={handleEditClose}
                onDone={handleEditDone}
        />;

    return (
        <Routes>
            <Route path="/" element={ <Outlet /> }>
                <Route index element={ page } />
                <Route path="edit" element={ edit } />
                <Route path="*" element={ <ErrorPage /> } />
            </Route>
        </Routes>
    );
}
