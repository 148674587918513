import React, {Component} from "react";
import PropTypes from "prop-types";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText} from "@mui/material";
import {FormattedMessage} from "react-intl";


export class CloseDialog extends Component {

    render() {
        return (
            <Dialog open={this.props.isOpen}
                    onClose={this.props.onCancel}
                    aria-describedby="close-dialog-description">
                <DialogContent>
                    <DialogContentText id="close-dialog-description">
                        <FormattedMessage
                            id="record.shared.close-dialog.text"
                            description="Record page - Close Dialog - Text."
                            defaultMessage="Close and discard selected media?"
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.onCancel} autoFocus>
                        <FormattedMessage
                            id="record.shared.close-dialog.cancel"
                            description="Record page - Close Dialog - Cancel button text."
                            defaultMessage="Cancel"
                        />
                    </Button>
                    <Button onClick={this.props.onConfirm}>
                        <FormattedMessage
                            id="record.shared.close-dialog.confirm"
                            description="Record page - Close Dialog - Confirm button text."
                            defaultMessage="Confirm"
                        />
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

}

CloseDialog.propTypes = {
    isOpen: PropTypes.bool,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
}
