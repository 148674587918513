import {Component} from "react";

import './RecordButton.scss';
import PropTypes from "prop-types";

export class RecordButton extends Component {

    render() {
        return (
          <button className="record-button" onClick={this.props.onClick}>
              <div className="record-button-progress" style={{"--progress": `${this.props.progress * 100}%`}}>
                  <div className={"record-button-icon" + (this.props.isRecording ? " recording" : "")} />
              </div>
          </button>
        );
    }

}

RecordButton.propTypes = {
    isRecording: PropTypes.bool,
    progress: PropTypes.number,
    onClick: PropTypes.func
};
