import React, {Component} from "react";
import PropTypes from "prop-types";

import {UploadManager} from "../shared/UploadManager";
import {LinearProgress} from "@mui/material";


export class UploadProgress extends Component {

    constructor(props) {
        super(props);

        this.handleProgress = this.handleProgress.bind(this);

        this.state = {
            progress: this.props.uploadManager.getProgress()
        };
    }

    componentDidMount() {
        this.props.uploadManager.addEventListener('progress', this.handleProgress);
    }

    componentWillUnmount() {
        this.props.uploadManager.removeEventListener('progress', this.handleProgress);
    }

    render() {
        const progress = Math.round(100 * this.state.progress.bytesSent / this.state.progress.bytesTotal);
        return <LinearProgress sx={{ width: '50%'}} variant="determinate" value={progress} />
    }

    handleProgress(event) {
        this.setState({ progress: event.detail });
    }

}

UploadProgress.propTypes = {
    uploadManager: PropTypes.instanceOf(UploadManager).isRequired
};
