import {useIntl} from "react-intl";

import "./ZapparFrame.scss";

interface ZapparFrameProps {
    apiBaseUrl?: string;
    accessToken: string;
    albumId?: string;
    videoId?: string;
    resourceKey?: string;
}

export function ZapparFrame(props: ZapparFrameProps) {
    const intl = useIntl();

    const projectHost = process.env.REACT_APP_ZAPPAR_HOST;
    const projectId = process.env.REACT_APP_ZAPPAR_PROJECT_ID;
    const projectVersion = process.env.REACT_APP_ZAPPAR_PROJECT_VERSION;
    const projectUrl = new URL(`${projectHost}/${projectId}/${projectVersion}/`);

    projectUrl.searchParams.set('l', intl.locale);
    projectUrl.searchParams.set('t', props.accessToken);

    if (props.apiBaseUrl) {
        projectUrl.searchParams.set('b', props.apiBaseUrl);
    }

    if (props.albumId) {
        projectUrl.searchParams.set('a', props.albumId);
    }

    if (props.videoId) {
        projectUrl.searchParams.set('v', props.videoId);
    }

    if (props.resourceKey) {
        projectUrl.searchParams.set('k', props.resourceKey);
    }

    return (
        <iframe className="play-container"
                title="AR"
                allow="camera;accelerometer;gyroscope;magnetometer;autoplay;fullscreen"
                src={projectUrl.toString()}
        />
    );
}
