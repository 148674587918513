import React, {ReactElement, ReactNode} from "react";
import { Box } from "@mui/material";


interface DialogPageProps {
    appBar?: ReactElement;
    footer?: ReactElement;
    children: ReactNode;
}

export function DialogPage({ appBar, footer, children }: DialogPageProps) {
    return (
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            {appBar}
            <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
                {children}
            </Box>
            {footer}
        </Box>
    )
}
