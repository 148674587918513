import React from "react";
import { FormattedMessage} from "react-intl";
import {Outlet, Route, Routes, useNavigate} from "react-router-dom";
import {PresetModel} from "../../../shared/models/PresetModel";
import {GA4} from "../../../shared/GA4";
import {FrameSelector} from "./FrameSelector";
import {ErrorPage} from "../../../error/ErrorPage";
import {useRecordDispatch, useRecordState} from "../../../shared/reducers/RecordStateProvider";
import {PinturaImageState} from "@pqina/pintura";
import { DialogPage } from "../../../shared/DialogPage";
import { DialogAppBar } from "../../../shared/DialogAppBar";
import { Preview } from "../shared/Preview";


interface StillProps {
    videoData: Blob;
    preset: PresetModel;
    onAccept: (data: Blob) => void;
}

export function Still({
    videoData,
    preset,
    onAccept
}: StillProps) {

    const state = useRecordState();
    const dispatch = useRecordDispatch();

    const position = state.image.still.position;
    const imageData = state.image.still.data;
    const originalImageData = state.image.still.originalData;
    const editorState = state.image.still.editorState;

    const navigate = useNavigate();

    const handleSelected = (data: Blob, position: number) => {
        GA4.sendEvent('record_image_still_select');
        dispatch( { type: 'setImageStillOriginalData', payload: { data, position } });
        navigate('preview');
    };

    const handleEdited = (data: Blob, editorState: PinturaImageState) => {
        dispatch( { type: 'setImageStillEditedData', payload: { data, editorState } });
    };

    const handleNext = () => {
        if (imageData) {
            onAccept(imageData);
        }
    };

    const handleBack = () => {
        navigate(-1);
    };

    const preview = imageData && originalImageData &&
        <Preview
            imageData={imageData}
            originalImageData={originalImageData}
            preset={preset}
            editorState={editorState}
            onEdited={handleEdited}
            onNext={handleNext}
            onBack={handleBack}
        />;

    const appBar =
        <DialogAppBar
            title={<FormattedMessage
                id="record.image.still.title"
                description="Record page - Image - Still - Title text."
                defaultMessage="Select from video"
            />}
            canBack={true}
            onBack={handleBack}
        />;

    const page =
        <DialogPage appBar={appBar}>
            <FrameSelector
                initialPosition={position}
                videoData={videoData}
                preset={preset}
                onSelected={handleSelected}
            />
        </DialogPage>;

    return (
        <Routes>
            <Route path="/" element={ <Outlet /> }>
                <Route index element={ page } />
                <Route path="preview/*" element={ preview } />
                <Route path="*" element={ <ErrorPage /> } />
            </Route>
        </Routes>
    );
}
