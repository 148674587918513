import {DialogPage} from "../../shared/DialogPage";
import {useHasCamera} from "./camera/useHasCamera";
import {FormattedMessage} from "react-intl";
import React, {ReactElement, useRef} from "react";
import {Stack} from "@mui/material";
import {NoSource} from "./NoSource";
import { SourceCard } from "../shared/SourceCard";
import {FileSelector} from "../shared/FileSelector";
import {GA4} from "../../shared/GA4";
import {Outlet, Route, Routes, useNavigate} from "react-router-dom";
import {ErrorPage} from "../../error/ErrorPage";
import {Camera} from "./camera/Camera";
import {PresetModel} from "../../shared/models/PresetModel";
import {Mobile} from "./mobile/Mobile";
import {VideoModel} from "../../shared/models/VideoModel";
import {File} from "./file/File";
import {useRecordDispatch} from "../../shared/reducers/RecordStateProvider";
import {DialogAppBar} from "../../shared/DialogAppBar";
import {Hint} from "../../shared/Hint";


const recorderSettings = {
    mimeTypes: [
        'video/mp4',
        'video/webm'
    ],
    sourceConstraints: {
        audio: {
            sampleSize: 16,
            sampleRate: 44100,
            channelCount: 1,
            autoGainControl: true,  // Fixes extremely low recording volume on some devices (also without the noiseSuppression constraint)
            noiseSuppression: true  // Fixes extremely low recording volume on some devices (also without the autoGainControl constraint)
        },
        video: {
            width: { min: 320, ideal: 1280, max: 1280 },
            height: { min: 320, ideal: 720, max: 720 }
        }
    },
    audioBitsPerSecond: 128000,
    videoBitsPerSecond: 8000000
};

interface MediaProps {
    video: VideoModel;
    mediaSources: string[];
    imageSources: string[];
    maxSize?: number;
    maxDuration: number;
    minResolution?: {
        width: number;
        height: number;
    },
    preset: PresetModel;
    accessToken: string;
    imageSelectable: boolean;
    canClose: boolean;
    onClose: () => void;
    onAccept: (data: Blob) => void;
    onDone: (video: VideoModel) => void;
}

export function Media({
    video,
    mediaSources,
    imageSources,
    maxSize = 512 * 1024 * 1024,
    maxDuration,
    minResolution = { width: 320, height: 320 },
    preset,
    accessToken,
    imageSelectable,
    canClose,
    onClose,
    onAccept,
    onDone
}: MediaProps) {

    const dispatch = useRecordDispatch();
    const navigate = useNavigate();
    const hasCamera = useHasCamera(recorderSettings.mimeTypes);
    const fileSelectorRef = useRef<FileSelector>(null);

    const handleCameraClick = () => {
        GA4.sendEvent('record_media_source_select', { source: 'camera' });
        navigate('camera');
    };

    const handleFileClick = () => {
        GA4.sendEvent('record_media_source_select', { source: 'file' });
        fileSelectorRef.current?.open();
    };

    const handleMobileClick = () => {
        GA4.sendEvent('record_media_source_select', { source: 'mobile' });
        navigate('mobile');
    };

    const handleFileOpened = () => {
        GA4.sendEvent('record_media_file_open');
    };

    const handleFileSelected = (data: Blob) => {
        dispatch({ type: 'setMediaFileOriginalData', payload: data });
        GA4.sendEvent('record_media_file_select');
        navigate('file');
    };

    const handleFileError = (error: DOMException) => {
        GA4.sendEvent('record_media_file_error', { error_name: error.name });
    };

    const handleAccept = (data: Blob) => {
        GA4.sendEvent('record_media_next');
        onAccept(data);
    };

    if (hasCamera === null) {
        return null;  // TODO: loading spinner while searching for camera
    }

    const cards: {[key: string]: ReactElement | false} = {
        'camera': hasCamera &&
            <SourceCard
                key='camera'
                image='/img/videocam.svg'
                title={
                    <FormattedMessage
                        id="record.media.btn-camera.title"
                        description="Record page - Select video - Camera button - Title."
                        defaultMessage="Record a video"
                    />
                }
                text={
                    <FormattedMessage
                        id="record.media.btn-camera.text"
                        description="Record page - Select video - Camera button - Text."
                        defaultMessage="Use this device to record a video now."
                    />
                }
                onClick={handleCameraClick}
            />,

        'file':
            <SourceCard
                key='file'
                image='/img/upload.svg'
                title={
                    <FormattedMessage
                        id="record.media.btn-file.title"
                        description="Record page - Select video - File button - Title."
                        defaultMessage="Upload a video"
                    />
                }
                text={
                    <FormattedMessage
                        id="record.media.btn-file.text"
                        description="Record page - Select video - File button - Text."
                        defaultMessage="Use a video that is stored on this device."
                    />
                }
                onClick={handleFileClick}
            />,

        'mobile':
            <SourceCard
                key='mobile'
                image='/img/devices.svg'
                title={
                    <FormattedMessage
                        id="record.media.btn-mobile.title"
                        description="Record page - Select video - Mobile button - Title."
                        defaultMessage="Use another device"
                    />
                }
                text={
                    <FormattedMessage
                        id="record.media.btn-mobile.text"
                        description="Record page - Select video - Mobile button - Text."
                        defaultMessage="Use a video that is stored on another device."
                    />
                }
                onClick={handleMobileClick}
            />
    };

    const filteredCards = mediaSources
        .map(mediaSource => cards[mediaSource])
        .filter(Boolean);

    if (!filteredCards.length) {
        return <NoSource />;
    }

    const appBar =
        <DialogAppBar
            title={
                <FormattedMessage
                    id="record.media.title"
                    description="Record page - Select video - Title text"
                    defaultMessage="Select a video"
                />
            }
            canClose={canClose}
            onClose={onClose}
        />;

    const page =
        <DialogPage appBar={appBar}>
            <Hint text={
                <FormattedMessage
                    id="record.media.instruction"
                    description="Record page - Select video - Hint"
                    defaultMessage="Select the video that will play on your card. How would you like to provide the video?"
                />
            }/>
            <Stack spacing={2} sx={{p: 2}}>
                { filteredCards }
            </Stack>
            <FileSelector
                ref={fileSelectorRef}
                type="video"
                maxSize={maxSize}
                minResolution={minResolution}
                onOpen={handleFileOpened}
                onSelected={handleFileSelected}
                onError={handleFileError}
            />
        </DialogPage>;

    const camera =
        <Camera
            recorderSettings={recorderSettings}
            maxDuration={maxDuration}
            preset={preset}
            onAccept={handleAccept}
        />;

    const file =
        <File
            maxDuration={maxDuration}
            preset={preset}
            onAccept={handleAccept}
        />;

    const mobile =
        <Mobile
            video={video}
            accessToken={accessToken}
            imageSelectable={imageSelectable}
            mediaSources={mediaSources}
            imageSources={imageSources}
            onReady={onDone}
        />;

    return (
        <Routes>
            <Route path="/" element={ <Outlet /> }>
                <Route index element={ page } />
                <Route path="camera/*" element={ camera } />
                <Route path="file/*" element={ file } />
                <Route path="mobile/*" element={ mobile }/>
                <Route path="*" element={ <ErrorPage /> } />
            </Route>
        </Routes>
    );
}
