import React, {ReactElement, ReactNode} from "react";
import {AppBar, IconButton, Tab, Tabs, Toolbar, Typography} from "@mui/material";
import {styled} from "@mui/system";

import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

/**
 *
 * An app bar that can be used for dialogs (including a close button and tabs).
 *
 * We use styled components to allow the AppBar and Tabs to be styled specifically for dialogs, i.e. without
 * affecting the styling of app bars and tabs used elsewhere. Example themed styling:
 *
 * ```
 * "components": {
 *     "DialogAppBar": {
 *         "styleOverrides": {
 *             "root": {
 *                 "color": "#3e4551",
 *                 "backgroundColor": "#f4f4f4",
 *                 "boxShadow": "none",
 *                 "borderBottom": "1px solid rgba(0,0,0,.05)",
 *             },
 *             "tabs": {
 *                 "& .MuiTabs-indicator": {
 *                     "backgroundColor": "#3e4551"
 *                 }
 *             }
 *         }
 *     },
 *     ...
 * ```
 */

interface DialogAppBarProps {
    title?: ReactNode;
    canBack?: boolean;
    canClose?: boolean;
    actions?: ReactElement[],
    tabs?: {
        label: ReactNode;
        value: any;
    }[];
    activeTab?: number | string | null;
    onTabChange?: (event: React.SyntheticEvent, newValue: any) => void;
    onBack?: () => void;
    onClose?: () => void;
}

const DialogAppBarRoot = styled(AppBar, {
    name: 'DialogAppBar',
    slot: 'Root',
    overridesResolver: (props, styles) => styles.root
})(({ theme }) => ({
    zIndex: (theme.zIndex as {[key: string]: number }).appBar
}));

const DialogAppBarTabs = styled(Tabs, {
    name: 'DialogAppBar',
    slot: 'Tabs',
    overridesResolver: (props, styles) => styles.tabs
})(({ theme }) => ({
    '& .MuiTabs-indicator': {
        backgroundColor: theme.palette.primary.contrastText
    }
}));

export function DialogAppBar(props: DialogAppBarProps) {
    return (
        <DialogAppBarRoot
            position="relative"
        >
            <Toolbar>
                {props.canClose && !props.canBack &&
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="close"
                        sx={{ mr: 1 }}
                        onClick={props.onClose}
                    >
                        <CloseIcon/>
                    </IconButton>
                }

                {props.canBack &&
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="close"
                        sx={{ mr: 1 }}
                        onClick={props.onBack}
                    >
                        <ArrowBackIcon/>
                    </IconButton>
                }

                <Typography
                    variant="h6"
                    component="div"
                    sx={{ flexGrow: 1 }}
                >
                    {props.title}
                </Typography>

                {props.actions?.map(
                    (component, index) => React.cloneElement(component, {
                        key: index,
                        color: "inherit"
                    })
                )}
            </Toolbar>

            {!!props.tabs?.length && (
                <Toolbar
                    variant="dense"
                    disableGutters={true}
                >
                    <DialogAppBarTabs
                        sx={{ flexGrow: 1 }}
                        variant="fullWidth"
                        textColor="inherit"
                        value={props.activeTab ?? false}
                        onChange={props.onTabChange}
                    >
                        {props.tabs.map(tab =>
                            <Tab
                                key={tab.value}
                                label={tab.label}
                                value={tab.value}
                            />
                        )}
                    </DialogAppBarTabs>
                </Toolbar>
            )}

        </DialogAppBarRoot>
    );
}
