import '@pqina/pintura/pintura.css';
import '@pqina/pintura-video/pinturavideo.css';
import './Editor.scss';

import {
    createDefaultImageReader,
    createDefaultImageWriter,
    setPlugins,
    plugin_crop,
    imageStateToCanvas,
    PinturaEditorDefaultOptions,
    PinturaDefaultImageReaderResult,
    PinturaImageState,
    Shape
} from "@pqina/pintura"

import {
    createDefaultVideoWriter,
    createMediaStreamEncoder,
    plugin_trim,
} from "@pqina/pintura-video";

import React, {useEffect, useRef, useState} from "react";
import {FormattedMessage} from "react-intl";
import {Button} from "@mui/material";
import {PinturaEditor} from '@pqina/react-pintura';

import {useEditorLocale} from "./useEditorLocale";
import {PresetModel} from "../../shared/models/PresetModel";
import {FullscreenDialog} from "../../shared/FullscreenDialog";
import {WebMContainer} from "../media/camera/WebMContainer";
import {AlertDialog} from "../media/shared/AlertDialog";

setPlugins(plugin_trim, plugin_crop);

interface EditorProps {
    type: 'image' | 'video';
    source: Blob | string;
    preset: PresetModel;
    maxDuration?: number | null;
    state?: PinturaImageState | null;
    onDone: (data: Blob, state: PinturaImageState) => void;
    onClose: () => void;
}

export function Editor(props: EditorProps) {
    const [editorConfig, setEditorConfig] = useState<PinturaEditorDefaultOptions>();
    const [mask, setMask] = useState<string | null>(null);
    const [overlay, setOverlay] = useState<string | null>(null);
    const [isAlertingMaxDuration, setIsAlertingMaxDuration] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const editorRef = useRef<PinturaEditor>(null);
    const durationRef = useRef<number>();
    const locale = useEditorLocale(editorConfig?.utils); // FIXME: prevent duplicate load

    useEffect(() => {
        const aspectRatio = props.preset.width / props.preset.height;
        if (props.type === 'image') {
            setEditorConfig(createImageEditorConfig(aspectRatio));
        } else {
            setEditorConfig(createVideoEditorConfig(aspectRatio));
        }
    }, [props.type, props.preset]);

    useEffect(() => {
        if (props.preset.clipping_mask) {
            createOverlay(props.preset.clipping_mask, '#fff').then(setMask);
        } else {
            setMask(null);
        }
    }, [props.preset.clipping_mask]);

    useEffect(() => {
        if (props.preset.overlay) {
            createOverlay(props.preset.overlay).then(setOverlay);
        } else {
            setOverlay(null);
        }
    }, [props.preset.overlay]);

    const handleWillSetMediaInitialTimeOffset = (duration: number, trim: [number, number][]): number => {
        // Initialize the time slider to the start of the first trim segment
        return trim[0][0] * duration;
    };

    // @see https://pqina.nl/pintura/docs/v8/examples/circular-crop-overlay/
    const handleWillRenderCanvas = (shapes: { interfaceShapes: Shape[], decorationShapes: Shape[], annotationShapes: Shape[] }, state: { utilVisibility: any; selectionRect: any; lineColor: any; backgroundColor: any; }) => {
        const { selectionRect, utilVisibility } = state;

        if ((!utilVisibility.crop && !utilVisibility.trim) || (!props.preset.overlay && !mask)) {
            return shapes;
        }

        const interfaceShapes: Shape[] = [];

        if (overlay) {
            interfaceShapes.push({
                ...selectionRect,
                backgroundImage: overlay,
            });
        }

        if (mask) {
            interfaceShapes.push( {
                ...selectionRect,
                backgroundImage: mask,
                opacity: utilVisibility.crop ? 0.8 : 1.0
            });
        }

        return {
            ...shapes,
            interfaceShapes: [
                ...interfaceShapes,
                ...shapes.interfaceShapes
            ]
        };
    }

    const exceedsMaxDuration = (): boolean => {
        if (!props.maxDuration || !durationRef.current || !editorRef.current) {
            return false;
        }

        const segments: number[][] = editorRef.current.editor.imageState?.trim || [[0, 1]];

        const relDuration = segments.reduce((total, segment) => {
            return total + (segment[1] - segment[0]);
        }, 0);

        return relDuration * durationRef.current > props.maxDuration;
    };

    const makeSeekable = (data: Blob): Promise<Blob> => {
        const container = new WebMContainer(data);
        return container.makeSeekable().then(() => container.getBlob());
    };

    const handleSave = async () => {
        if (exceedsMaxDuration()) {
            setIsAlertingMaxDuration(true);
            return;
        }

        try {
            setIsProcessing(true);

            const result = await editorRef.current?.editor.processImage();

            // Result is undefined if processing is aborted
            if (!result) return;

            // Make WebM videos seekable
            if (result.dest.type === "video/webm") {
                props.onDone(await makeSeekable(result.dest), result.imageState);
            } else {
                props.onDone(result.dest, result.imageState);
            }
        } catch(error: any) {
            // Pintura will show an error message
            if (error.name !== 'AbortError') {
                console.error(error);
            }
        } finally {
            setIsProcessing(false);
        }
    };

    const handleDismissMaxDuration = () => {
        setIsAlertingMaxDuration(false);
    };

    const handleLoadstart = () => {
        setIsLoading(true);
    };

    const handleLoad = ({ duration }: PinturaDefaultImageReaderResult) => {
        setIsLoading(false);

        durationRef.current = duration;

        if (editorRef.current && props.maxDuration && (duration > props.maxDuration)) {
            editorRef.current.editor.imageState = {
                ...editorRef.current.editor.imageState,
                trim: [[0, props.maxDuration / duration]]
            };
        }
    };

    const handleClose = () => {
        if (editorRef.current && isProcessing) {
            editorRef.current.editor.abortProcessImage();
        }

        props.onClose();
    };

    const actions = [
        <Button onClick={handleSave} disabled={isLoading || isProcessing}>
            <FormattedMessage
                id="shared.save"
                description="Shared - Save."
                defaultMessage="Save"
            />
        </Button>
    ];

    return (
        <FullscreenDialog
            open={true}
            actions={actions}
            onClose={handleClose}
        >
            <AlertDialog
                isOpen={isAlertingMaxDuration}
                onDismiss={handleDismissMaxDuration}
                message={
                    <FormattedMessage
                        id="record.shared.editor.alert-max-duration"
                        description="Record page - Shared - Editor - Video too long."
                        defaultMessage="The duration of the edited video cannot exceed {minutes}:{seconds, number, ::00}."
                        values={{
                            minutes: Math.floor(props.maxDuration! / 60) % 60,
                            seconds: Math.floor(props.maxDuration!) % 60
                        }}
                    />
                }
            />

            <PinturaEditor
                ref={editorRef}
                locale={locale}
                src={props.source}
                enableToolbar={false}
                muteAudio={false}
                {...editorConfig}
                imageState={props.state ?? undefined}
                willSetMediaInitialTimeOffset={handleWillSetMediaInitialTimeOffset}
                willRenderCanvas={handleWillRenderCanvas}
                onLoadstart={handleLoadstart}
                onLoad={handleLoad}
            />
        </FullscreenDialog>
    );
}

function createImageEditorConfig(aspectRatio: number): PinturaEditorDefaultOptions {
    return {
        utils: ['crop'],
        imageCropAspectRatio: aspectRatio,
        imageReader: createDefaultImageReader(),
        imageWriter: createDefaultImageWriter()
    };
}

function createVideoEditorConfig(aspectRatio: number): PinturaEditorDefaultOptions {
    const maxHeight = aspectRatio > 1 ? 720 : 1280;
    const maxWidth  = aspectRatio > 1 ? 1280 : 720;

    const targetHeight = aspectRatio * maxHeight > maxWidth ? maxWidth / aspectRatio : maxHeight;
    const targetWidth = aspectRatio * targetHeight;

    return {
        utils: ['trim', 'crop'],
        imageCropAspectRatio: aspectRatio,
        imageReader: createDefaultImageReader(),
        imageWriter: createDefaultVideoWriter({
            targetSize: {
                width: 2 * Math.ceil(targetWidth / 2),
                height: 2 * Math.ceil(targetHeight / 2)
            },
            encoder: createMediaStreamEncoder({
                imageStateToCanvas,
                framesPerSecond: 24,
                audioBitrate: 128000,
                videoBitrate: 8000000
            })
        })
    };
}

async function createOverlay(src: string, fillStyle?: string): Promise<string> {
    const image = await fetchImage(src);

    const canvas = document.createElement('canvas');
    canvas.width = image.naturalWidth;
    canvas.height = image.naturalHeight;

    const context = canvas.getContext('2d');
    if (!context) {
        throw new Error("Could not create canvas 2D context");
    }

    context.drawImage(image, 0, 0);

    if (fillStyle) {
        context.globalCompositeOperation = 'source-out';
        context.fillStyle = fillStyle;
        context.fillRect(0, 0, canvas.width, canvas.height);
    }

    return canvas.toDataURL();
}

async function fetchImage(src: string): Promise<HTMLImageElement> {

    /*
     * Images must be retrieved using CORS to draw them onto a canvas. However, if the image is retrieved earlier
     * without CORS (e.g. as a CSS background-image in the <Mask> component), then several browsers (macOS
     * Chrome/Safari/Opera, Android Chrome/Samsung Internet/Opera, iOS Chrome/Opera) will return the cached response
     * without an Access-Control-Allow-Origin set. This results in failure to load the image here.
     *
     * We cannot just add a query parameter to the URL to bypass the cache, because the overlay and mask images use
     * pre-signed S3 URLs. Instead, we use fetch() to retrieve the image with CORS and with busting the cache (but
     * updating the cache once retrieved). We then load the retrieved data into an Image element.
     */

    const response = await fetch(src,  { cache: 'reload' });
    const url = URL.createObjectURL(await response.blob());

    return new Promise((accept, reject) => {
        const image = new Image();

        image.onload = () => {
            URL.revokeObjectURL(url);
            accept(image);
        };

        image.onerror = () => {
            URL.revokeObjectURL(url);
            reject(new Error("Could not load image"));
        }

        image.crossOrigin = "anonymous";
        image.src = url;
    });
}
