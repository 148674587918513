import React, {ReactNode} from "react";
import {FormattedMessage} from "react-intl";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText} from "@mui/material";


interface AlertDialogProps {
    isOpen: boolean;
    message: ReactNode;
    onDismiss: () => void;
}

export function AlertDialog(props: AlertDialogProps) {
    return (
        <Dialog open={props.isOpen}
                onClose={props.onDismiss}
                aria-describedby="alert-description">
            <DialogContent>
                <DialogContentText id="alert-description">
                    {props.message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onDismiss}>
                    <FormattedMessage
                        id="shared.ok"
                        description="OK button text."
                        defaultMessage="OK"
                    />
                </Button>
            </DialogActions>
        </Dialog>
    );
}
