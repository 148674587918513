import {Dialog, Slide} from "@mui/material";
import React, {ReactElement, ReactNode} from "react";
import {TransitionProps} from "@mui/material/transitions";
import {DialogAppBar} from "./DialogAppBar";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />
});

interface FullscreenDialogProps {
    open: boolean;
    onClose: () => void;
    children?: ReactNode;
    actions?: ReactElement[];
}

export function FullscreenDialog(props: FullscreenDialogProps) {
    return (
        <Dialog
            fullScreen
            open={props.open}
            TransitionComponent={Transition}
        >
            <DialogAppBar
                canClose={true}
                actions={props.actions}
                onClose={props.onClose}
            />
            {props.children}
        </Dialog>
    );
}
