import React, {Component, RefObject} from "react";
import {VideoError} from "./VideoError";

/**
 *
 * Wrapper for a HTML5 video element, with some additional features:
 *
 * - cross-browser support for URL, Blob and MediaStream sources
 * - constrained end time
 * - seek, play (cross-browser), pause and stop methods
 * - frame mirroring
 * - frame grabbing
 */

interface Metadata {
    duration: number;
    width: number;
    height: number;
}

interface VideoProps {
    src: string | Blob | MediaStream | null
    poster?: string | Blob;
    mirrored?: boolean;
    preload?: 'auto' | 'metadata' | 'none';
    autoPlay?: boolean;
    muted?: boolean;
    endTime?: number;
    objectFit?: 'cover' | 'contain';
    onLoadedMetadata?: (metadata: Metadata) => void;
    onPlay?: () => void;
    onSeeking?: () => void;
    onSeeked?: () => void;
    onEnded?: () => void;
}

interface VideoState {
    poster: string | null;
    error: MediaError | null;
}

export class Video extends Component<VideoProps, VideoState> {

    private elementRef: RefObject<HTMLVideoElement>;
    private srcObjectUrl: string | null;
    private posterObjectUrl: string | null;

    constructor(props: VideoProps) {
        super(props);

        this.handleLoadedMetadata = this.handleLoadedMetadata.bind(this);
        this.handleTimeUpdate = this.handleTimeUpdate.bind(this);
        this.handleError = this.handleError.bind(this);

        this.state = {
            poster: null,
            error: null
        };

        this.elementRef = React.createRef();
        this.srcObjectUrl = null;
        this.posterObjectUrl = null;
    }

    componentDidMount() {
        this.updateSource();
        this.updatePoster();
    }

    componentWillUnmount() {
        this.unsetSource();

        if (this.posterObjectUrl) {
            URL.revokeObjectURL(this.posterObjectUrl);
        }
    }

    componentDidUpdate(prevProps: VideoProps) {
        if (prevProps.src !== this.props.src) {
            this.updateSource();
        }
        if (prevProps.poster !== this.props.poster) {
            this.updatePoster();
        }
    }

    private updateSource() {
        this.unsetSource();
        this.setSource(this.props.src);
    }

    private setSource(src: string | Blob | MediaStream | null) {

        /*
         * Safari on macOS sometimes does not render the video if the timing of setting the video sources
         * is off. Therefore, set the source element or srcObject attribute directly instead of via a state change.
         *
         * iOS 17.4 does not automatically load the first video frame if the video src attribute is set to a URL created
         * with URL.createObjectURL(). Instead, a <source> child element must be used, which must have both the src and
         * type attributes set.
         */

        const video = this.elementRef.current;
        if (video === null) {
            return;
        }

        if (src instanceof Blob) {
            const source = document.createElement('source');
            source.src = this.srcObjectUrl = URL.createObjectURL(src);
            source.type = src.type;
            video.appendChild(source);
        } else if (src instanceof MediaStream) {
            video.srcObject = src;
        } else if (src) {
            video.setAttribute('src', src);
        }

        video.load();
    }

    private unsetSource() {
        const video = this.elementRef.current;
        if (video === null) {
            return;
        }

        // @see https://html.spec.whatwg.org/multipage/media.html#best-practices-for-authors-using-media-elements
        video.pause();
        video.querySelector('source')?.remove();
        video.srcObject = null;
        video.removeAttribute('src');
        video.load();

        if (this.srcObjectUrl) {
            URL.revokeObjectURL(this.srcObjectUrl);
            this.srcObjectUrl = null;
        }
    }

    private updatePoster() {
        if (this.posterObjectUrl) {
            URL.revokeObjectURL(this.posterObjectUrl);
            this.posterObjectUrl = null;
        }

        let poster = this.props.poster ?? null;
        if (poster instanceof Blob) {
            this.posterObjectUrl = URL.createObjectURL(poster);
            poster = this.posterObjectUrl;
        }

        this.setState({ poster });
    }

    render() {
        if (this.state.error) {
            return <VideoError error={this.state.error} />;
        }

        const style = {
            width: '100%',
            height: '100%',
            objectFit: this.props.objectFit,
            transform: this.props.mirrored ? 'scaleX(-1)' : undefined
        };

        return (
            <video style={style}
                   ref={this.elementRef}
                   poster={this.state.poster ?? undefined}
                   playsInline={true}
                   disableRemotePlayback={true}
                   disablePictureInPicture={true}
                   preload={this.props.preload ?? 'auto'}
                   autoPlay={this.props.autoPlay}
                   muted={this.props.muted}
                   onLoadedMetadata={this.handleLoadedMetadata}
                   onPlay={this.props.onPlay}
                   onSeeking={this.props.onSeeking}
                   onSeeked={this.props.onSeeked}
                   onTimeUpdate={this.handleTimeUpdate}
                   onEnded={this.props.onEnded}
                   onError={this.handleError}
            />
        )
    }

    private handleLoadedMetadata() {
        const video = this.elementRef.current;

        if (video) {
            this.props.onLoadedMetadata?.({
                duration: video.duration,
                width: video.videoWidth,
                height: video.videoHeight
            });
        }
    }

    private handleTimeUpdate() {
        const video = this.elementRef.current;
        const endTime = this.props.endTime;

        if (endTime && video && (video.currentTime >= endTime)) {
            video.pause();
            this.props.onEnded?.();
        }
    }

    private handleError() {
        this.setState({
            error: this.elementRef.current?.error ?? null
        });
    }

    public seek(time: number) {
        const video = this.elementRef.current;

        if (video) {
            video.currentTime = time;
        }
    }

    public async play() {
        const video = this.elementRef.current;

        if (video) {
            await video.play();
            // Seek after play to prevent Safari macOS from pausing the video
            video.currentTime = 0;
        }
    }

    public pause() {
        this.elementRef.current?.pause();
    }

    public stop() {
        const video = this.elementRef.current;

        if (video) {
            video.pause();
            video.currentTime = 0;
            this.props.onEnded?.();
        }
    }

    public getStill(type = 'image/png', quality = 0.95): Promise<Blob> {
        return new Promise((resolve, reject) => {
            const video = this.elementRef.current;

            if (video === null) {
                reject(new Error('Video element not set'));
                return;
            }

            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');

            if (context === null) {
                reject(new Error('Could not obtain canvas 2D context'));
                return;
            }

            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            context.drawImage(video, 0, 0);

            canvas.toBlob(blob => {
                if (blob === null) {
                    reject(new Error('Could not create blob from canvas'));
                    return;
                }

                resolve(blob);
            }, type, quality);
        });
    }

}
