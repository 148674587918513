import React, {useCallback, useEffect, useState} from "react";

import {IconButton} from "../../shared/IconButton";


/**
 * Component for selecting a device camera
 *
 * - Keeping track of the currently active camera is the parent's responsibility. This component cannot know if the
 *   selected camera is actually activated (e.g. due to non-matching media stream constraints, or the user selecting
 *   a different camera in a permissions popup). The parent must therefore set this component's value to the currently
 *   active camera ID.
 * - Most browsers return empty device IDs when calling .enumerateDevices() before the user granted camera permission.
 *   Some browsers also return an empty group ID before permission is granted (and few browsers return empty group IDs
 *   even after permission is obtained). All browsers return a device ID after the user has granted permission.
 *   We therefore rely only on device IDs. Empty device IDs are ignored, because selecting them would have no effect.
 * - There is no way to check whether the enumerated devices match any media stream constraints,
 *   therefore devices could be selected that cannot be used.
 * - Errors that occur when enumerating devices are silently ignored because
 *   getUserMedia() might still be able to get a camera.
 */
interface CameraSelectorProps {
    value?: string;
    onChange: (value: string) => void;
}

export function CameraSelector({
    value,
    onChange
}: CameraSelectorProps) {

    const [cameras, setCameras] = useState<string[]>([]);

    const updateCameras = useCallback(() => {
        getCameras()
            .then(setCameras)
            .catch(() => setCameras([]));
    }, []);

    /*
     * Update the camera list if a device is added or removed.
     */
    useEffect(() => {
        window.navigator.mediaDevices?.addEventListener('devicechange', updateCameras);
        return () => {
            window.navigator.mediaDevices?.removeEventListener('devicechange', updateCameras);
        }
    }, [updateCameras]);

    /*
     * Update the camera list if the parent is setting a camera ID that we don't know.
     * Possibly permission has been granted for a camera since the last camera list update.
     */
    useEffect(() => {
        if (value && !cameras.includes(value)) {
            updateCameras();
        }
    }, [value, cameras, updateCameras]);

    const handleSelectNext = () => {
        if (!cameras.length) {
            return;
        }

        const currentIndex = cameras.findIndex(camera => camera === value);
        const nextIndex = (currentIndex + 1) % cameras.length;

        onChange(cameras[nextIndex]);
    };

    if (cameras.length < 2) {
        return null;
    }

    return (
        <IconButton icon="flip_camera_android" size="s" onClick={handleSelectNext} />
    );
}

async function getCameras(): Promise<string[]> {
    if (!window.navigator.mediaDevices || !window.navigator.mediaDevices.enumerateDevices) {
        throw new Error('EnumerateDevices not supported');
    }

    const devices = await window.navigator.mediaDevices.enumerateDevices();
    return devices
        .filter(device => (device.kind === "videoinput") && device.deviceId)
        .map(device => device.deviceId);
}
