import React, {useCallback, useEffect, useRef, useState} from "react";
import {FormattedMessage} from "react-intl";
import {Box, Button, Stack, Typography} from "@mui/material";
import {ShutterButton} from "./ShutterButton";
import {PresetMask} from "../../../shared/PresetMask";
import {Video} from "../../../shared/Video";
import {CameraSelector} from "../../shared/CameraSelector";
import {GA4} from "../../../shared/GA4";
import {PresetModel} from "../../../shared/models/PresetModel";

import './PhotoCamera.scss';

/**
 *
 * A component for capturing a photo from camera
 *
 * RESOLUTION
 * - A video resolution above 4K (3840x2160) results in a blank preview on some devices.
 * - If the ImageCapture API (@see https://developer.mozilla.org/en-US/docs/Web/API/ImageCapture) is available, then
 *   the photo is taken at the camera's maximum resolution. Otherwise, the photo is taken in the video preview
 *   resolution.
 */

enum Step {
    Initializing,
    Permitting,
    Previewing,
    Capturing,
    Error
}

interface PhotoCameraProps {
    preset: PresetModel;
    videoConstraints?: MediaTrackConstraints;
    onCaptured: (data: Blob) => void;
    onError: (error: Error) => void;
}

export function PhotoCamera({
    preset,
    videoConstraints = {
        width: 3840,
        height: 2160
    },
    onCaptured,
    onError
}: PhotoCameraProps) {

    const [step, setStep] = useState<Step>(Step.Initializing);
    const [cameraId, setCameraId] = useState<string>();
    const [cameraStream, setCameraStream] = useState<MediaStream|null>(null);
    const [cameraIsMirrored, setCameraIsMirrored] = useState<boolean>();

    const videoRef = useRef<Video|null>(null);
    const imageCaptureRef = useRef<ImageCapture|null>(null);

    const handleError = useCallback((error: Error) => {
        setStep(Step.Error);
        onError(error);
    }, [onError]);

    useEffect(() => {
        startCameraStream().then();
        return () => {
            stopCameraStream();
        }
        // startCameraStream and stopCameraStream dependencies are omitted. Should be effect events (but useEffectEvent is not available yet).
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cameraId]);

    /*
     * Chrome and Opera on macOS capture the first photo with a 3s delay after calling
     * ImageCapture.takePhoto(), but complete subsequent captures within 50ms. Therefore,
     * to meet the user's expectations, we take (and discard) the first photo straight
     * after the camera stream is initialized.
     */
    useEffect(() => {
        if (!cameraStream || !isImageCaptureAvailable()) {
            imageCaptureRef.current = null;
            return;
        }

        const videoTracks = cameraStream.getVideoTracks();
        if (!videoTracks.length) {
            imageCaptureRef.current = null;
            return;
        }

        imageCaptureRef.current = new ImageCapture(videoTracks[0]);

        if (isPlatformMacIntel()) {
            capture().catch(() => {});
        }
    }, [cameraStream]);

    const startCameraStream = async () => {
        const constraints = {
            audio: false,
            video: {
                ...videoConstraints,
                deviceId: cameraId
            }
        };

        try {
            const cameraStream = await window.navigator.mediaDevices.getUserMedia(constraints);
            const videoTrack = cameraStream.getVideoTracks()[0];

            /*
             * User facing camera streams are not automatically mirrored horizontally.
             * Facing modes 'user', 'left' and 'right' face toward the user by definition. Some devices
             * provide an empty value, which is user facing on all devices encountered so far.
             */
            const videoSettings = videoTrack.getSettings();
            const cameraIsMirrored = videoSettings.facingMode !== 'environment';

            setCameraId(videoSettings.deviceId); // FIXME: prevent duplicate start
            setCameraStream(cameraStream);
            setCameraIsMirrored(cameraIsMirrored);
            setStep(Step.Previewing);

            GA4.sendEvent('record_image_camera_preview_start', {
                video_width: videoSettings.width,
                video_height: videoSettings.height,
                video_facing_user: cameraIsMirrored
            });
        } catch (error) {
            if (error instanceof DOMException && error.name === 'NotAllowedError') {
                setStep(Step.Permitting);
            } else {
                handleError(error as Error);
            }
        }
    };

    const stopCameraStream = () => {
        if (cameraStream) {
            cameraStream.getTracks().forEach(track => track.stop());
            setCameraStream(null);
        }
    };

    const capture = async (): Promise<Blob> => {
        if (imageCaptureRef.current) {
            const capabilities = await imageCaptureRef.current.getPhotoCapabilities();
            return imageCaptureRef.current.takePhoto({
                imageWidth: capabilities.imageWidth.max,
                imageHeight: capabilities.imageHeight.max
            });
        }

        if (videoRef.current) {
            return videoRef.current.getStill('image/jpeg');
        }

        throw new Error('Attempting to capture photo without ImageCapture or Video set');
    };

    const handleCapture = async () => {
        GA4.sendEvent('record_image_camera_capture');

        try {
            setStep(Step.Capturing);
            onCaptured(await capture());
            setStep(Step.Previewing);
        }
        catch (error) {
            handleError(error as Error);
        }
    };

    if (step === Step.Error) {
        return null;
    }

    if (step === Step.Initializing) {
        return (
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', padding: 2}}>
                <Typography variant="body1" component="div" align="center">
                    <FormattedMessage
                        id="record.image.photo.access-request"
                        description="Record page - Select image - Camera tab - Camera access request text."
                        defaultMessage="Grant access to the camera to continue."
                    />
                </Typography>
            </Box>
        );
    }

    if (step === Step.Permitting) {
        return (
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', padding: 2}}>
                <Stack spacing={2} justifyContent="center" alignItems="center">
                    <Typography variant="body1" component="div" align="center">
                        <FormattedMessage
                            id="record.image.photo.access-denied"
                            description="Record page - Select image - Camera tab - Camera access denied text."
                            defaultMessage="Access to the camera has been denied. Change the permission settings in your browser and retry."
                        />
                    </Typography>
                    <Button variant="contained" onClick={startCameraStream}>
                        <FormattedMessage
                            id="shared.retry"
                            description="Retry button text."
                            defaultMessage="Retry"
                        />
                    </Button>
                </Stack>
            </Box>
        );
    }

    return (
        <div className="photo-camera-wrapper">
            <PresetMask
                preset={preset}
                mirrored={cameraIsMirrored}
            >
                <Video
                    ref={videoRef}
                    src={cameraStream}
                    autoPlay={true}
                    muted={true}
                    objectFit="cover"
                />
                <div className={`photo-camera-aperture ${step === Step.Capturing ? 'active' : ''}`} />
            </PresetMask>
            <div className="photo-camera-controls">
                { step === Step.Previewing &&
                    <CameraSelector
                        value={cameraId}
                        onChange={setCameraId}
                    />
                }
                <ShutterButton
                    isRecording={step === Step.Capturing}
                    onClick={handleCapture}
                />
            </div>
        </div>
    );

}

function isImageCaptureAvailable(): boolean {
    return typeof window !== 'undefined' && 'ImageCapture' in window;
}

function isPlatformMacIntel(): boolean {
    return typeof window !== 'undefined' && window.navigator.platform === 'MacIntel';
}
