import '../../Events';

const sdk = window.happyar;

interface Upload {
    data: Blob | null;
    request: APIResponse | null;
    error: Error | null;
    bytesSent: number;
    bytesTotal: number | null;
    uploadId: string | null;
}

interface Progress {
    bytesSent: number;
    bytesTotal: number;
}

interface CustomEventMap {
    "progress": CustomEvent<Progress>;
    "completed": CustomEvent<{ key: string, uploadId: string }>;
    "error": CustomEvent<{ key: string, error: Error }>;
}

interface CustomEventTarget extends EventTarget {
    addEventListener<K extends keyof CustomEventMap>(type: K, callback: (this: UploadManager, event: CustomEventMap[K]) => void, options?: AddEventListenerOptions | boolean): void;
    addEventListener(type: string, callback: EventListenerOrEventListenerObject | null, options?: EventListenerOptions | boolean): void;
    removeEventListener(type: string, callback: EventListenerOrEventListenerObject | null, options?: EventListenerOptions | boolean): void;
    removeEventListener<K extends keyof CustomEventMap>(type: K, callback: (this: UploadManager, event: CustomEventMap[K]) => void, options?: EventListenerOptions | boolean): void;
    dispatchEvent<K extends keyof CustomEventMap>(ev: CustomEventMap[K]): boolean;
}

// @see https://dev.to/marcogrcr/type-safe-eventtarget-subclasses-in-typescript-1nkf
type TypedEventTarget = { new (): CustomEventTarget; };

export class UploadManager extends (EventTarget as TypedEventTarget) {

    /**
     * The next upload key
     */
    private nextKey: number = 0;

    /**
     * The currently active uploads
     *
     * Object keys are upload keys, object values are upload objects
     */
    private uploads: {[key: string]: Upload} = {};

    /**
     * Add an upload
     *
     * @param data
     *     The binary data to upload.
     * @returns
     *     The upload key.
     */
    public add(data: Blob): string {
        const key = String(++this.nextKey);
        this.uploads[key] = { data, error: null, request: null, bytesSent: 0, bytesTotal: null, uploadId: null };
        this.startUpload(key);
        return key;
    }

    /**
     * Get upload details
     *
     * @param key
     *     The key of the upload to retrieve.
     * @returns
     *     The upload details.
     */
    public get(key: string): Upload {
        return this.uploads[key];
    }

    /**
     * Remove an upload
     *
     * @param key
     *     The key of the upload to remove.
     */
    public remove(key: string): void {
        this.cancelUpload(key);
        delete this.uploads[key];
        this.onProgress();
    }

    /**
     * Remove all uploads
     */
    public removeAll() {
        for (const key in this.uploads) {
            this.cancelUpload(key);
        }
        this.uploads = {};
        this.onProgress();
    }

    /**
     * Get the cumulative upload progress
     *
     * @returns
     *     The number of bytes sent and the total number of bytes to send.
     */
    public getProgress(): Progress {
        return Object.values(this.uploads).reduce((totals, upload) => {
            totals.bytesSent  += upload.bytesSent  || 0;
            totals.bytesTotal += upload.bytesTotal || 0;
            return totals;
        }, { bytesSent: 0, bytesTotal: 0 } as Progress)
    }

    /**
     * Determine if at least one upload resulted in an error
     *
     * @returns
     *     Whether at least one upload resulted in an error.
     */
    public hasError(): boolean {
        return Object.values(this.uploads).reduce((hasError, upload) => {
            return hasError || !!upload.error
        }, false as boolean);
    }

    /**
     * Retry all failed uploads
     */
    public retryAll(): void {
        for (const key in this.uploads) {
            if (this.uploads[key].error) {
                this.startUpload(key);
            }
        }
    }

    private startUpload(key: string) {
        const upload = this.uploads[key];
        upload.error = null;
        upload.request = null;
        upload.bytesSent = 0;

        if (!upload.data) {
            upload.error = new Error("Attempted to start upload without data.");
            return;
        }

        try {
            upload.request = sdk.upload({
                data: upload.data,
                progress: (bytesSent, bytesTotal) => {
                    upload.bytesSent = bytesSent;
                    upload.bytesTotal = bytesTotal;
                    this.onProgress();
                },
                success: (uploadId) => {
                    upload.data = null;
                    upload.uploadId = uploadId;
                    this.onCompleted(key, uploadId);
                },
                error: (error) => {
                    upload.error = error;
                    this.onError(key, error);
                }
            });
        } catch (error: unknown) {
            if (error instanceof Error) {
                upload.error = error;
                this.onError(key, error);
            } else {
                throw error;
            }
        }
    }

    private cancelUpload(key: string) {
        const upload = this.uploads[key];
        if (upload) {
            upload.request?.abort();
            upload.request = null;
        }
    }

    private onProgress() {
        this.dispatchEvent(new CustomEvent('progress', {
            detail: this.getProgress()
        }));
    }

    private onCompleted(key: string, uploadId: string) {
        this.dispatchEvent(new CustomEvent('completed', {
            detail: { key, uploadId }
        }));
    }

    private onError(key: string, error: Error) {
        this.dispatchEvent(new CustomEvent('error', {
            detail: { key, error }
        }));
    }

}
