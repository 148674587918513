import {Component} from "react";
import PropTypes from "prop-types";

import './ShutterButton.scss';

export class ShutterButton extends Component {

    render() {
        return (
          <button className="shutter-button"
                  disabled={this.props.isRecording}
                  onClick={this.props.onClick}>
              <div className={"shutter-button-icon" + (this.props.isRecording ? " recording" : "")} />
          </button>
        );
    }

}

ShutterButton.propTypes = {
    isRecording: PropTypes.bool,
    onClick: PropTypes.func
}
