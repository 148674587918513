import {useEffect, useState} from "react";
import {SimpleMediaRecorder} from "./SimpleMediaRecorder";


export function useHasCamera(mimeTypes: string[]) {
    const [hasCamera, setHasCamera] = useState<boolean | null>(null);

    useEffect(() => {
        const updateHasCamera = () => {
            canRecord(mimeTypes)
                .catch(() => false)
                .then(setHasCamera);
        };

        updateHasCamera();

        window.navigator.mediaDevices?.addEventListener('devicechange', updateHasCamera);
        return () => {
            window.navigator.mediaDevices?.removeEventListener('devicechange', updateHasCamera);
        };
    }, [mimeTypes]);

    return hasCamera;
}

async function canRecord(mimeTypes: string[]): Promise<boolean> {
    const supportsOneMimeType = mimeTypes.reduce((hasSupport, mimeType) => {
        return hasSupport || SimpleMediaRecorder.isTypeSupported(mimeType)
    }, false);

    if (!supportsOneMimeType ||
        !window.navigator.mediaDevices ||
        !window.navigator.mediaDevices.enumerateDevices ||
        !window.navigator.mediaDevices.getUserMedia) {
        return Promise.resolve(false);
    }

    const devices = await window.navigator.mediaDevices.enumerateDevices();
    return !!devices.find(device => device.kind === 'videoinput') &&
           !!devices.find(device => device.kind === 'audioinput');
}
