import {createContext, Dispatch, ReactElement, useContext, useReducer} from "react";
import {initialRecordState, RecordAction, recordReducer, RecordState} from "./recordReducer";


const RecordStateContext = createContext<RecordState>(initialRecordState);
const RecordDispatchContext = createContext<Dispatch<RecordAction>>(() => null);

export function RecordStateProvider({ children }: { children: ReactElement }) {
    const [state, dispatch] = useReducer(recordReducer, initialRecordState);

    return (
        <RecordStateContext.Provider value={state}>
            <RecordDispatchContext.Provider value={dispatch}>
                {children}
            </RecordDispatchContext.Provider>
        </RecordStateContext.Provider>
    )
}

export function useRecordState() {
    return useContext(RecordStateContext);
}

export function useRecordDispatch() {
    return useContext(RecordDispatchContext);
}
