import clsx from 'clsx';

import './InstructionImage.scss';

interface InstructionImageProps {
    src: string|null;
    alt: string;
    force?: boolean;
}

export function InstructionImage(props: InstructionImageProps) {
    if (!props.src) {
        return null;
    }

    return (
        <img className={clsx('instruction-image', props.force && 'visible')} src={props.src} alt={props.alt} />
    );
}
