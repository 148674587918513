import React, {Component} from "react";
import PropTypes from "prop-types";

export class CanvasMask extends Component {

    constructor(props) {
        super(props);

        this.canvasRef = React.createRef();
    }

    componentDidMount() {
        this.paint(this.canvasRef.current, this.props.alpha, this.props.color).then();
    }

    render() {
        return <canvas ref={this.canvasRef} />;
    }

    async paint(canvas, alpha ,color) {
        const mask = await this.getImage(alpha);
        const context = canvas.getContext('2d');

        canvas.width = mask.naturalWidth;
        canvas.height = mask.naturalHeight;

        context.drawImage(mask, 0, 0);

        context.globalCompositeOperation = 'source-out';
        context.fillStyle = color;
        context.fillRect(0, 0, canvas.width, canvas.height);
    }

    getImage(src) {
        return new Promise((accept, reject) => {
           const image = new Image();

           image.onload = () => {
               accept(image);
           };

           image.onerror = () => {
               reject(new Error("Could not load image"));
           }

           image.src = src;
        });
    }
}

CanvasMask.propTypes = {
    alpha: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired
}
