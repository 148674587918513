import {sdk} from './sdk';
import {VideoModel} from "../models/VideoModel";


function post(data: Partial<VideoModel>, fields?: string[], expand?: string[]): Promise<Partial<VideoModel>> {
    return sdk.api<VideoModel>({
        method: 'POST',
        path: `/videos`,
        data: data,
        fields: fields,
        expand: expand
    });
}

function get(id: string, fields?: string[], expand?: string[], key?: string): Promise<Partial<VideoModel>> {
    return sdk.api<VideoModel>({
        method: 'GET',
        path: `/videos/${id}`,
        fields: fields,
        expand: expand,
        key: key
    });
}

function patch(id: string, data: Partial<VideoModel>, fields?: string[], expand?: string[]): Promise<Partial<VideoModel>> {
    return sdk.api<VideoModel>({
        method: 'PATCH',
        path: `/videos/${id}`,
        data: data,
        fields: fields,
        expand: expand
    });
}

export const VideoService = { post, get, patch };
