
declare global {
    interface Window {
        hj: any;
        _hjSettings: any;
    }
}

/**
 * The Hotjar Snippet version
 */
const hotjarVersion = 6;

/**
 * Initializes Hotjar
 *
 * @param hotjarId
 *     The Hotjar ID.
 */
export function init(hotjarId: string) {

    window.hj = window.hj || function() {
        (window.hj.q = window.hj.q || []).push(arguments);
    };

    window._hjSettings = {
        hjid: hotjarId,
        hjsv: hotjarVersion
    };

    const script = document.createElement('script');
    script.async = true;
    script.src = `https://static.hotjar.com/c/hotjar-${hotjarId}.js?sv=${hotjarVersion}`;
    document.head.appendChild(script);

}


