import React, {useEffect, useRef, useState} from 'react';
import {IconButton} from "@mui/material";
import {FormattedMessage} from "react-intl";

import {Video} from "../../../shared/Video";
import {PresetModel} from "../../../shared/models/PresetModel";
import {PresetMask} from "../../../shared/PresetMask";
import AspectBox from "../../../shared/AspectBox";
import {AlertDialog} from "./AlertDialog";

import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import './Player.scss';


interface PlayerProps {
    source: string | Blob | MediaStream;
    preset: PresetModel;
    poster?: string | Blob | null;
    autoPlay?: boolean;
    maxDuration?: number | null;
    warnMaxDuration?: boolean;
    onWarnMaxDuration?: (duration: number, maxDuration: number) => void;
    onPlay?: () => void;
}

const defaultPlayerProps: Partial<PlayerProps> = {
    autoPlay: false,
    warnMaxDuration: true
};

export function Player(inProps: PlayerProps) {
    const props = { ...defaultPlayerProps, ...inProps };

    const [warningMaxDuration, setWarningMaxDuration] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const videoRef = useRef<Video>(null);

    // Ensure the play button reappears if another source is selected while the video is playing
    useEffect(() => {
        setIsPlaying(false);
    }, [props.source]);

    const handleLoadedMetadata = (metadata: { duration: number }) => {
        const exceedsMaxDuration = !!props.maxDuration && (metadata.duration > props.maxDuration);
        const warnMaxDuration = !!props.warnMaxDuration && exceedsMaxDuration;

        if (warnMaxDuration) {
            props.onWarnMaxDuration?.(metadata.duration, props.maxDuration!)
        } else if (props.autoPlay) {
            handlePlay();
        }

        setWarningMaxDuration(warnMaxDuration);
    };

    const handleDismissMaxDuration = () => {
        setWarningMaxDuration(false);
        if (props.autoPlay) {
            handlePlay();
        }
    };

    const handlePlay = async () => {
        try {
            await videoRef.current?.play();
        } catch (error: any) {
            // Ignore error thrown if browser requires user interaction to play
            if (error.name !== 'NotAllowedError') {
                throw error;
            }
        }
    };

    const handleStarted = () => {
        setIsPlaying(true);
    };

    const handleEnded = () => {
        setIsPlaying(false);
    };

    return (
        <>
            <AlertDialog
                isOpen={warningMaxDuration}
                onDismiss={handleDismissMaxDuration}
                message={
                    <FormattedMessage
                        id="record.media.shared.player.warn-max-duration"
                        description="Record page - Select video - Video player - Video trimmed text."
                        defaultMessage="Video is too long and therefore trimmed to {minutes}:{seconds, number, ::00}."
                        values={{
                            minutes: Math.floor(props.maxDuration! / 60) % 60,
                            seconds: Math.floor(props.maxDuration!) % 60
                        }}
                    />
                }
            />

            <div className="player-wrapper">
                <PresetMask preset={props.preset}>
                    <Video ref={videoRef}
                           src={props.source}
                           poster={props.poster ?? undefined}
                           objectFit="cover"
                           autoPlay={false}
                           onLoadedMetadata={handleLoadedMetadata}
                           onPlay={handleStarted}
                           onEnded={handleEnded}
                           endTime={props.maxDuration ?? undefined} />
                </PresetMask>
                <div className="player-controls">
                    {!isPlaying &&
                        <AspectBox ratio={1} sx={{ width: '15%' }}>
                            <IconButton sx={{width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
                                        disableRipple={true}
                                        onClick={handlePlay}>
                                <PlayArrowIcon sx={{ width: '100%', height: '100%', color: 'white' }} />
                            </IconButton>
                        </AspectBox>
                    }
                </div>
            </div>
        </>
    );
}
