/**
 *
 * A simplified port of .NET's asynchronous task cancellation mechanism.
 *
 *  @see https://docs.microsoft.com/en-us/dotnet/standard/parallel-programming/task-cancellation
 */
export class CancellationToken {

    constructor() {
        this.canceled = false;
    }

    cancel() {
        this.canceled = true;
    }

    isCancellationRequested() {
        return this.canceled;
    }

    throwIfCancellationRequested() {
        if (this.canceled) {
            throw new CancellationError(this);
        }
    }

}

export class CancellationError extends Error {

    constructor(token) {
        super('Task has been canceled');
        this.name = 'CancellationError';
        this.token = token;
    }

}
