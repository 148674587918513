import React from "react";
import {PresetModel} from "../../../shared/models/PresetModel";
import {GA4} from "../../../shared/GA4";
import {useRecordDispatch, useRecordState} from "../../../shared/reducers/RecordStateProvider";
import {PinturaImageState} from "@pqina/pintura";
import { Preview } from "../shared/Preview";
import {useNavigate} from "react-router-dom";


interface FileProps {
    preset: PresetModel;
    maxDuration: number;
    onAccept: (data: Blob) => void;
}

export function File({
    preset,
    maxDuration,
    onAccept
}: FileProps) {

    const state = useRecordState();
    const dispatch = useRecordDispatch();

    const navigate = useNavigate();

    const videoData = state.media.file.data;
    const originalVideoData = state.media.file.originalData;
    const editorState = state.media.file.editorState;

    const handleWarnMaxDuration = (duration: number, maxDuration: number) => {
        GA4.sendEvent('record_media_file_trim', { duration: duration, max_duration: maxDuration });
    }

    const handlePlay = () => {
        GA4.sendEvent('record_media_file_play');
    };

    const handleEdited = (data: Blob, editorState: PinturaImageState) => {
        dispatch( { type: 'setMediaFileEditedData', payload: { data, editorState } });
    };

    const handleNext = () => {
        if (videoData) {
            onAccept(videoData);
        }
    };

    const handleBack = () => {
        navigate(-1);
    };

    if (!videoData || !originalVideoData) {
        return null;    // TODO: expected video data set
    }

    return (
        <Preview
            videoData={videoData}
            originalVideoData={originalVideoData}
            preset={preset}
            maxDuration={maxDuration}
            editorState={editorState}
            onPlay={handlePlay}
            onEdited={handleEdited}
            onWarnMaxDuration={handleWarnMaxDuration}
            onNext={handleNext}
            onBack={handleBack}
        />
    );
}
