import {VideoPlayer} from "./VideoPlayer";
import {AlbumPlayer} from "./AlbumPlayer";
import {useEffect} from "react";

import * as Groove from "./../shared/Groove";


export interface PlayParams {
    albumId?: string,
    videoId?: string
    videoKey?: string
}

interface PlayProps {
    apiBaseUrl?: string,
    accessToken: string,
    grooveId: string|null,
    params: PlayParams
}

export function Play(props: PlayProps) {
    useEffect(() => {
        if (props.grooveId) {
            Groove.init(props.grooveId);
        }
    }, [props.grooveId])

    if (props.params.videoId) {
        return <VideoPlayer
            apiBaseUrl={props.apiBaseUrl}
            accessToken={props.accessToken}
            videoId={props.params.videoId}
            resourceKey={props.params.videoKey}
        />;
    }

    if (props.params.albumId) {
        return <AlbumPlayer
            apiBaseUrl={props.apiBaseUrl}
            accessToken={props.accessToken}
            albumId={props.params.albumId}
            resourceKey={props.params.videoKey}
        />
    }

    throw new Error("Missing 'videoId' or 'albumId' parameter for play dialog.");
}
